@import 'theme.scss';

.add-mobile-group-card {
  border-radius: $form-container-border-radius;
  padding: $form-container-padding;
  box-shadow: $form-container-box-shadow;

  .p-float-label label {
    position: absolute;
    pointer-events: none;
    margin-top: $float-label-margin-top;
    transition-property: all;
    transition-timing-function: ease;
    font-weight: $font-normal;
    margin-left: $float-label-margin-left;
  }

  .p-error {
    margin-left: $float-label-margin-left;
  }

  label {
    left: $form-label;
  }

  .p-invalid + .form-fields:enabled:hover,
  .p-invalid-hover {
    border-color: $danger-red-color !important;
  }

  .versions-row {
    margin-bottom: $form-field-margin;
    display: grid;
    grid-template-columns: 20% 20% 20%;
    .major-version,
    .minor-version {
      margin-right: 10px;
    }
  }

  .p-inputtext:hover {
    border-color: $primary-color;
  }

  .p-inputtext:focus {
    border-color: $primary-color;
    box-shadow: 0 0 0 0.2rem $box-shadow-primary-color;
  }
  .p-dropdown:not(.p-disabled):hover {
    border-color: $primary-color;
  }

  .p-button:focus {
    box-shadow: $focus-shadow-color;
  }

  .p-dropdown:not(.p-disabled).p-focus {
    box-shadow: $focus-shadow-color;
    border-color: $primary-color;
  }

  .field-label {
    color: $grey-font-color-on-hover;
  }

  .field-required {
    color: $danger-red-color;
    font-weight: $font-medium;
    font-size: $x-large;
  }

  .version {
    width: $field-width-60;
    margin-bottom: $form-field-margin;
  }
  .build-number,
  .launchPlatform,
  .release-notes,
  .build-date {
    margin-bottom: $form-field-margin;
    width: $field-width-60;
    .release-notes-error {
      margin-top: -7px;
    }
    .p-calendar-w-btn-right .p-datepicker-trigger {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .p-fluid .p-button-icon-only {
      width: 3rem;
    }
    .p-fluid .p-button {
      width: 3rem;
    }

    .p-button-icon-only {
      justify-content: center;
    }
    .p-button {
      background: $primary-color;
      border: 1px solid $primary-color;
      padding: $button-label-padding;
    }
  }

  .add-mobile-save {
    display: flex;
    justify-content: flex-end;
  }
}

.p-datepicker table td.p-datepicker-today > span.p-highlight {
  background-color: $light-grey;
  color: $primary-color;
}
.p-datepicker table td > span:focus {
  box-shadow: none;
}

@media (max-width: $device-medium) {
  .add-mobile-group-card {
    .versions-row {
      display: block;

      .major-version,
      .minor-version,
      .patch-version {
        width: $field-width-60;
        margin-right: auto;
        margin-bottom: $form-field-margin;
      }
    }
  }
}

@media (max-width: $device-small) {
  .add-mobile-group-card {
    .versions-row {
      display: block;
      .major-version,
      .minor-version,
      .patch-version {
        width: $field-width-100;
        margin-right: auto;
        margin-bottom: $form-field-margin;
      }
    }

    .version,
    .build-number,
    .launchPlatform,
    .build-date,
    .release-notes {
      width: $field-width-100;
    }
  }
}
