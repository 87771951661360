@import 'theme.scss';
.layout-sidebar {
  height: 100%;
  left: 0;
  width: 20rem;
  box-shadow: $form-container-box-shadow;
  display: flex;
  flex-direction: column;
  transition: width 0.2s ease-in;
  &.inactive {
    width: 80px;

    .sidebar-header {
      background-color: $primary-color;
      cursor: pointer;
      .toggle-menu-btn {
        right: -40px;
      }
    }
    .p-panelmenu .p-menuitem-text {
      opacity: 0;
      width: 0;
      height: 0;
      overflow: hidden;
    }
    .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
      display: none;
    }
    .p-panelmenu .p-panelmenu-content {
      padding: 0;
      border: none;
    }
    .menu-items .p-panelmenu .p-panelmenu-header.p-highlight > a {
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
    }
    .version-number {
      display: none;
    }

    .side-menu-footer {
      width: 80px;

      .user-info {
        opacity: 0;
        width: 0;
        height: 0;
        overflow: hidden;
      }
      .p-avatar {
        width: 2.5rem;
        height: 2.5rem;
        font-size: $x-large;
        margin-left: 5px;
        color: $grey-font-color;
      }
      .footer-content {
        &:hover {
          background: none;
        }
      }
    }
  }

  .sidebar-header {
    position: relative;
    background-color: $primary-color;
    height: 68px;
    .logo {
      width: 74%;
      overflow: hidden;
      display: flex;
      margin: 16px;
      justify-content: center;
      .sidebar-inactive-logo {
        height: 3rem;
        padding: 4px;
        margin-top: -3px;
        padding-right: 12px;
        width: 100%;
      }
      .sidebar-main-logo {
        height: 2rem;
        margin: 4px;
      }
      .cw-logo {
        width: 100%;
        padding-right: 7px;
      }
      .sidebar-inactive-cw-Test-logo {
        height: 2rem;
        margin: 4px;
      }
    }
    .toggle-menu-btn {
      color: $primary-color;
      font-size: 20px;
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      cursor: pointer;
      padding-right: 2px;
      .p-button:enabled:hover {
        background-color: $light-grey-on-hover;
        color: $primary-color;
      }
      .p-button:focus {
        box-shadow: none;
      }
      .p-button.p-button-icon-only {
        width: 2rem;
        padding: 4px 12px;
        background-color: $light-grey;
        color: $primary-color;
        border: 1px solid $light-grey-border;
        border-radius: 4px;
        height: 2rem;
      }
    }
  }

  .menu-items {
    overflow-y: scroll;
    height: 100%;
  }

  .side-menu-footer {
    width: 17.1rem;
    position: relative;
    bottom: 0;
    left: 0;
    padding: 12px 12px 0px 12px;
    box-sizing: border-box;
    cursor: pointer;
    .footer-content {
      .avatar {
        // width: 50px;
        // height: 50px;
        overflow: hidden;
        display: inline-block;
      }

      .user-info {
        display: inline-block;
        margin: 0 10px;
        position: absolute;

        &:hover {
          color: $primary-color;
        }
      }
    }
    .p-avatar {
      width: 2.5rem;
      height: 2.5rem;
      font-size: $x-large;
      color: $grey-font-color;
    }
  }
  .p-panelmenu .p-panelmenu-header.p-highlight > a {
    margin: 12px 12px 0;
    background: $primary-color;
    color: $white;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .p-panelmenu .p-panelmenu-header.p-highlight > a:hover {
    background: $primary-color;
  }
  .version-number {
    display: flex;
    justify-content: flex-end;
    font-size: 9px;
    color: $grey-font-color;
    margin-right: 5px;
    margin-bottom: 1px;
  }
}
.close-btn {
  font-size: 10px;
}
.menu-items {
  .p-panelmenu .p-panelmenu-header > a {
    padding: 16px 18px;
    border: 1px solid $light-grey-border;
    color: $grey-font-color;
    background: $light-grey;
    font-weight: $font-bold;
    border-radius: 6px;
    margin: 10px;
  }

  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
    padding: 0.5rem 0.75rem;
    color: $dark_grayish_blue;
    border-radius: 0;
    transition: box-shadow 0.2s;
    -webkit-user-select: none;
    user-select: none;
    border: 1px solid $light-grey-border;
    background: $white;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    margin: 10px;
  }

  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-panelmenu-icon {
    margin-right: 0;
  }
  .p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover {
    color: $grey-font-color-on-hover;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
    background: #e9efea;
    color: $grey-font-color-on-hover;
  }

  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem $primary-color;
    background-color: $primary-color;
  }

  .p-panelmenu-icon {
    order: 1;
    rotate: 90deg;
  }
  .p-menuitem-text {
    flex-grow: 1;
  }

  .p-toggleable-content {
    margin: 0 12px 12px;
  }
  .p-panelmenu .p-panelmenu-header > a:focus {
    box-shadow: none;
  }
  .p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-submenu) {
    margin: 10px 0 10px 10px;
    margin-right: -12px;
    padding: 0px;
  }
}

.menu-items::-webkit-scrollbar {
  width: 4px;
}
.menu-items::-webkit-scrollbar-track {
  border-radius: 0;
  background: transparent;
}

.menu-items::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: transparent;
}

.p-panelmenu-icon {
  order: 1;
  rotate: 90deg;
}
.p-menuitem-text {
  flex-grow: 1;
}

.p-sidebar-left .p-sidebar {
  display: none;
}
.mobile-sidebar {
  display: none;
}
@media (max-width: $device-small) {
  .layout-sidebar {
    display: none;
    .menu-items {
      overflow-y: scroll;
    }
  }

  .p-sidebar-left .p-sidebar {
    display: block;
    width: 16rem;
  }

  .p-component-overlay-enter {
    animation: p-component-overlay-enter-animation 150ms forwards;
  }
  .p-sidebar .p-sidebar-header + .p-sidebar-content {
    padding: 0.5rem;
    .p-panelmenu .p-panelmenu-header > a:focus {
      box-shadow: none;
    }
    .p-panelmenu .p-panelmenu-header > a {
      padding: 1rem;
    }
  }
  .p-button:focus {
    box-shadow: none;
  }
  .p-sidebar .p-sidebar-header {
    padding: 34px;
    background-image: url('https://coffeeweb.blob.core.windows.net/coffeewebicons/coffeeweb_logo.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}
