@import 'theme.scss';

.terminal-details-container {
  .p-datatable .p-datatable-thead > tr > th {
    padding: $table-row-padding;
  }

  .p-datatable .p-datatable-tbody > tr > td {
    padding: $table-row-padding;
  }
  .terminal-details-table {
    width: 100%;
    margin-bottom: $table-margin-bottom;

    .market-name-column,
    .action-column {
      width: 9%;
    }
    .contract-name-column {
      width: 10%;
    }
    .option-expiry-column,
    .first-notice-column,
    .week-high-rate-column,
    .week-low-rate-column,
    .week-high-date-column,
    .week-low-date-column {
      width: 11%;
    }
    .active-column {
      width: 6%;
    }

    .toggle-switch {
      display: flex;
      align-items: center;
      .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
        background-color: $primary-color;
      }

      .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:hover {
        background-color: $primary-color;
      }
      .p-inputswitch.p-focus .p-inputswitch-slider {
        box-shadow: 0 0 0 0.2rem $box-shadow-primary-color;
      }
    }
    .action-container {
      display: flex;
      justify-content: space-between;

      .add-entry-button {
        width: 64px;
        height: 54px;
      }
      .p-button:focus {
        box-shadow: none;
      }

      .pi-edit-icon {
        border: 1px solid $white;
        background-color: $white;
        color: $primary-color;
        margin-right: 4px;
        padding: 0;
      }
      .add-button {
        border: 1px solid $white;
        background-color: $white;
        color: $primary-color;
        margin-right: 4px;
        padding: 0;
        cursor: pointer;
        transition: background-color 0.3s;
        box-shadow: none;
      }
    }
  }

  .status-filter-dropdown {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    gap: $action-header-gap;
    .p-dropdown {
      width: 190px;
    }

    .p-dropdown:hover {
      border: 1px solid $primary-color;
    }

    .p-dropdown:focus {
      border: 1px solid $primary-color;
      box-shadow: $focus-shadow-color;
    }

    .p-dropdown:not(.p-disabled).p-focus {
      border: 1px solid $primary-color;
      box-shadow: $focus-shadow-color;
    }
  }
}

.terminal-data-container {
  .grid-input {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    .field {
      margin-bottom: $form-field-margin;
      .p-inputtext {
        width: 100%;
      }
      .p-dropdown {
        width: 100%;
      }

      label {
        margin-top: $float-label-margin-top;
        left: $form-label;
        margin-left: $float-label-margin-left;
      }
      .error-label {
        color: $danger-red-color;
      }
      .p-inputtext:enabled:focus {
        box-shadow: $focus-shadow-color;
        border-color: $primary-color;
      }
      .p-inputtext:enabled:hover {
        border-color: $primary-color;
      }
      .p-invalid + .form-fields:enabled:hover,
      .p-invalid-hover {
        border-color: $danger-red-color !important;
      }

      .p-dropdown:not(.p-disabled):hover {
        border-color: $primary-color;
      }
      .p-dropdown:not(.p-disabled).p-focus {
        box-shadow: $focus-shadow-color;
        border-color: $primary-color;
      }
      .p-invalid + .form-fields:enabled:hover,
      .p-invalid-hover {
        border-color: $danger-red-color;
      }
      .api-error-message {
        margin-left: $form-error-message-margin;
      }
      .error-message {
        color: $danger-red-color;
        margin-left: $form-error-message-margin;
      }
    }
  }

  .terminal-field-label {
    color: $grey-font-color-on-hover;
    margin-top: -6px;
    margin-left: -4px;
  }
  .p-error {
    color: $danger-red-color;
  }

  .date-field {
    margin-bottom: 24px;
    .p-calendar {
      width: 100%;
    }

    .p-calendar-w-btn-right .p-datepicker-trigger {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    .p-button.p-button-icon-only {
      width: 3rem;
      padding: 0.75rem 0;
    }

    .p-button-icon-only {
      justify-content: center;
    }
    .p-button {
      margin: 0;
      color: $white;
      background: $primary-color;
      border: 1px solid $primary-color;

      transition:
        background-color 0.2s,
        color 0.2s,
        border-color 0.2s,
        box-shadow 0.2s;
      border-radius: 6px;
    }
    .p-button:focus {
      box-shadow: $focus-shadow-color;
    }
    .p-inputtext:enabled:hover {
      border-color: $primary-color;
    }
    .p-invalid + .form-fields:enabled:hover,
    .p-invalid-hover {
      border-color: $danger-red-color;
    }
    .error-message {
      color: $danger-red-color;
      margin-left: $form-error-message-margin;
    }
    label {
      margin-top: $float-label-margin-top;
      left: $form-label;
      margin-left: $float-label-margin-left;
    }
    .p-invalid .p-inputtext:enabled:hover {
      border-color: $danger-red-color;
    }
    .p-invalid .p-inputtext:enabled:focus {
      border-color: $danger-red-color;
    }
    .p-filled .p-inputtext:enabled:focus {
      border-color: $primary-color;
    }
  }
  .required-text-field {
    color: $danger-red-color;
    margin-left: 2px;
    font-size: $x-large;
  }
}

.terminal-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: -35px;
  gap: $form-button-gap;
}

.terminal-main-dialog {
  width: 50vw;

  .p-dialog-content {
    padding-top: 25px;
  }
  .p-link:focus {
    box-shadow: $focus-shadow-color;
  }
}
.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  box-shadow: $focus-shadow-color;
}
@media (max-width: $device-small) {
  .terminal-main-dialog {
    width: $modal-mobile-width;
    .terminal-data-container {
      .grid-input {
        grid-template-columns: 1fr;
        gap: 0;
        .field {
          .p-inputtext {
            width: $field-width-100;
          }
          .p-dropdown {
            width: $field-width-100;
          }
        }
        .date-field {
          .p-calendar {
            width: $field-width-100;
          }
        }
      }
    }
    .terminal-button-container {
      margin-top: 0px;
    }
  }
}
