@import 'theme.scss';

.profileInformation {
  .profileInformation-card {
    border-radius: $form-container-border-radius;
    padding: $form-container-padding;
    box-shadow: $form-container-box-shadow;
  }

  .field {
    margin-bottom: $form-field-margin;
    width: $field-width-60;
  }

  .p-float-label label {
    position: absolute;
    pointer-events: none;
    margin-top: $float-label-margin-top;
    transition-property: all;
    transition-timing-function: ease;
    margin-left: $float-label-margin-left;
    font-weight: $font-normal;
  }

  label {
    left: $form-label;
    color: $grey-font-color-on-hover;
    font-weight: $font-extra-light;
  }

  .profile-information-fields {
    width: $field-width-100;
    border-color: $light-grey-border;
  }
  .profile-information-fields:hover {
    border-color: $primary-color;
  }

  .profile-information-fields:focus {
    border-color: $primary-color;
    box-shadow: 0 0 0 0.2rem $box-shadow-primary-color;
  }

  .p-inputtext {
    width: $field-width-100;
  }
  .p-input-icon-right {
    width: $field-width-100;
  }
  .p-inputtext:enabled:hover {
    border-color: $primary-color;
  }
  .p-inputtext:enabled:focus {
    border-color: $primary-color;
    box-shadow: $box-shadow-primary-color;
  }
  .p-invalid + .p-inputtext:enabled:hover,
  .p-invalid-hover {
    border-color: $danger-red-color;
  }
  .p-dropdown:not(.p-disabled).p-focus {
    border-color: $primary-color;
  }
  .p-dropdown:not(.p-disabled):hover {
    border-color: $primary-color;
  }

  .p-dropdown:not(.p-disabled).p-focus {
    box-shadow: $focus-shadow-color;
  }
  .p-invalid + .p-dropdown:hover .p-invalid-hover {
    border-color: $danger-red-color;
    border: 1px solid;
  }

  .p-inputtext.p-invalid.p-component {
    border-color: $danger-red-color;
  }

  .profile-information-fields + .p-inputtext:enabled:hover,
  .p-invalid-hover {
    border-color: $danger-red-color !important;
  }

  .profile-information-fields.p-invalid:focus + .p-inputtext,
  .p-invalid-hover:focus {
    border-color: $primary-color;
  }

  .required-field {
    color: $danger-red-color;
    font-size: $x-large;
    margin-left: $form-error-message-margin;
  }

  .error-message {
    margin-left: $form-error-message-margin;
    color: $danger-red-color;
    font-size: $small;
  }
  .p-error {
    margin-left: $float-label-margin-left;
    color: $danger-red-color;
  }

  .add-user-label {
    color: $grey-font-color-on-hover;
  }
  .add-user-button {
    display: flex;
    justify-content: flex-end;
  }
}

@media screen and (max-width: $device-small) {
  .profileInformation-card {
    width: $field-width-100;
    margin-bottom: 14px;
    .field {
      width: $field-width-100;
    }
    .p-inputtext {
      width: $field-width-100;
    }
  }
  .profile-information-fields {
    width: $field-width-100;
  }
}
