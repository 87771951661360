@import 'theme.scss';

.list-mobile-menu-component {
  .list-menu-wrapper {
    .mobile-menu-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      .action-buttons {
        display: flex;
        gap: 10px;
        align-items: center;
      }
      .p-inputtext {
        font-family: 'Roboto', 'Arial', sans-serif;
      }
    }
    table {
      thead {
        .action-buttons-column-cell,
        .isActive-column-cell {
          width: 10%;
          div {
            justify-content: center;
          }
        }
      }
      tbody {
        tr td {
          padding: 0.65rem 1rem !important;
        }
        .action-buttons-column-cell,
        .isActive-column-cell {
          text-align: center !important;
          .toggle-switch-menu {
            .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
              background-color: $primary-color;
            }
            .p-inputswitch .p-inputswitch-slider {
              box-shadow: none;
            }
          }
          .action-buttons-wrapper {
            display: flex;
            gap: 20px;
            justify-content: center;
          }
          .p-button {
            background-color: $white;
            border: none;
            width: auto;
            padding: 0.3rem;
            &:focus {
              box-shadow: none;
            }
            &:hover {
              background-color: $white;
            }
            .p-button-icon {
              color: $primary-color;
              font-size: 1rem;
            }
          }
          .pi-trash {
            color: #ef4444 !important;
          }
        }
      }
    }
  }
}

.mobile-menus-reorder-dailog-modal {
  user-select: none;
  .p-dialog-header {
    padding: 0.8rem 1rem;
    border-bottom: 1px solid lightgrey;
  }
  .p-dialog-content {
    padding: 0.65rem;
  }
  .p-dialog-footer {
    padding: 0.75rem;
    display: flex;
    justify-content: flex-end;
    button {
      margin: 0px;
    }
  }
}
