@import 'theme.scss';

.global-tab-view-container {
  margin-bottom: 40px;
  border: 1px solid $light-grey-border;
  border-radius: 4px;

  .p-tabview .p-tabview-panels {
    padding: $table-padding;
  }

  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: white;
    border-color: $primary-color;
    color: $primary-color;
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 0.9rem;
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link:hover {
    text-decoration: none;
  }

  .p-tabview .p-tabview-nav .p-tabview-ink-bar {
    background: $primary-color;
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
}
