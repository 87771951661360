@import 'theme.scss';

.list-payment-container {
  .search-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 8px;
    gap: $action-header-gap;

    .search-field {
      width: 190px;

      .p-inputtext {
        width: $field-width-100;

        &:hover {
          border: 1px solid $primary-color;
        }

        &:focus {
          border: 1px solid $primary-color;
          box-shadow: $focus-shadow-color;
        }
      }
    }
  }
  .toggle-switch {
    display: flex;
    align-items: center;
    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
      background-color: $primary-color;
    }
    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:hover {
      background-color: $primary-color;
      border-color: none;
    }
    .p-inputswitch .p-focus .p-inputswitch-slider {
      box-shadow: $focus-shadow-color;
    }
    .p-inputswitch.p-focus .p-inputswitch-slider {
      box-shadow: 0 0 0 0.2rem $box-shadow-primary-color;
    }
  }

  .edit-button {
    background-color: $white;
    border: none;
    outline: none;
    color: $primary-color;
    padding: 0;
  }
  .edit-button:hover {
    background-color: $white;
    color: $primary-color;
  }
  .p-button:focus {
    box-shadow: none;
  }

  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    color: $primary-color;
  }
  .p-link:focus {
    box-shadow: 0 0 0 0.2 rem $focus-shadow-color;
  }

  .p-datatable .p-datatable-thead > tr > th {
    padding: $table-row-padding;
  }

  .p-datatable .p-datatable-tbody > tr > td {
    padding: $table-row-padding;
  }

  .payment-column {
    width: 45%;
  }
  .image-column {
    width: 45%;
  }
  .action-column {
    width: 3%;
  }
  .toggle-column {
    width: 7%;
  }
}
.toggle-switch > .p-inputswitch .p-focus .p-inputswitch-slider {
  box-shadow: none;
}

.payment-type-image {
  .p-image img {
    display: block;
    width: auto;
    height: 50px;
  }
}

.no-box-shadow:focus {
  box-shadow: none;
  background: #000;
}
