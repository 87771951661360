@import 'theme.scss';

.global-differential-container {
  .card {
    border: 1px solid $light-grey-border;
    margin-top: 10px;
  }
  .p-message.p-message-error {
    width: fit-content;
  }
  .p-tabview .p-tabview-panels {
    padding: 0px;
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 0.9rem;
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link:hover {
    text-decoration: none;
  }
  .p-datatable .p-datatable-thead > tr > th {
    padding: $table-row-sm-padding;
    font-size: $small;
    text-align: center;
    div {
      display: flex;
      justify-content: center;
    }
  }

  .p-datatable .p-datatable-tbody > tr > td {
    padding: $table-row-sm-padding;
    text-align: center;
    div {
      display: flex;
      justify-content: center;
    }
  }
  .column-quality {
    width: 15%;
  }
  .column-differential {
    width: 10%;
  }
  .column-quality-standards {
    width: 10%;
  }
  .column-remarks {
    width: 25%;
  }
  .column-moisture {
    width: 5%;
  }
  .column-screen-retention {
    width: 10%;
  }
  .column-defects {
    width: 5%;
  }
  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    color: $primary-color;
  }

  .country-select-component {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .p-dropdown-label {
      div {
        display: flex;
        gap: 7px;
        align-items: center;
      }
    }
    .p-dropdown {
      width: 250px !important;
      border-radius: 5px;
    }

    .actions {
      display: flex;
      gap: 10px;
    }

    .p-dropdown:not(.p-disabled).p-focus {
      box-shadow: $focus-shadow-color;
      border-color: $primary-color;
    }

    .country-dropdown-label {
      margin-top: -10px;
    }
    label {
      margin-top: $float-label-margin-top !important;
    }
    .mapping-label {
      color: $grey-font-color-on-hover;
    }

    .dropdown {
      width: $field-width-100;
    }

    .p-invalid:hover {
      border-color: $danger-red-color !important;
    }
    .error-message {
      margin-left: $form-error-message-margin;
      color: $danger-red-color;
      font-size: $x-small;
    }

    .star-required {
      margin-left: 3px;
      color: $danger-red-color;
      font-size: $x-large;
    }

    .p-dropdown {
      width: $field-width-100;
      outline: none;
    }

    .p-dropdown:hover {
      border-color: $primary-color;
    }
  }

  .p-button:focus {
    box-shadow: $focus-shadow-color;
  }
  .p-link:focus {
    box-shadow: $focus-shadow-color;
  }
}

.global-differential-column-container {
  display: flex;
  justify-content: space-between;

  .global-differential-column-div {
    display: flex;
    justify-content: flex-start;

    .global-differential-column-span {
      width: 50px;
    }
  }
}

@media (max-width: $device-small) {
  .global-differential-container {
    .pi {
      font-size: $x-large;
    }
    .pi-check-circle:before {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .pi-times-circle:before {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .icon {
      background-color: $primary-color;
      .pi-eye {
        color: $white;
      }
    }

    .country-select-component {
    }
    .actions {
      margin-top: -9px;
    }

    .p-paginator-bottom {
      padding-bottom: 50px;
    }
  }
}
