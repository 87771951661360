@import 'theme.scss';

.user-list-container {
  .p-tabview-nav-container {
    margin: 0 5px;
  }

  .p-tabview .p-tabview-panels {
    margin: 0 5px;
  }

  .p-datatable .p-datatable-thead > tr > th {
    padding: $table-row-padding;
  }

  .p-datatable .p-datatable-tbody > tr > td {
    padding: $table-row-padding;
  }

  .search-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 8px;
    gap: $action-header-gap;

    .search-field {
      width: 190px;

      .p-inputtext {
        width: 100%;
      }

      .p-inputtext:hover {
        border: 1px solid $primary-color;
      }

      .p-inputtext:focus {
        border-color: $primary-color;
        box-shadow: 0 0 0 0.2rem $box-shadow-primary-color;
      }
    }
  }

  .user-table {
    .full-name-column {
      width: 12%;
    }

    .subscription-type-column {
      width: 9%;
    }

    .occupation-column {
      width: 10%;
    }

    .user-role-column {
      width: 9%;
    }

    .phone-column {
      width: 9%;
    }

    .email-column {
      width: 15%;
    }

    .state-column {
      width: 13%;
    }

    .country-column {
      width: 10%;
    }

    .toggle-switch-column {
      width: 7%;

      .p-inputswitch {
        display: flex;
        align-items: center;
      }

      .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
        background-color: $primary-color;
      }

      .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:hover {
        background-color: $primary-color;
      }

      .toggle-switch {
        display: flex;
        justify-content: center;
      }

      .p-column-header-content {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .p-inputswitch.p-focus .p-inputswitch-slider {
        box-shadow: 0 0 0 0.2rem $box-shadow-primary-color;
      }
    }

    .action-column {
      width: 2%;

      .action-container {
        display: flex;
        justify-content: space-between;

        .p-button:focus {
          box-shadow: none;
        }

        .pi-edit-icon {
          border: 1px solid $white;
          background-color: $white;
          color: $primary-color;
          margin-right: 4px;
          padding: 0;
          height: 32px;
        }
      }
    }
  }

  .p-link:focus {
    box-shadow: $focus-shadow-color;
  }
}

.p-datatable .p-sortable-column:focus {
  box-shadow: none;
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
  background-color: $light-grey-on-hover;
  color: $primary-color;
}

.p-datatable .p-sortable-column.p-highlight {
  background-color: $light-grey-on-hover;
  color: $primary-color;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: $primary-color;
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover .p-sortable-column-icon {
  color: $primary-color;
}

@media (max-width: $device-medium) {
  .user-list-container {
    .search-wrapper {
      margin-top: 8px;
      margin-right: 8px;
    }
  }
}
