@import 'theme.scss';

.rejected-news-remark {
  .remark-input {
    width: 100%;
    background-color: $light-grey;
  }
}
.edit-news-container {
  height: auto;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 5px;
  .edit-news-editor-wrapper {
    .edit-news-image-wrapper {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      border: 1px solid $light-grey;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 10px;
      border-radius: 10px;
      .added-image {
        display: flex;
        flex-direction: column;
        position: relative;
        background-color: $light-grey;
        margin: 10px;
        border-radius: 5px;
        padding: 8px;
        padding-top: 12px;
        color: $white;
        .image-size {
          width: 100%;
          height: 155px;
          display: flex;
          justify-content: center;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
          video {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .close-button {
          width: 25px;
          height: 25px;
          margin-bottom: 5px;
        }
        .uploaded-image-close-button {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
  .edit-news-input-wrapper {
    .input-wrapper {
      overflow-y: auto;
      padding: 8px;
      border: 2px solid $light-grey;
      border-radius: 8px 8px 0px 0px;
      border-bottom: 0px;
      .input-width {
        width: 100%;
      }
      .card-label {
        margin-left: 8px;
      }
      .card {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: $white;
        background-clip: border-box;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0.25rem;
        padding: 8px;
      }
      .edit-news-author {
        .author-text-limit {
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
      }
      .edit-news-heading {
        padding: 8px 0px 0px 0px;
        .heading-option {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 20px;
          .trending-checkbox {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 3px;
            label {
              margin-bottom: 0;
            }
          }
          .bold-checkbox {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 3px;
            label {
              margin-bottom: 0;
            }
          }
        }
        .heading-text-limit {
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
        .trending-edit-news-wrapper {
          padding: 8px 0px 0px 0px;
          .color-button {
            margin: 4px 0px 0px 4px;
          }
          .edit-news-trending {
            padding: 8px 0px 0px 0px;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }

        .input-width {
          width: 100%;
        }
      }
      .edit-news-description {
        padding: 8px 0px 0px 0px;
        .description-text-limit {
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
      }
      .edit-news-language {
        padding: 8px 0px 0px 0px;
      }
      .edit-news-subscription-type {
        padding: 8px 0px 0px 0px;
        .subscription-lock-checkbox {
          display: flex;
          gap: 10px;
          .check-box-wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 3px;
            .check-box-label {
              margin-left: 2px;
            }
          }
        }
        .select-custom-color {
          .p-highlight {
            background-color: $primary-color;
            border-color: $white;
          }
        }
      }
      .edit-news-subscription-lock {
        padding: 8px 0px 0px 0px;
        .subscription-lock-checkbox {
          display: flex;
          gap: 10px;
          .check-box-wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 3px;
            .check-box-label {
              margin-left: 2px;
            }
          }
        }
        .select-custom-color {
          .p-highlight {
            background-color: $primary-color;
            border-color: $white;
          }
        }
      }
      .edit-news-country-list {
        padding: 8px 0px 0px 0px;
      }
      .edit-news-default-image {
        padding: 8px 0px 0px 0px;
      }
      .edit-news-published-date {
        padding: 8px 0px 0px 0px;
        .p-button {
          background-color: $primary-color;
          border-color: $primary-color;
        }
      }
      .edit-news-image-input {
        padding: 8px 0px 0px 0px;
        .image-upload {
          .image-upload-button {
            .p-button {
              border: 2px solid $primary-color;
              background-color: $primary-color;
            }
          }
          .p-inputtext {
            border-radius: 0px 6px 6px 0px;
          }
          .p-button {
            border-radius: 6px 0px 0px 6px;
          }
        }
      }
      .edit-news-file-input {
        padding: 8px 0px 0px 0px;
        .file-upload {
          .file-upload-button {
            .p-button {
              border: 2px solid $primary-color;
              background-color: $primary-color;
            }
          }
          .p-inputtext {
            border-radius: 0px 6px 6px 0px;
          }
          .p-button {
            border-radius: 6px 0px 0px 6px;
          }
        }
      }
    }
    .edit-news-action-button {
      display: flex;
      justify-content: center;
      gap: $form-button-gap;
      padding: 5px 0px;
    }
  }
  .card-invalid {
    border: 1px solid $danger-red-color;
    border-radius: 10px;
  }
  .error-message {
    margin-left: $form-error-message-margin;
    color: $danger-red-color;
    font-size: $small;
  }
  .mandatory-label {
    color: $danger-red-color;
  }
  .star-required {
    color: $danger-red-color;
  }
  .p-inputtext:hover {
    border-color: $primary-color;
  }

  .p-inputtext:enabled:focus {
    border-color: $primary-color;
    box-shadow: 0 0 0 0.2rem $box-shadow-primary-color;
  }
  .p-invalid:hover {
    border-color: $danger-red-color;
  }
  .p-invalid:focus {
    border-color: $danger-red-color;
  }
  .p-dropdown:hover {
    border-color: $primary-color;
  }
  .p-dropdown.p-invalid.p-component:hover {
    border-color: $danger-red-color;
  }
  .p-dropdown:not(.p-disabled).p-focus {
    border-color: $primary-color;
    box-shadow: 0 0 0 0.2rem $box-shadow-primary-color;
  }
  .p-multiselect:hover {
    border-color: $primary-color;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    color: $primary-color;
  }
  .p-checkbox .p-checkbox-box.p-highlight {
    border-color: $primary-color;
    background: $primary-color;
  }
  .p-checkbox .p-checkbox-box.p-highlight:hover {
    border-color: $primary-color;
    background: $primary-color;
  }
  .p-checkbox .p-checkbox-box:hover {
    border-color: $primary-color;
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    border-color: $primary-color;
    box-shadow: 0 0 0 0.2rem $box-shadow-primary-color;
  }
  .p-button:focus {
    box-shadow:
      0 0 0 2px $white,
      0 0 0 4px $box-shadow-primary-color,
      0 1px 2px 0 rgb(0, 0, 0);
  }
  .p-button:focus {
    box-shadow:
      0 0 0 2px $white,
      0 0 0 4px $box-shadow-primary-color,
      0 1px 2px 0 rgb(0, 0, 0);
  }
  .default-button {
    background: $primary-color;
    border-color: $primary-color;

    &:hover {
      background: $on-primary-hover;
      border-color: $primary-color;
    }

    margin: 10px 0px 0px;
  }
}
.country-flag {
  display: flex;
  justify-content: center;
  text-align: center;
  gap: 10px;
}
.proceed-button {
  background-color: $primary-color;
  border: none;
}
.proceed-button:hover {
  background-color: $on-primary-hover;
}
.retry-button {
  background-color: $primary-color;
  border: none;
}
.retry-button:hover {
  background-color: $on-primary-hover;
}
.dismiss-button {
  background-color: $grey-primary-color;
  border: none;
}
.dismiss-button:hover {
  background-color: $grey-secondary-color;
}
.edit-news-country-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 260px;
  text-align: start;
  color: $primary-color;
}
.p-multiselect-panel {
  .p-inputtext:enabled:hover {
    border-color: $primary-color;
  }
  .p-checkbox .p-checkbox-box.p-highlight {
    border-color: $primary-color;
    background: $primary-color;
  }
  .p-checkbox .p-checkbox-box.p-highlight:hover {
    border-color: $primary-color;
    background: $primary-color;
  }
  .p-checkbox .p-checkbox-box:hover {
    border-color: $primary-color;
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    border-color: $primary-color;
    box-shadow: 0 0 0 0.2rem $box-shadow-primary-color;
  }
}
.p-multiselect:not(.p-disable).p-focus {
  box-shadow: 0 0 0 0.2rem $box-shadow-primary-color;
  border-color: $primary-color;
}
@media (max-width: $device-small) {
  .edit-news-container {
    grid-template-columns: 1fr;
  }
}
