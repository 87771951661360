@import 'theme.scss';

.p-component-overlay-leave {
  animation: $modal-overlay-animation;
}

.add-payment-container {
  min-width: $field-width-100;
  border-radius: $modal-border;
  padding: $form-container-padding;
  box-shadow: $form-container-box-shadow;
  margin-top: 5px;

  .required-field {
    color: $danger-red-color;
    font-weight: $font-medium;
    font-size: $x-large;
    margin: 3px;
  }

  .payment-name-field-wrapper {
    width: $field-width-60;

    label {
      left: 4px;
    }

    .error-message {
      color: $danger-red-color;
    }

    .p-float-label label {
      margin-top: $float-label-margin-top;
    }

    .p-error {
      margin-left: 4px;
    }

    .add-payment-label {
      color: $grey-font-color-on-hover;
    }
  }

  .payment-logo-container {
    width: $field-width-60;
    .payment-logo-label {
      font-size: $x-small;
      margin-bottom: -1px;
    }
    .logo-upload-container {
      background: $white;
      padding: 0.7rem;
      border: 1px solid $light-grey-border;
      color: $dark_grayish_blue;
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
      position: relative;
      .payment-logo-wrapper {
        display: flex;
        justify-content: center;
      }
      .logo-wrapper {
        display: flex;
        justify-content: center;
        border-radius: 7px;
        width: 150px;
        height: 150px;
        padding: 5px;
      }
      .logo-cancel-button-container {
        display: flex;
        justify-content: center;
        position: absolute;
        right: 18px;
      }
    }
    .logo-choose-title {
      margin-left: -9px;
      margin-bottom: 0;
    }
    img {
      width: $field-width-100;
      height: 100%;
    }
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  label {
    display: inline-block;
    margin-bottom: -1px;
    margin-left: 8px;
    margin-top: 1rem;
  }

  .p-inputtext.p-invalid.p-component {
    border-color: $danger-red-color;
  }

  .p-inputtext:enabled:hover {
    border-color: $primary-color;
  }

  .p-inputtext:enabled:focus {
    border-color: $primary-color;
    box-shadow: 0 0 0 0.2rem $box-shadow-primary-color;
  }
  .p-button.p-button-danger.p-button-text {
    color: $danger-red-color;
    background: $white;
    border: none;
  }

  .p-button.p-button-danger.p-button-text:enabled:hover {
    color: $danger-red-color;
    background: $white;
    border: none;
  }

  .p-fileupload .p-fileupload-buttonbar {
    padding-left: 8px;
  }

  .p-fileupload .p-fileupload-buttonbar .p-button {
    padding: $button-label-padding;
  }

  .p-button {
    color: $white;
    background: $primary-color;
    border: 1px solid $primary-color;
  }

  .p-button:enabled:hover,
  .p-button:not(button):not(a):not(.p-disabled):hover {
    background: $on-primary-hover;
    color: $white;
    border: 1px solid $on-primary-hover;
  }

  .p-button:focus {
    box-shadow: $focus-shadow-color;
  }

  p-button:enabled:active,
  .p-button:not(button):not(a):not(.p-disabled):active {
    background: $primary-color;
    color: $white;
    border-color: $primary-color;
  }

  .p-button.p-button-icon-only.p-button-rounded {
    margin-right: -12px;
  }

  .p-float-label label {
    position: absolute;
    pointer-events: none;
    transition-property: all;
    transition-timing-function: ease;
    margin-left: $float-label-margin-left;
    font-weight: $font-normal;
    color: $grey-font-color-on-hover;
  }
}

@media screen and (max-width: $device-small) {
  .add-payment-container {
    .payment-name-field-wrapper {
      width: $field-width-100;
    }
    .payment-logo-container {
      width: $field-width-100;
    }
    .button-container {
      margin-top: 12px;
    }
  }
}
