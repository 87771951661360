@import 'theme.scss';

.button-component-container {
  width: max-content;
  .neutral {
    min-width: $button-min-width;
    color: $primary-color;
    padding: $button-label-padding;
    background-color: $white;
    border: 1px solid $primary-color;

    &:hover {
      background-color: $white;
      color: $on-primary-hover;
      border-color: $on-primary-hover;
    }

    &:focus {
      box-shadow:
        0 0 0 2px $white,
        0 0 0 4px $primary-color,
        0 1px 2px 0 $grey-font-color-on-hover;
    }

    &:enabled:active,
    &:not(button):not(a):not(.p-disabled):active {
      background: $white;
      color: $on-primary-hover;
      border-color: $on-primary-hover;
    }
  }

  .action {
    color: $white;
    border: 1px solid $primary-color;
    background-color: $primary-color;
    min-width: $button-min-width;
    padding: $button-label-padding;

    &:hover {
      color: $white;
      border: 1px solid $on-primary-hover;
      background-color: $on-primary-hover;
      border-color: $on-primary-hover;
    }

    &:focus {
      box-shadow:
        0 0 0 2px $white,
        0 0 0 4px $primary-color,
        0 1px 2px 0 $grey-font-color-on-hover;
    }

    &:enabled:active,
    &:not(button):not(a):not(.p-disabled):active {
      background: $on-primary-hover;
      color: $white;
      border-color: $on-primary-hover;
    }
  }

  .only-label .p-button-icon-left {
    margin-right: 0;
  }
}

@media (max-width: $device-small) {
  .button-component-container {
    .icon {
      color: $white;
      min-width: 0;
      padding: 0;
      border-radius: 5px;
      width: 2rem;
      height: 2rem;
      cursor: pointer;
    }
  }
}
