@import 'theme.scss';
.global-price-main-wrapper {
  // gap: 20px;
  margin-bottom: 50px;
  .tab-panel-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    .download-link {
      display: flex;
      justify-content: flex-end;
      position: absolute;
      right: 0px;
      a {
        text-decoration: underline;
        color: blue;
        padding-right: 5px;
        cursor: pointer;
      }
    }
    .country-drop-down-wrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .p-dropdown:not(.p-disabled):hover {
        border-color: $primary-color !important;
        box-shadow: 0 0 0 0.2rem $box-shadow-primary-color !important;
      }
      .p-dropdown:not(.p-disabled).p-focus {
        border-color: $primary-color !important;
        box-shadow: 0 0 0 0.2rem $box-shadow-primary-color !important;
      }
    }
    .file-upload-card {
      width: 250px;
      display: flex;
      flex-direction: column;
      gap: 4px;

      .p-fileupload {
        .p-fileupload-buttonbar {
          background: #f8f9fa;
          padding: 1.25rem;
          color: #343a40;
          border-top-right-radius: 6px;
          border-top-left-radius: 6px;
          gap: 0.5rem;
          border-bottom-right-radius: 6px;
          border-bottom-left-radius: 6px;
          border-bottom: 1px solid #dee2e6;
        }
        .p-fileupload .p-fileupload-content {
          border: 1px solid $white;
        }
      }
      .p-button:nth-child(1):focus {
        box-shadow: $focus-shadow-color;
      }

      .p-button.p-button-icon-only.p-button-rounded {
        border-radius: 50%;
        height: 2.3rem;
        width: 2.3rem;
      }

      .p-button.p-button-icon-only.p-button-rounded:first-child {
        border-color: $primary-color;

        .pi-file:before {
          color: $primary-color;
        }
      }

      .p-fileupload-row > div {
        width: $field-width-100;
      }

      .p-fileupload-row {
        display: block;

        span {
          display: none;
        }

        button {
          display: none;
        }
      }
      .p-progressbar {
        border: 0 none;
        background: none;
      }
      .p-fileupload .p-fileupload-content {
        padding: 0;
        border: 1px solid $white;
      }

      .p-fileupload-filename {
        padding: 15px 0px 0px 19px;
      }

      .p-fileupload .p-fileupload-row > div {
        padding: 0;
      }
      .custom-upload-btn {
        display: none;
      }
    }
    .preview-button-wrapper {
      display: flex;
      justify-content: flex-end;
    }
    label {
      font-size: 13px;
      padding-left: 5px;
    }
    .error-massage {
      font-size: 13px;
      padding-left: 5px;
      color: red;
    }
  }
  .p-tabview {
    border: 1px solid lightgray;
    border-radius: 6px;
    overflow: hidden;
    .p-tabview-nav li.p-highlight .p-tabview-nav-link {
      border-bottom: 1px solid $primary-color !important;
      color: $primary-color;
      border-color: $primary-color !important;
    }
    .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
      box-shadow: none;
    }
    .p-tabview-nav .p-tabview-ink-bar {
      background-color: $primary-color;
    }
  }
  .p-dropdown {
    width: 250px;
    .p-inputtext div {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
}
.p-dropdown-panel {
  .p-dropdown-item div {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
