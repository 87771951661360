@import 'theme.scss';

.cot-reports-container {
  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: $white;
    border-color: $primary-color;
    color: $primary-color;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-tabview .p-tabview-nav .p-tabview-ink-bar {
    background-color: $primary-color;
  }
}

.lot-wise-report-container {
  .p-dropdown:not(.p-disabled).p-focus {
    border: 1px solid $primary-color;
    box-shadow: 0 0 0 0.2rem rgba(80, 179, 246, 0.2509803922);
  }

  .p-dropdown:not(.p-disabled):hover {
    border: 1px solid $primary-color;
  }

  .table-container {
    .action-button-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .edit-button {
        background-color: $white;
        color: $primary-color;
        border: none;
        padding: 0;
        &:hover {
          background-color: $white;
          color: $primary-color;
        }
        &:focus {
          box-shadow: none;
        }
      }
      .deleted-button {
        background-color: $white;
        color: $danger-red-color;
        border: none;
        padding: 0;
        &:hover {
          background-color: $white;
          color: $danger-red-color;
        }
        &:focus {
          box-shadow: none;
        }
      }
    }

    .left-table {
      padding-bottom: 12px;

      .date-column {
        width: 20%;
      }
      .terminal-level-column,
      .open-interest-column,
      .net-change-column {
        width: 25%;
      }

      .action-column {
        width: 5%;
      }
    }
    .right-table {
      .category-dropdown {
        padding-bottom: 12px;
        width: 100%;
        .category-dropdown-select {
          min-width: 250px;
        }
      }

      .action-column {
        width: 5%;
      }

      .date-column {
        width: 12%;
      }
    }

    .p-datatable .p-datatable-thead > tr > th {
      padding: $table-row-padding;
    }

    .p-datatable .p-datatable-tbody > tr > td {
      padding: $table-row-padding;
    }

    .p-paginator {
      padding: 4px;
    }
  }
}
