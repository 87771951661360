@import 'theme.scss';

.list-mobile-container {
  .p-datatable .p-datatable-thead > tr > th {
    padding: $table-row-padding;
  }

  .p-datatable .p-datatable-tbody > tr > td {
    padding: $table-row-padding;
  }

  .list-mobile-table {
    margin-bottom: $table-margin-bottom;

    .current-version-column,
    .build-number-column,
    .mobile-os-column {
      width: 16%;
    }

    .build-date-column {
      width: 29%;
    }
    .is-active-column {
      width: 10%;
    }
    .isForceUpdate-column {
      width: 8%;
    }
  }
  .toggle-switch {
    display: flex;
    align-items: center;
    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
      background-color: $primary-color;
    }

    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:hover {
      background-color: $primary-color;
    }
    .p-inputswitch.p-focus .p-inputswitch-slider {
      box-shadow: 0 0 0 0.2rem $box-shadow-primary-color;
    }
  }
}
