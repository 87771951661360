@import 'theme.scss';

.list-news-feed-layout {
  .list-news-feed-container {
    .p-datatable .p-datatable-thead > tr > th {
      padding: $table-row-padding;
    }

    .p-datatable .p-datatable-tbody > tr > td {
      padding: $table-row-padding;
    }

    .search-dropdown-wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
      gap: $action-header-gap;

      .search-field {
        width: 190px;

        .p-inputtext {
          width: 100%;
        }

        .p-inputtext:hover {
          border: 1px solid $primary-color;
        }

        .p-inputtext:focus {
          border: 1px solid $primary-color;
          box-shadow: $focus-shadow-color;
        }
      }
      .dropdown-container {
        display: flex;
        gap: 20px;
        .language-dropdown {
          .p-dropdown {
            width: 180px;
          }

          .p-dropdown:hover {
            border: 1px solid $primary-color;
          }

          .p-dropdown:focus {
            border: 1px solid $primary-color;
            box-shadow: $focus-shadow-color;
          }

          .p-dropdown:not(.p-disabled).p-focus {
            border: 1px solid $primary-color;
            box-shadow: $focus-shadow-color;
          }
        }
        .status-filter-dropdown {
          .p-dropdown {
            width: 180px;
          }

          .p-dropdown:hover {
            border: 1px solid $primary-color;
          }

          .p-dropdown:focus {
            border: 1px solid $primary-color;
            box-shadow: $focus-shadow-color;
          }

          .p-dropdown:not(.p-disabled).p-focus {
            border: 1px solid $primary-color;
            box-shadow: $focus-shadow-color;
          }
        }
      }
    }

    .list-news-table {
      width: 100%;

      .news-status-column {
        width: 6%;
      }
      .news-subject-column {
        width: 32%;
      }
      .news-author-column {
        width: 20%;
      }
      .news-date-column {
        width: 16%;
      }
      .news-trending-column {
        width: 12%;
        .toggle-switch {
          display: flex;
          align-items: center;
          justify-content: center;
          .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
            background-color: $primary-color;
          }
          .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:hover {
            background-color: $primary-color;
          }
          .p-inputswitch.p-focus .p-inputswitch-slider {
            box-shadow: 0 0 0 0.2rem $box-shadow-primary-color;
          }
        }
      }
      .news-views-column {
        width: 4%;
      }
      .news-actions-column {
        width: 10%;

        .action-container {
          display: flex;
          justify-content: space-between;

          .p-button:focus {
            box-shadow: none;
          }
          .empty-button {
            border: 1px solid $white;
            background-color: $white;
            color: $primary-color;
            cursor: default;
          }
          .user-preview-icon {
            border: 1px solid $white;
            background-color: $white;
            color: $primary-color;
            margin-right: 4px;
            padding: 0;
            .user-preview-image {
              width: 64px;
              height: 54px;
            }
          }
          .reject-remark-icon {
            border: 1px solid $white;
            background-color: $white;
            color: $primary-color;
            margin-right: 4px;
            padding: 0;
            .remark-preview-image {
              width: 36px;
              height: 50px;
              margin-top: 2px;
            }
          }

          .admin-review-icon {
            border: 1px solid $white;
            background-color: $white;
            color: $primary-color;
            margin-right: 4px;
            padding: 0;
            .admin-review-image {
              width: 72px;
              height: 54px;
              margin-top: 2px;
            }
          }
          .hide-edit-button {
            display: none;
          }
          .pi-edit-icon {
            border: 1px solid $white;
            background-color: $white;
            color: $primary-color;
            margin-right: 4px;
            padding: 0;
          }

          .pi-delete-icon {
            border: 1px solid $white;
            background-color: $white;
            color: $danger-red-color;
            margin-right: 4px;
            padding: 0;
          }
        }
      }

      .custom-status-container {
        .custom-status-image-button {
          border: 1px solid $white;
          background-color: $white;
          color: $primary-color;
          margin-right: 4px;
          padding: 0;
          .custom-approved-status-image {
            width: 64px;
            height: 64px;
            cursor: pointer;
          }
          .custom-rejected-status-image {
            width: 54px;
            height: 54px;
            cursor: pointer;
            margin-left: 8px;
          }
          .custom-pending-status-image {
            width: 54px;
            height: 54px;
            margin-left: 4px;
            cursor: pointer;
          }
        }
        .p-button:focus {
          box-shadow: none;
        }
      }
    }

    .p-link:focus {
      box-shadow: $focus-shadow-color;
    }
    .pagination-divide {
      margin: 0;
    }
  }
}
.edit-approved-news-dialog {
  min-width: 400px;
  max-width: 400px;
  background-color: $white;

  .p-dialog-header {
    border-bottom: 1px solid $light-grey-border;
    padding: 12px 10px 5px 24px;
  }

  .edit-news-otp-field {
    padding-top: 28px;
    .field {
      width: 100%;
      label {
        left: $form-label;
        margin-top: $float-label-margin-top;
        margin-left: $float-label-margin-left;
      }
      .p-float-label > label {
        margin-left: $float-label-margin-left;
        color: $grey-font-color-on-hover;
      }
      .p-float-label > label.p-error {
        color: $danger-red-color;
      }
      .span-text-color {
        color: $danger-red-color;
        font-size: $x-large;
      }
      .error-message {
        font-size: $small;
        margin-left: $form-error-message-margin;
      }
      .p-invalid + .form-fields:enabled:hover,
      .p-invalid-hover {
        border-color: $danger-red-color;
      }
      .otp-error-message {
        font-size: $small;
        margin-left: $form-error-message-margin;
        color: $danger-red-color;
      }
    }
  }

  .p-inputtext:hover {
    border-color: $primary-color;
  }

  .edit-approved-news-dialog-footer {
    display: flex;
    justify-content: flex-end;
    gap: $form-button-gap;
  }
}

.trending-news-dialog {
  width: 430px;

  .p-dialog-header {
    border-bottom: 1px solid $light-grey-border;
    padding: 16px 10px 8px 24px;
  }

  .trending-news-time-dropdown {
    margin-top: 30px;
    width: 80%;

    &:hover {
      border-color: $primary-color;
    }
  }
  .p-inputtext:enabled:hover {
    border-color: $primary-color;
  }

  .p-dropdown:not(.p-disabled).p-focus,
  .p-inputtext:enabled:focus {
    border-color: $primary-color;
    box-shadow: $focus-shadow-color;
  }
  .trending-news-div {
    margin: 30px 0px;

    .error-for-hours {
      margin-top: 4px;
      margin-left: 6px;
      color: $danger-red-color;
      font-size: $small;
    }
  }
  .trending-news-time-input-field {
    width: 80%;
  }
  .p-dropdown.p-invalid.p-component,
  .p-inputtext.p-invalid.p-component {
    border-color: $danger-red-color;
  }

  .p-error {
    color: $danger-red-color;
    left: $form-label;
  }
  .error-message {
    font-size: $small;
    margin-left: $form-error-message-margin;
  }

  label {
    left: $form-label;
    margin-left: $float-label-margin-left;
    color: $grey-font-color-on-hover;
    margin-top: -10px;
  }

  .required-star {
    color: $danger-red-color;
    font-weight: $font-medium;
    font-size: $x-large;
    margin: 0px 3px;
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .p-button {
      margin-left: $form-button-gap;
    }
  }
}
.free-news-dialog {
  min-width: 400px;
  max-width: 400px;
  background-color: $white;

  .p-dialog-header {
    border-bottom: 1px solid $light-grey-border;
    padding: 12px 10px 5px 24px;
  }

  .free-news-input-wrapper {
    padding-top: 28px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .free-news-checkbox {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      .p-checkbox .p-checkbox-box.p-highlight {
        border-color: $primary-color;
        background: $primary-color;
      }
      .p-checkbox .p-checkbox-box.p-highlight:hover {
        border-color: $primary-color;
        background: $primary-color;
      }
      .p-checkbox .p-checkbox-box:hover {
        border-color: $primary-color;
      }
      .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
        border-color: $primary-color;
        box-shadow: 0 0 0 0.2rem $box-shadow-primary-color;
      }
    }
  }

  .p-inputtext:hover {
    border-color: $primary-color;
  }

  .free-news-approved-news-dialog-footer {
    display: flex;
    justify-content: flex-end;
    gap: $form-button-gap;
  }
}
@media (max-width: $device-medium) {
  .list-news-feed-layout {
    .list-news-feed-container {
      .search-dropdown-wrapper {
        .search-field {
          width: 58%;
          .p-inputtext {
            width: 100%;
          }
        }
        .dropdown-container {
          .language-dropdown {
            width: 40%;

            .p-dropdown {
              width: 100%;
            }
          }
          .status-filter-dropdown {
            width: 46%;
            .p-dropdown {
              width: 100%;
            }
          }
        }
      }

      .list-news-table {
        width: auto;

        .news-actions-column {
          .action-container {
            display: flex;

            .pi-eye-icon {
              border: 1px solid $white;
              background-color: $white;
              color: $primary-color;
              margin-right: 0;
            }

            .pi-edit-icon {
              border: 1px solid $white;
              background-color: $white;
              color: $primary-color;
              margin-right: 0;
            }

            .pi-delete-icon {
              border: 1px solid $white;
              background-color: $white;
              color: $danger-red-color;
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}

@media (max-width: $device-small) {
  .edit-approved-news-dialog {
    min-width: 92%;
    max-width: 92%;
  }
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background-color: $light-grey;
  color: $primary-color;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  background-color: $light-grey;
}
