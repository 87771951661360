@import 'theme.scss';

.usda-report-table {
  .usda-columns-container {
    .upcoming-column {
      .upcoming {
        font-weight: $font-semibold;
        cursor: pointer;
      }

      width: 5%;
    }

    .countryName {
      width: 5%;
    }

    .tag-column {
      width: 7%;
    }

    .content-column {
      width: 20%;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
    }

    .title-column {
      width: 8%;
    }

    .report-link-column {
      width: 10%;
    }

    .publish-date-column {
      width: 6%;
    }

    .usda-actions-column {
      width: 2%;

      .action-container {
        .pi-delete-icon {
          border: 1px solid $white;
          background-color: $white;
          color: $danger-red-color;
          margin-right: 4px;
          padding: 0;
        }
      }
    }
  }
}
