@import 'theme.scss';

.weekly-wise-dialog-modal {
  width: 65vw;
  .constant-inputs-container {
    margin-top: 20px;
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 30px 20px;

    .calendar-field {
      width: $field-width-100;
      &:hover {
        border-color: $primary-color;
      }
      .p-button.p-button-icon-only {
        width: 3rem;
        padding: 0.75rem 0;
      }

      .p-button-icon-only {
        justify-content: center;
      }
      .p-button {
        margin: 0;
        color: $white;
        background: $primary-color;
        border: 1px solid $primary-color;
        transition:
          background-color 0.2s,
          color 0.2s,
          border-color 0.2s,
          box-shadow 0.2s;
      }
      .p-button:focus {
        box-shadow: $focus-shadow-color;
      }
      .p-inputtext:enabled:hover {
        border-color: $primary-color;
      }
      .p-inputtext:enabled:focus {
        box-shadow: $focus-shadow-color;
        border-color: $primary-color;
      }
      &.error-field {
        .p-inputtext:enabled {
          border-color: $danger-red-color;
        }
        .p-inputtext:enabled:hover {
          border-color: $danger-red-color;
        }
        .p-inputtext:enabled:focus {
          border-color: $danger-red-color !important;
        }
      }
      .field-label {
        margin-top: $float-label-margin-top;
        left: $form-label;
        margin-left: $float-label-margin-left;
        &.error-label {
          color: $danger-red-color;
        }
      }
    }
    .text-field {
      width: $field-width-100;

      &:hover {
        border-color: $primary-color;
      }
      &:focus {
        border: 1px solid $primary-color;
        box-shadow: $focus-shadow-color;
      }
      &.error-field {
        border: 1px solid $danger-red-color;
        border-radius: 4px;
      }
      .error-field:hover {
        border: 1px solid $danger-red-color;
      }
    }

    .error-field:focus {
      border: 1px solid $danger-red-color !important;
    }
    .field-label {
      margin-top: $float-label-margin-top !important;
      left: $form-label;
      margin-left: $float-label-margin-left;
      &.error-label {
        color: $danger-red-color;
      }
    }
    .p-inputtext.p-invalid.p-component {
      &:hover {
        border-color: $danger-red-color;
      }
    }

    .required-text-field {
      color: $danger-red-color;
      margin-left: 2px;
      font-size: $x-large;
    }
  }

  .dynamic-rows-container {
    .dropdown-category {
      width: 30%;

      .dropdown-field {
        width: $field-width-100;
        &:hover {
          border-color: $primary-color;
        }
      }
      .p-dropdown:not(.p-disabled).p-focus {
        border: 1px solid $primary-color;
        box-shadow: $focus-shadow-color;
      }
      .error-field.p-dropdown:not(.p-disabled).p-focus {
        border: 1px solid $danger-red-color !important;
      }
      .error-field {
        border: 1px solid $danger-red-color;
        border-radius: 4px;
      }
      .error-field:hover {
        border: 1px solid $danger-red-color;
      }
    }

    .input-field-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 25px;
      .category-name {
        width: 200px;
        strong {
          font-size: 17px;
        }
      }
      .input-group {
        display: flex;
        justify-content: space-between;
        gap: 18px 15px;
      }
    }

    .text-field {
      width: $field-width-100;

      &:hover {
        border-color: $primary-color;
      }
      &:focus {
        border: 1px solid $primary-color;
        box-shadow: $focus-shadow-color;
      }
      &.error-field {
        border: 1px solid $danger-red-color;
        border-radius: 4px;
      }
      .error-field:hover {
        border: 1px solid $danger-red-color;
      }
    }
    .error-field:focus {
      border: 1px solid $danger-red-color !important;
    }
    .required-text-field {
      color: $danger-red-color;
      margin-left: 2px;
      font-size: $x-large;
    }
  }

  .add-button-container {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    margin-top: 15px;

    .edit-button {
      background-color: $primary-color;
      border: 1px solid $primary-color;
      width: 36px;
      height: 36px;

      &:hover {
        color: $white;
        border: 1px solid $on-primary-hover;
        background-color: $on-primary-hover;
        border-color: $on-primary-hover;
      }
      &:focus {
        box-shadow:
          0 0 0 2px $white,
          0 0 0 4px $primary-color,
          0 1px 2px 0 $grey-font-color-on-hover;
      }
    }

    .delete-button {
      width: 36px;
      height: 36px;

      background-color: $danger-red-color;
      border: 1px solid $danger-red-color;
      &:hover {
        background-color: $danger-red-color;
      }
      &:focus {
        box-shadow: $form-container-box-shadow;
      }
    }
  }

  .button-container {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
  }
  .error-message {
    color: $danger-red-color;
    margin-left: $form-error-message-margin;
  }
  .field-label {
    margin-top: $float-label-margin-top !important;
    left: $form-label;
    margin-left: $float-label-margin-left;
    &.error-label {
      color: $danger-red-color;
    }
  }

  .p-card {
    margin: 20px 0px;

    .p-card-body {
      padding: 10px 20px;
    }
    .p-card-content {
      padding: 20px 0px 5px 0px;
    }
    .p-card-title {
      font-size: 1.1rem;
    }
  }
}
