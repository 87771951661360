@import 'theme.scss';

.port-wise-stock-report-container {
  .port-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 4px;
    gap: 6px;
    width: 100%;
    .dropdown-wrapper {
      display: flex;
      align-items: center;
      gap: 20px;

      .p-dropdown {
        min-width: 180px;
      }
      .p-dropdown:not(.p-disabled).p-focus {
        border: 1px solid $primary-color;
        box-shadow: 0 0 0 0.2rem rgba(80, 179, 246, 0.2509803922);
      }

      .p-dropdown:not(.p-disabled):hover {
        border: 1px solid $primary-color;
      }
    }
  }

  .port-wise-table-data {
    .p-datatable .p-datatable-thead > tr > th {
      padding: $table-row-padding;
      min-width: 120px;
    }

    .p-datatable .p-datatable-tbody > tr > td {
      padding: $table-row-padding;
    }
    .actions-column {
      width: 8%;
    }

    .action-button-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .edit-button {
        background-color: $white;
        color: $primary-color;
        border: none;
        padding: 0;
        &:hover {
          background-color: $white;
          color: $primary-color;
        }
        &:focus {
          box-shadow: none;
        }
      }
      .deleted-button {
        background-color: $white;
        color: $danger-red-color;
        border: none;
        padding: 0;
        &:hover {
          background-color: $white;
          color: $danger-red-color;
        }
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}

@media (max-width: $device-small) {
  .port-wise-stock-report-container {
    .port-header-container {
      .dropdown-wrapper {
        gap: 8px;
        width: 86%;
        .p-dropdown {
          width: 60%;
          min-width: 60px;
        }
      }
    }
  }
}
