@import 'theme.scss';

.trader-wise-report-dialog-wrapper {
  width: 70vw;
  background-color: $white;

  .total-traders-container {
    padding-bottom: 8px;
    .total-traders-title {
      text-transform: uppercase;
      color: #343a40;
    }
  }

  .footer-wrapper {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }

  .p-link:focus {
    box-shadow: $focus-shadow-color;
  }
  .p-dialog-title {
    text-transform: uppercase;
  }
  .form-wrapper {
    display: flex;
    gap: 12px;
    margin-bottom: 1rem;
    .form-fields {
      width: 100%;
      .required-text-field {
        color: $danger-red-color;
        margin-left: 2px;
        font-size: $x-large;
      }

      .field-label {
        margin-top: $float-label-margin-top;
        left: $form-label;
        margin-left: $float-label-margin-left;
        &.error {
          color: $danger-red-color;
        }
      }
      .input-field {
        width: $field-width-100;
        &:hover {
          border: 1px solid $primary-color;
        }
        &.error {
          border: 1px solid $danger-red-color !important;
          border-radius: 4px;
        }
      }
      .error-message {
        color: $danger-red-color;
        margin-left: $form-error-message-margin;
      }

      .calendar-field {
        width: $field-width-100;

        .p-button.p-button-icon-only {
          width: 3rem;
          padding: 0.75rem 0;
        }

        .p-button-icon-only {
          justify-content: center;
        }
        .p-button {
          margin: 0;
          color: $white;
          background: $primary-color;
          border: 1px solid $primary-color;
          transition:
            background-color 0.2s,
            color 0.2s,
            border-color 0.2s,
            box-shadow 0.2s;
        }
        .p-button:focus {
          box-shadow: $focus-shadow-color;
        }
        .p-inputtext:enabled:hover {
          border-color: $primary-color;
        }
        &.error {
          .p-inputtext:enabled {
            border-color: $danger-red-color;
          }
          .p-inputtext:enabled:hover {
            border-color: $danger-red-color;
          }
          .p-inputtext:enabled:focus {
            border-color: $danger-red-color !important;
          }
        }
      }
    }
  }

  .input-container {
    width: 100%;

    .form-card {
      margin-bottom: 12px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }

    .form-title {
      text-transform: uppercase;
      color: #343a40;
      padding-top: 12px;
    }

    .field-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
      padding-bottom: 12px;

      .difference-title,
      .category-title {
        color: #343a40;
        font-weight: $font-bold;
        margin-top: 4px;
      }
    }

    .input-field-wrapper {
      margin: 8px 0 16px;

      .input-field {
        width: $field-width-100;
        &:hover {
          border: 1px solid $primary-color;
        }
        &.error {
          border: 1px solid $danger-red-color;
          border-radius: 4px;
        }
      }
      .error.p-inputtext:enabled:focus {
        border-color: $danger-red-color !important;
      }

      .required-text-field {
        color: $danger-red-color;
        margin-left: 2px;
        font-size: $x-large;
      }

      .field-label {
        margin-top: $float-label-margin-top;
        left: $form-label;
        margin-left: $float-label-margin-left;
        &.error {
          color: $danger-red-color;
        }
      }

      .error-message {
        color: $danger-red-color;
        margin-left: $form-error-message-margin;
      }

      .p-dropdown {
        width: 100%;
      }

      .p-dropdown:not(.p-disabled).p-focus {
        border: 1px solid $primary-color;
        box-shadow: $focus-shadow-color;
      }
      .p-dropdown:not(.p-disabled).p-focus {
        border: 1px solid $primary-color;
        box-shadow: $focus-shadow-color;
      }

      .p-dropdown:not(.p-disabled):hover {
        border: 1px solid $primary-color;
      }

      .error.p-dropdown:not(.p-disabled).p-focus {
        border: 1px solid $danger-red-color;
      }
      .error.p-dropdown:not(.p-disabled).p-focus {
        border: 1px solid $danger-red-color;
      }

      .error.p-dropdown:not(.p-disabled).p-focus {
        border: 1px solid $danger-red-color;
      }

      .error.p-dropdown:not(.p-disabled):hover {
        border: 1px solid $danger-red-color;
      }
    }

    .button-content {
      display: flex;
      justify-content: flex-end;
      gap: 12px;
      .edit-button {
        background-color: $primary-color;
        border: 1px solid $primary-color;
        width: 36px;
        height: 36px;

        &:hover {
          color: $white;
          border: 1px solid $on-primary-hover;
          background-color: $on-primary-hover;
          border-color: $on-primary-hover;
        }
        &:focus {
          box-shadow:
            0 0 0 2px $white,
            0 0 0 4px $primary-color,
            0 1px 2px 0 $grey-font-color-on-hover;
        }
      }

      .delete-button {
        width: 36px;
        height: 36px;

        background-color: $danger-red-color;
        border: 1px solid $danger-red-color;
        &:hover {
          background-color: #d32f2f;
        }
        &:focus {
          box-shadow:
            0 0 0 2px #fff,
            0 0 0 4px #ffcece,
            0 1px 2px 0 #343a40;
        }
      }
    }

    .header-field {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 50%;
      gap: 12px;
      padding-bottom: 12px;
      .calendar-field {
        width: $field-width-100;

        .p-button.p-button-icon-only {
          width: 3rem;
          padding: 0.75rem 0;
        }

        .p-button-icon-only {
          justify-content: center;
        }
        .p-button {
          margin: 0;
          color: $white;
          background: $primary-color;
          border: 1px solid $primary-color;
          transition:
            background-color 0.2s,
            color 0.2s,
            border-color 0.2s,
            box-shadow 0.2s;
        }
        .p-button:focus {
          box-shadow: $focus-shadow-color;
        }
        .p-inputtext:enabled:hover {
          border-color: $primary-color;
        }
        &.error {
          .p-inputtext:enabled {
            border-color: $danger-red-color;
          }
          .p-inputtext:enabled:hover {
            border-color: $danger-red-color;
          }
          .p-inputtext:enabled:focus {
            border-color: $danger-red-color !important;
          }
        }
      }
    }
    .p-card .p-card-body {
      padding: 0.25rem 1.25rem;
    }
  }
}
