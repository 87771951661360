@import 'theme.scss';

.auth-screen {
  background-position: 20% 10%;
  object-fit: cover;
  min-height: calc(100vh - 71px);
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
  z-index: -1;
  margin-top: -16px;

  .auth-screen-container {
    width: $field-width-100;
    display: flex;
    justify-content: center;
    align-items: center;

    .auth-screen-logo {
      width: 50%;
      display: flex;
      justify-content: center;

      img {
        width: $field-width-100;
        max-width: 500px;
        min-height: auto;
        position: relative;
      }
    }

    .p-tabview-nav-content {
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    }
    .auth-screen-tabs {
      width: 50%;
      display: flex;
      justify-content: center;
      min-height: 220px;

      .auth-tabs {
        width: $field-width-100;
      }
      .card {
        background-color: $white;
        max-width: 430px;
        min-height: 435px;
        position: relative;
        box-shadow: 0 25px 45px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        padding: 1rem 2rem 0px 2rem;

        .select-button {
          .p-disabled {
            background-color: $light-grey-on-hover;
          }
          .p-selectbutton .p-button {
            width: 50%;
            justify-content: center;
            height: 40px;
          }
          .p-selectbutton .p-button.p-highlight {
            background-color: $primary-color !important;
            border-color: $primary-color !important;
            height: 40px;
          }
          .p-button:focus {
            box-shadow: none;
          }
          .p-button-label {
            font-weight: 500;
          }
          .p-button:not(button):not(a):not(.p-disabled):active {
            background: $white;
            border: 1px solid $white-smoke;
          }
        }
        .login-text {
          font-size: $large;
          margin-bottom: 0.3rem;
          text-transform: uppercase;
          font-weight: $font-semibold;
          color: $grey-font-color;
        }
      }

      @keyframes gradient {
        0% {
          background-position: 0% 50%;
        }

        50% {
          background-position: 100% 50%;
        }

        100% {
          background-position: 0% 50%;
        }
      }
      .p-tabview-nav {
        display: flex;
        justify-content: space-around;
        width: $field-width-100;
      }
      .p-tabview .p-tabview-nav li {
        width: 50%;
      }
      .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
        display: block;
        text-align: center;
        color: $primary-color;
      }
      .p-tabview .p-tabview-nav li .p-tabview-nav-link {
        border: none;
        border-radius: 0;
        display: block;
        text-align: center;
        background: $white-smoke;
        padding: 0.9rem;
        text-decoration: none;
        color: $grey-font-color;
      }
      .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
        box-shadow: none;
      }

      .p-tabview .p-tabview-nav .p-tabview-ink-bar {
        background-color: $primary-color;
      }

      .card-footer-auth-screen {
        position: relative;
        top: 250px;
        .forgot-password-container {
          display: flex;
          justify-content: center;
          margin-top: 15px;
          padding: 0px 0px 20px 0px;

          .forgot-password-text,
          .support {
            color: $primary-color;
            cursor: pointer;
          }

          .forgot-password-text:hover,
          .support:hover {
            color: $primary-color;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $device-medium) {
  .auth-screen {
    .auth-screen-container {
      .auth-screen-tabs {
        .auth-tabs {
          width: $field-width-100;
        }
      }
    }
  }
}

@media screen and (max-width: $device-small) {
  .auth-screen {
    .auth-screen-container {
      .auth-screen-logo {
        display: none;
      }
      .auth-screen-tabs {
        width: 97%;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 20px;
        min-height: auto;
        position: relative;
        left: unset;

        .auth-tabs {
          width: $field-width-100;
        }
      }
    }
  }
}
