@import 'theme.scss';

.reset-dialog {
  width: 430px;
  position: absolute;

  .p-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: $focus-shadow-color;
  }

  .reset-otp-form {
    padding: 1rem 0px;

    .selected-buttons {
      margin-bottom: 30px;

      .select-type {
        margin-bottom: 1.5rem;
      }

      .p-disabled {
        background-color: $light-grey-on-hover;
      }

      .select-button-content {
        display: flex;
        justify-content: center;
        align-items: center;

        .pi-phone {
          margin-right: 8px;
        }

        .pi-envelope {
          margin-right: 8px;
        }
      }

      .p-selectbutton .p-button {
        height: 40px;
        width: 50%;
        justify-content: center;
      }

      .p-selectbutton .p-button.p-highlight {
        background-color: $primary-color;
        border-color: $primary-color;
        height: 40px;
      }

      .p-button:focus {
        box-shadow: none;
      }

      .p-button:not(button):not(a):not(.p-disabled):active {
        background: $white;
        border: 1px solid $white-smoke;
      }
    }

    .p-fluid {
      .field {
        margin-bottom: $form-field-margin;

        .p-inputtext {
          height: 40px;
        }

        .p-inputtext:hover {
          border-color: $primary-color;
        }

        .p-inputgroup-addon {
          height: 40px;
          padding: 0px;
          width: 45%;
        }

        .p-inputtext:enabled:focus + label,
        .p-filled + label,
        .p-inputwrapper-focus + label,
        .p-inputwrapper-filled + label,
        .p-inputtext:enabled:focus + label,
        .p-filled + label {
          margin-left: 8px;
          margin-top: -12px;
        }

        .p-inputtext:enabled:focus,
        .p-dropdown:not(.p-disabled).p-focus {
          box-shadow: $focus-shadow-color;
          border-color: $primary-color;
        }
        .p-dropdown:hover {
          border-color: $primary-color;
        }

        .phone-input:disabled,
        .p-float-label {
          cursor: not-allowed;
        }

        .required-star {
          color: $danger-red-color;
          font-weight: $font-medium;
          font-size: $x-large;
          margin: 0px 3px;
        }

        label {
          left: $form-label;
          margin-left: 5px;
        }

        .label-text {
          margin-top: -12px;
        }

        .p-float-label > label {
          color: $grey-font-color-on-hover;
        }

        .p-invalid + .form-fields:enabled:hover,
        .p-invalid-hover,
        .p-invalid .p-inputtext:hover,
        .p-invalid .p-inputtext:focus,
        .p-invalid:hover {
          border-color: $danger-red-color !important;
        }
        .p-invalid + label,
        .p-float-label > label.p-error + label {
          color: $danger-red-color;
        }

        .p-error {
          margin-left: $float-label-margin-left;
          color: $danger-red-color;
        }

        .error-message {
          font-size: $small;
          margin-left: $form-error-message-margin;

          .mismatch-password-error {
            margin-top: 0px;
            margin-left: 4px;
            font-size: $x-small;
            color: $danger-red-color;
          }
        }
        .change-phone-number {
          display: flex;
          justify-content: flex-end;
          margin-top: 5px;
          .change-phone-number-text {
            cursor: pointer;
            color: $primary-color;
            &:hover {
              text-decoration: underline;
            }
          }
        }

        .email-error {
          font-size: $x-small;
          margin-top: 4px;
          color: $danger-red-color;
        }

        .form-fields:focus {
          box-shadow: $focus-shadow-color;
          border-color: $primary-color;
        }

        .p-input-icon-right > i:last-of-type {
          z-index: 1;
        }

        .password-input {
          border-top-left-radius: 1px;
          border-bottom-left-radius: 1px;
        }

        .p-dropdown {
          border-radius: 0px;
          border-bottom-left-radius: 6px;
          border-top-left-radius: 6px;
          height: 40px;
        }

        .p-dropdown .p-dropdown-trigger {
          width: 14px;
          margin-right: 8px;
        }

        .dropdown-selector {
          margin-top: -4px;
        }
      }

      .email-field,
      .phone-field {
        .p-inputtext.phone-input:enabled:focus + label,
        .p-filled.phone-input + label,
        .p-inputwrapper-focus.phone-input + label,
        .p-inputwrapper-filled.phone-input + label,
        .p-inputtext:enabled:focus + label,
        .p-filled + label {
          margin-left: -110px;
          margin-top: -12px;
        }
        .change-email {
          display: flex;
          justify-content: flex-end;
          margin-top: 5px;
          .change-email-text {
            cursor: pointer;
            color: $primary-color;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .p-inputtext.password-input:enabled:focus + label,
      .p-filled.password-input + label,
      .p-inputwrapper-focus.password-input + label,
      .p-inputwrapper-filled.password-input + label,
      .p-inputtext:enabled:focus + label,
      .p-filled + label {
        margin-left: -110px;
        margin-top: -12px;
      }

      .otp-success-div {
        display: flex;
        justify-content: space-between;
        margin-top: -45px;
        margin-bottom: 10px;

        .otp-success-text {
          color: $success-background;
        }

        .otp-resend-text {
          color: $primary-color;
          cursor: pointer;
          text-decoration: underline;
        }

        .otp-timer-text {
          color: $primary-color;
        }
      }

      .reset-link-text {
        color: $success-background;
        margin-top: -25px;
        font-weight: 600;
        margin-bottom: 15px;
      }
    }

    .dropdown-selector {
      display: flex;
      width: 200px;

      .flag-icon {
        margin: 0px 5px 0px 0px;
        width: 28px;
        height: 20px;
        border: 1px solid $grey-primary-color;
      }
    }

    .login-with-text {
      margin: 0px 0px 5px 9px;
      font-size: $x-small;
    }

    .p-button:focus {
      box-shadow: none;
    }

    .login-with-otp-button {
      background-color: $primary-color;
      border-color: $primary-color;
      height: 40px;
    }

    .p-button:enabled:hover {
      background-color: $primary-color;
      border-color: $primary-color;
    }
  }
}

@media screen and (max-width: $device-medium) {
  .reset-dialog .reset-otp-form {
    .field {
      .p-inputgroup-addon {
        width: 35%;
      }
    }
  }
}

@media screen and (max-width: $device-small) {
  .reset-dialog {
    width: 360px;
    top: 70px;

    .reset-otp-form {
      .dropdown-selector {
        display: flex;
        width: 200px;

        .flag-icon {
          margin: 3px 5px 0px 0px;
          width: 18px;
          height: 15px;
          border: 1px solid $grey-primary-color;
        }
      }

      .p-dialog .p-dialog-content {
        padding: 0px;
      }

      .email-field,
      .phone-field {
        .p-inputtext.phone-input:enabled:focus + label,
        .p-filled.phone-input + label,
        .p-inputwrapper-focus.phone-input + label,
        .p-inputwrapper-filled.phone-input + label,
        .p-inputtext:enabled:focus + label,
        .p-filled + label {
          margin-left: -85px;
          margin-top: -12px;
        }
      }

      .p-inputtext.password-input:enabled:focus + label,
      .p-filled.password-input + label,
      .p-inputwrapper-focus.password-input + label,
      .p-inputwrapper-filled.password-input + label,
      .p-inputtext:enabled:focus + label,
      .p-filled + label {
        margin-left: -85px;
        margin-top: -12px;
      }

      .field {
        .p-inputgroup-addon {
          width: 55%;
        }

        .p-icon {
          margin-bottom: 0px;
        }
      }

      .otp-success-div {
        margin-bottom: 20px;
      }
    }
  }
}
