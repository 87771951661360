@import 'theme.scss';

.weekly-wise-reports-container {
  .weekly-wise-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 4px;
    gap: 6px;
    width: 100%;

    .dropdown-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;

      .p-dropdown {
        min-width: 180px;
      }
      .p-dropdown:not(.p-disabled).p-focus {
        border: 1px solid $primary-color;
        box-shadow: 0 0 0 0.2rem rgba(80, 179, 246, 0.2509803922);
      }

      .p-dropdown:not(.p-disabled):hover {
        border: 1px solid $primary-color;
      }
    }
  }

  .dropdown-container {
    margin: 15px 0px;

    .p-dropdown {
      min-width: 180px;
    }
    .p-dropdown:not(.p-disabled).p-focus {
      border: 1px solid $primary-color;
      box-shadow: 0 0 0 0.2rem rgba(80, 179, 246, 0.2509803922);
    }

    .p-dropdown:not(.p-disabled):hover {
      border: 1px solid $primary-color;
    }
  }
  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: $white;
    border-color: $primary-color;
    color: $primary-color;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-tabview .p-tabview-nav .p-tabview-ink-bar {
    background-color: $primary-color;
  }

  .p-tabview-nav-link {
    border-bottom: solid #dee2e6 !important;
  }
  .long-column,
  .short-column,
  .total-column,
  .percent-long-column,
  .percent-short-column,
  .net-column,
  .spreading-column {
    width: 14%;
  }
}
