@import 'theme.scss';

.account-settings-container .account-settings-card {
  border-radius: $form-container-border-radius;
  padding: $form-container-padding;
  box-shadow: $form-container-box-shadow;

  .error-message {
    margin-left: $form-error-message-margin;
  }

  .account-settings-label {
    color: $grey-font-color-on-hover;
  }

  .p-dropdown.p-invalid.p-component {
    border-color: $danger-red-color;
  }

  .p-invalid + .p-dropdown:hover .p-invalid-hover {
    border-color: $danger-red-color;
    border: 1px solid;
  }

  .p-dropdown:not(.p-disabled).p-focus {
    border-color: $primary-color;
  }

  .p-invalid + .profile-information-fields:enabled:hover,
  .p-invalid-hover {
    border-color: $danger-red-color !important;
  }

  .p-dropdown:not(.p-disabled):hover {
    border-color: $primary-color;
  }

  .p-dropdown:not(.p-disabled).p-focus {
    box-shadow: $focus-shadow-color;
  }

  .date-time-wrapper {
    display: flex;
    gap: $action-header-gap;
    margin-bottom: $form-field-margin;
    width: $field-width-60;

    .date-format {
      width: 60%;

      .p-error {
        margin-top: -22px;
      }

      .p-dropdown {
        width: 100%;
      }
    }

    .select-button-container {
      width: 40%;

      .p-selectbutton {
        width: 100%;

        .p-button {
          width: 50%;
        }

        .p-button.p-highlight {
          background: $primary-color;
          border-color: $primary-color;
          color: $white;
        }
      }

      .p-button:focus {
        box-shadow: $focus-shadow-color;
      }
    }
  }

  .device-type-container {
    width: 60%;
    margin-bottom: $form-field-margin;
    position: relative;

    .device-type-label {
      position: absolute;
      color: $grey-font-color-on-hover;
      margin-top: -26px;
      margin-left: 16px;
      font-weight: $font-normal;
      font-size: $x-small;
    }

    .p-selectbutton {
      width: 100%;

      .p-button {
        width: 50%;
      }

      .p-button.p-highlight {
        background: $primary-color;
        border-color: $primary-color;
        color: $white;
      }
    }

    .p-button:focus {
      box-shadow: $focus-shadow-color;
    }
  }

  .profile-information-fields {
    width: $field-width-100;
    border-color: $light-grey-border;
  }

  .field {
    margin-bottom: $form-field-margin;
    width: $field-width-60;
  }

  .p-float-label label {
    position: absolute;
    pointer-events: none;
    margin-top: $float-label-margin-top;
    transition-property: all;
    transition-timing-function: ease;
    margin-left: $float-label-margin-left;
    font-weight: $font-normal;
  }

  .required-field {
    color: $danger-red-color;
    font-size: $x-large;
    margin-left: $form-error-message-margin;
  }

  .notification-checkbox-wrapper {
    margin-left: 16px;
    margin-top: $float-label-margin-top;
    display: flex;
    flex-direction: column;
    color: $grey-font-color-on-hover;
    gap: $action-header-gap;
    margin-bottom: 12px;

    div {
      display: flex;
      align-items: center;

      label {
        margin-left: 6px;
        cursor: pointer;
      }

      .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
        border-color: $primary-color;
      }

      .p-checkbox .p-checkbox-box.p-highlight {
        border-color: $primary-color;
        background: $primary-color;
      }

      .p-checkbox .p-checkbox-box.p-highlight:hover {
        border-color: $primary-color;
        background: $primary-color;
      }

      .p-checkbox .p-checkbox-box:hover {
        border-color: $primary-color;
      }

      .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
        border-color: $primary-color;
        box-shadow: $focus-shadow-color;
      }
    }
  }

  .button-container {
    display: flex;
    justify-content: flex-end;

    .p-button {
      padding: $button-label-padding;
      min-width: $button-min-width;
      background-color: $primary-color;
      border: 1px solid $primary-color;
    }

    .p-button:hover {
      background-color: $on-primary-hover;
      border: 1px solid $on-primary-hover;
    }

    .p-button:focus {
      box-shadow: $focus-shadow-color;
    }
  }
}

@media screen and (max-width: $device-small) {
  .account-settings-container .account-settings-card {
    .date-time-wrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: $form-field-margin;
      width: $field-width-100;
      gap: 0;

      .date-format {
        width: 100%;
        margin-bottom: $form-field-margin;
      }

      .select-button-container {
        width: 100%;
      }
    }

    .field {
      margin-bottom: $form-field-margin;
      width: $field-width-100;
    }

    .profile-information-fields {
      width: $field-width-100;
      border-color: $light-grey-border;
    }

    .device-type-container {
      width: 100%;
    }
  }
}
