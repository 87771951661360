@import 'theme.scss';

.coffee-quotes-data {
  margin-bottom: 50px;
  @mixin coffee-quotes-button-css {
    .p-button.p-button-icon-only {
      width: auto;
    }
    .p-button.p-button-danger.p-button-text:enabled:active {
      background: $white;
      border-color: none;
    }
    .p-button.p-button-danger.p-button-text:enabled:hover {
      background: $white;
    }
    .p-button.p-button-danger:enabled:focus {
      box-shadow:
        0 0 0 2px $white,
        0 0 0 4px $white,
        0 1px 2px 0 $white;
    }
    .p-button.p-button-danger {
      border: none;
    }
    .p-button.p-button-secondary.p-button-text:enabled:active {
      background: $white;
      border-color: none;
    }
    .p-button.p-button-secondary.p-button-text:enabled:hover {
      background: $white;
    }
    .p-button.p-button-secondary:enabled:focus {
      box-shadow:
        0 0 0 2px $white,
        0 0 0 4px $white,
        0 1px 2px 0 $white;
    }
    .p-button.p-button-secondary {
      border: none;
    }
    .p-button:focus {
      box-shadow: $focus-shadow-color;
    }
  }
  .coffee-quotes-input-container {
    display: flex;
    align-items: center;
    gap: 10px;
    .p-inputtext {
      padding: 0.5rem 0.75rem;
    }

    .add-coffee-quotes {
      width: $field-width-40;

      &:hover {
        border-color: $primary-color;
      }
      &:focus {
        border-color: $primary-color;
        box-shadow: 0 0 0 0.2rem $box-shadow-primary-color;
      }
    }
  }
  .coffee-quotes-button-container {
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    .coffee-quotes-buttons {
      display: flex;
      gap: 20px;
      margin-top: 14px;
    }
  }

  .error-message {
    margin-left: $form-error-message-margin;
    color: $danger-red-color;
    font-size: $small;
    margin-bottom: 5px;
  }

  .coffee-quotes-number {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
    justify-items: center;
    justify-content: center;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    margin: 10px 0px;
    padding: 10px 40px;
    @include coffee-quotes-button-css;
    .coffee-quote-item-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 3rem;
      gap: 3px;
    }
  }
  .coffee-quotes-email {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-items: start;
    justify-content: start;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    margin: 10px 0px;
    padding: 10px 40px;

    @include coffee-quotes-button-css;

    .p-button.p-button-danger:enabled:focus {
      box-shadow:
        0 0 0 2px $white,
        0 0 0 4px #dee2e6,
        0 1px 2px 0 $white;
    }

    .coffee-quote-item-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 5px 0px;
      .coffee-quote-item {
        background-color: #f8f9fa;
        color: #495057;
        padding: 14px 20px 13px 20px;
        width: 88%;
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
      }
      .coffee-quotes-delete-button {
        display: flex;
        justify-content: center;
        background-color: $white;
        border-radius: 50%;
        width: 12%;
        margin-left: -25px;
        border: 1px solid #dee2e6;

        .delete-icon {
          font-size: 20px;
        }
        .p-button.p-button-icon-only.p-button-rounded {
          margin-left: 0px;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .add-coffee-quote-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 10px;
  }

  .add-coffee-quote-input {
    flex: 1;
    margin-right: 10px;
  }

  .edit {
    width: 100%;
  }
  .coffee-quotes-edit-container {
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-width: 1601px) {
  .coffee-quotes-data {
    .coffee-quotes-email {
      grid-template-columns: 1fr 1fr 1fr;
      padding: 10px 20px;
    }
  }
}
@media (max-width: 1301px) {
  .coffee-quotes-data {
    .coffee-quotes-email {
      grid-template-columns: 1fr 1fr;
      padding: 10px 20px;
    }
  }
}
@media (max-width: 1025px) {
  .coffee-quotes-data {
    .coffee-quotes-email {
      grid-template-columns: 1fr 1fr;
      padding: 10px 20px;
    }
  }
}

@media (max-width: 768px) {
  .coffee-quotes-data {
    .coffee-quotes-input-container {
      .add-coffee-quotes {
        width: $field-width-100;
      }
    }

    .coffee-quotes-number {
      padding: 10px 20px;
    }
    .coffee-quotes-email {
      grid-template-columns: 1fr 1fr;
      padding: 10px 20px;
      .coffee-quote-item-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 5px 0px;
        .coffee-quote-item {
          padding: 14px;
          width: 84%;
        }
        .coffee-quotes-delete-button {
          padding: 1px 4px;
          margin-left: -22px;
          width: 16%;
          .delete-icon {
            font-size: 16px;
          }
          .p-button.p-button-icon-only.p-button-rounded {
            margin-left: 0px !important;
          }
        }
      }
    }

    .edit-container {
      margin-right: 2%;
    }
    .coffee-quotes-edit-container {
      display: flex;
      justify-content: flex-end;
    }
    .p-button.p-button-icon-only.p-button-rounded {
      margin-left: 0px;
    }
  }
}
@media (max-width: 555px) {
  .coffee-quotes-data {
    .coffee-quotes-number {
      justify-items: center;
      justify-content: center;
      padding: 10px 20px;
    }
    .coffee-quotes-email {
      grid-template-columns: 1fr;
      justify-items: center;
      justify-content: center;
      padding: 10px 20px;
      .coffee-quote-item-container {
        .coffee-quote-item {
          padding: 16px;
          width: 84%;
        }
        .coffee-quotes-delete-button {
          padding: 2px;
          width: 16%;
          .delete-icon {
            font-size: 16px;
          }
        }
      }
    }
  }
}
