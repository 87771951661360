@import 'theme.scss';

.add-mobile-menu-component {
  .menu-type-title {
    font-size: 18px;
    font-weight: 600;
    color: #6c757d;
  }
  .add-mobile-menu-form {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 25px;
    .mandate {
      color: #ef4444;
    }
    .validation-error {
      input {
        border: 1px solid #ef4444 !important;
        box-shadow: none !important;
      }
      label {
        color: #ef4444;
      }
    }
    .error-message-labeling {
      color: #ef4444;
      font-size: 13px;
      left: 5px;
      position: relative;
      margin-top: 3px;
    }

    .radio-button-wrapper {
      display: flex;
      flex-direction: column;
      border: 1px solid lightgray;
      padding: 10px;
      gap: 10px;
      width: 60%;
      border-radius: 5px;
      user-select: none;
      .active-radio-button {
        border: 1px solid $primary-color !important;
        background-color: #f0f9ff !important;
      }
      .each-radio-button {
        display: flex;
        align-items: center;
        padding: 10px;
        border: 1px solid $white;
        width: 100%;
        border-radius: 5px;
        cursor: pointer;

        &:hover {
          background-color: rgb(245, 245, 245);
        }

        input {
          height: 18px;
          width: 18px;
          margin: 0px 6px 0px 0px;
        }
      }
      .multiselect-country-dropdown {
        width: 60%;
        .p-multiselect {
          width: 100%;
        }
        .p-multiselect:not(.p-disabled):hover {
          border: 1px solid $primary-color;
        }
      }
    }
    .file-upload-wrapper {
      width: 60%;
      .choose-menu-logo-label {
        margin-left: 7px;
        bottom: 5px;
        position: relative;
      }
      .p-fileupload-choose {
        background-color: $primary-color;
        border: none;
      }
      .p-fileupload-content {
        padding: 15px;
        .image-file {
          display: flex;
          justify-content: center;
          .add-image-container {
            img {
              height: 200px;
            }
          }
          .cancel-action {
            position: absolute;
            top: 10px;
            right: 10px;
          }
        }
      }
    }

    .save-button-wrapper {
      display: flex;
      justify-content: flex-end;
    }

    .p-inputtext:enabled {
      width: 60%;
      &:hover {
        border: 1px solid $primary-color;
        box-shadow: 0 0 0 0.2rem $box-shadow-primary-color;
      }
      &:focus {
        box-shadow: 0 0 0 0.2rem $box-shadow-primary-color;
      }
    }
    .p-inputtext:enabled:focus,
    .p-float-label label {
      top: 50% !important;
    }
    .p-float-label input:focus ~ label,
    .p-float-label input.p-filled ~ label,
    .p-float-label .p-inputwrapper-focus ~ label,
    .p-float-label .p-inputwrapper-filled ~ label {
      top: -0.5rem !important;
      left: 6px;
    }
  }
}
