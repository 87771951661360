@import 'theme.scss';

.reconciled-report-container {
  .reconciled-report-filter {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .multiselect-field {
      width: 250px;

      &:hover {
        border-color: $primary-color;
      }
    }

    .filter-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 10px;
      order: 2;
      grid-column: 1;

      .drop-down-wrapper {
        display: flex;

        .p-inputtext {
          border-color: $primary-color;
          border-bottom-right-radius: 0px;
          border-top-right-radius: 0px;
          border-right: none;
          width: 200px;
          height: 45px;
        }
        .p-inputtext:enabled:focus {
          box-shadow: none;
        }

        .field-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 45px;
          border: 1px solid $primary-color;
          border-left: none;
          border-bottom-right-radius: 4px;
          border-top-right-radius: 4px;
          color: $primary-color;
          padding: 0px 15px;
          cursor: pointer;
        }
      }
    }
  }
  .reconciled-report-table-wrapper {
    width: calc(100vw - 320px);
    .p-datatable .p-datatable-thead > tr > th {
      padding: 0.75rem 0.6rem;
    }

    .p-datatable .p-datatable-tbody > tr > td {
      padding: 0.75rem 0.6rem;
    }
  }
  .reconciled-Report-panel-style {
    .p-multiselect-items-wrapper {
      max-height: 350px !important;
    }

    .p-multiselect-item.p-highlight {
      color: $primary-color !important;
      background-color: #f8f9fa !important;
      margin: 2px 0px;
    }

    .p-multiselect-item.p-highlight:focus {
      box-shadow: none !important;
    }

    .p-multiselect-items {
      padding: 0px !important;
    }
  }

  .setting-button-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
  }
  .invoice-number-clickable {
    width: 15%;
    cursor: pointer;

    &:hover {
      color: $primary-color;
      text-decoration: underline;
      font-weight: bold;
      cursor: pointer;
    }
  }
}

.setting-dialog-modal {
  width: 50vw;

  .columns-container {
    gap: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 4px;

    .column {
      display: flex;
      gap: 8px;
      align-items: center;
    }

    .column-name {
      cursor: pointer;
    }

    .p-checkbox .p-checkbox-box.p-highlight {
      border-color: $primary-color;
      background: $primary-color;
    }

    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
      border-color: $primary-color;
    }

    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
      border-color: $primary-color;
      box-shadow: $focus-shadow-color;
    }

    .p-checkbox,
    .p-focus {
      outline: 0 none;
      outline-offset: 0;
    }

    .p-focus {
      border-color: $primary-color;
      box-shadow: $focus-shadow-color;
    }
  }

  .info-message {
    padding: 0;

    .p-message {
      .p-message-wrapper {
        padding: 0.75rem 1.75rem;
      }

      &.p-message-info {
        background-color: #c8e9fa75;
        color: $primary-color;
        border: solid #50b3f6;
        border-width: 0 0 0 6px;

        .p-message-icon {
          color: #50b3f6;
        }
      }
    }
  }

  .setting-button-container {
    display: flex;
    justify-content: flex-end;

    .submit-button-wrapper {
      &.disable-text-present {
        margin: 0;
      }

      margin-top: 80px;
    }
  }
}

.reconciled-Report-panel-style {
  .p-multiselect-items-wrapper {
    max-height: 350px !important;
  }

  .p-multiselect-item.p-highlight {
    color: $primary-color !important;
    background-color: #f8f9fa !important;
    margin: 2px 0px;
  }

  .p-multiselect-item.p-highlight:focus {
    box-shadow: none !important;
  }

  .p-multiselect-items {
    padding: 0px !important;
  }
}

@media (max-width: $device-small) {
  .reconciled-report-container {
    .reconciled-report-table-wrapper {
      .reconciled-report-table {
        width: 100vw;
      }
    }
  }
}
