.edit-holiday-component-wrapper {
  border: 1px solid lightgray;
  border-radius: 8px;
  padding: 10px;
  margin-top: 10px;
  .edit-holiday-form {
    padding: 15px 7px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 10px;
    .mandate {
      color: #ef4444;
    }

    .edit-holiday-form-footer {
      display: flex;
      justify-content: flex-end;
    }

    .p-inputtext,
    .p-calendar,
    .p-dropdown {
      width: 35%;
    }
    .p-inputtext:enabled:focus,
    .p-float-label label {
      top: 50% !important;
    }
    .p-float-label input:focus ~ label,
    .p-float-label input.p-filled ~ label,
    .p-float-label .p-inputwrapper-focus ~ label,
    .p-float-label .p-inputwrapper-filled ~ label {
      top: -0.5rem !important;
      left: 6px;
    }
  }
}
