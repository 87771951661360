@import 'theme.scss';

.add-roles-form {
  border-radius: $modal-border;
  margin-bottom: $field-margin-bottom-size;
  padding: $form-container-padding;
  box-shadow: $form-container-box-shadow;

  .required-field {
    color: $danger-red-color;
    font-weight: $font-medium;
    font-size: $x-large;
    margin: 3px;
  }

  .add-roles-field {
    margin-bottom: $form-field-margin;

    .error-message-roles {
      color: $danger-red-color;
    }

    .p-error {
      margin-left: $form-error-message-margin;
    }

    label {
      margin-top: $float-label-margin-top;
      color: $grey-font-color-on-hover;
    }

    input:focus {
      box-shadow: $focus-shadow-color;
      border-color: $primary-color;
    }

    .p-inputtext:enabled:hover {
      border-color: $primary-color;
    }

    .p-inputtext.p-invalid.p-component {
      border-color: $danger-red-color !important;
    }
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
  }

  .p-inputtext {
    width: $field-width-60;
  }

  .p-component-overlay-leave {
    animation: $modal-overlay-animation;
  }

  input:focus {
    box-shadow: $focus-shadow-color;
  }
  .role-toggle-switches {
    display: flex;
    gap: 25px;
    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
      background-color: $primary-color;
    }
    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:hover {
      background-color: $primary-color;
    }
    .p-inputswitch.p-focus .p-inputswitch-slider {
      box-shadow: 0 0 0 0.2rem $box-shadow-primary-color;
    }
  }
}

@media screen and (max-width: $device-small) {
  .add-roles-form {
    .p-inputtext {
      width: $field-width-100;
    }
  }
}
