@import 'theme.scss';

.global-differential-user-preview {
  width: 95vw;
  .crop_year {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 15px;
    border-radius: 4px;
    font-weight: bold;
    background-color: $primary-color;
    margin: 0px 15px 10px 15px;
    color: $white;
    .crop_year_title {
      display: flex;
      align-items: center;
    }
  }
  .differential_table {
    padding: 0px 15px;
    margin-bottom: 30px;
    .table_data {
      margin: 15px 0px;
    }
    .year {
      display: flex;
      justify-content: space-between;
      background-color: $primary-color;
      color: $white;
      font-weight: $font-semibold;
      border-radius: 4px;
      border-right: 1px solid #dee2e6;
      border-left: 1px solid #dee2e6;
      padding: 5px 14px;
      margin-bottom: 5px;
      align-items: center;
      .flag_and_cropyear {
        display: flex;
        gap: 20px;
        align-items: center;
        .crop_year_flag {
          font-size: $x-large;
          display: flex;
          align-items: center;
          gap: 3px;
          img {
            width: 40px;
            border-radius: 3px;
            border: 1px solid rgba(0, 17, 51, 0.15);
            height: 28px;
            object-fit: cover;
            margin-right: 3px;
          }
        }
      }
      .updated_on {
        margin-left: -110px;
      }
    }
    .each_table {
      width: 100%;
      margin-bottom: 20px;
      border-spacing: 0px;
      border-collapse: collapse;
      .differential_table_header {
        .quality {
          width: 23vw;
        }
        .remarks {
          width: 23vw;
        }
        tr,
        th,
        td {
          border: 1px solid #dee2e6;
          text-align: center;
          color: black;
          padding: 6px 0px;
          background-color: $white;
        }
        tr:first-child th {
          background-color: #e9f1f9;
          border: 2px solid $primary-color;
        }
        tr {
          background-color: $white;
          &:hover {
            background-color: rgba(0, 0, 0, 0.075) !important;
          }
        }
        .quality-th-cell {
          width: 23vw;
        }
        .screen-th-cell {
          width: 9vw;
        }
        .differetial-between {
          width: 20vw;
        }
        .terminal {
          width: 9vw;
        }
        .td {
          width: 9vw;
        }
        .remarks {
          width: 32vw;
        }
      }
      .differential_table_body {
        border: 1px solid #dee2e6;
        tr,
        th,
        td {
          border: 1px solid #dee2e6;
          text-align: center;
          padding: 6px 0px;
        }
        tr {
          background-color: $white;
          &:hover {
            background-color: rgba(0, 0, 0, 0.075) !important;
          }
        }
        .range_starts {
          width: 10vw;
        }
        .range_ends {
          width: 10vw;
        }
        .fob-cell {
          background-color: $white;
        }
      }
    }
  }
  .no_data_found {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: $xxx-large;
    padding: 45px;
    gap: 10px;
  }
  .p-dialog-header {
    padding: 0.75rem;
    border-bottom: 1px solid $light-grey;
  }
  .p-dialog-footer {
    padding: 1rem 0.5rem !important;
    border-top: 1px solid $light-grey;
  }
  .global-differential-user-preview-footer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
}
