@import 'theme.scss';

.header-container {
  background-color: $primary-color;
  .auth-screen-header {
    .auth-header-logo {
      align-items: center;
      padding: 12px;
      position: relative;
      z-index: 10;

      .auth-screen-logo {
        width: 14rem;
        height: 3.5rem;
        padding-bottom: 15px;
      }
    }
  }
  .dashboard-action-container {
    display: flex;
    justify-content: flex-end;
    top: 0;
    left: 0;
    height: 65px;
    width: 100%;
    padding-right: 10px;
    box-sizing: border-box;
    background-repeat: no-repeat;

    .action-div {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  .logout-container {
    .logout-button {
      background-color: transparent;
      border: none;
    }

    .pi-power-off {
      font-size: 1.5rem;
    }

    .p-button:focus {
      box-shadow: none;
    }
    .p-button.p-button-icon-only {
      width: 4rem;
    }
  }

  .p-menubar {
    padding: 0.1rem;
    background: $primary-color;
    border: none;
    border-radius: 0;
    box-shadow:
      2px 0 4px -1px rgba(0, 0, 0, 0.2),
      4px 0 5px 0 rgba(0, 0, 0, 0.14),
      1px 0 10px 0 rgba(0, 0, 0, 0.12);
  }

  .dashboard-screen-logo {
    display: none;
  }

  .dashboard-header {
    .sidebar-menu-button {
      display: none;
    }
  }
  .coffeeWebLogoModal {
    display: inline-block;
    align-items: flex-start;
    width: 180px;
    margin: auto;
    padding: 16px;
  }
  .coffeeWebDevLogo {
    display: inline-block;
    align-items: flex-start;
    width: 150px;
    margin: auto;
    padding: 0px;
  }
}

@media (max-width: $device-medium) {
  .header-container {
    .p-menubar .p-menubar-button {
      display: none !important;
    }
  }
}

@media (max-width: $device-small) {
  .header-container {
    .p-menubar .p-menubar-button {
      display: none;
    }
    .auth-screen-header {
      .auth-header-logo {
        .auth-screen-logo {
          padding-bottom: 0px;
        }
      }
    }
  }
  .dashboard-header {
    display: flex;
    margin-left: 12px;
    .sidebar-menu-button {
      display: block !important;
    }
    .pi-align-justify {
      font-size: $x-large;
    }

    .dashboard-screen-logo {
      width: 200px;
      height: 40px;
      display: block;
      margin-left: 5px;
    }

    .p-button.p-button-icon-only {
      width: 2rem;
      height: 2rem;
      padding: 5px 8px;
      background-color: $primary-color;
      color: $white;
      border: none;
      border-radius: 4px;
    }
  }
}
