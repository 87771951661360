@import 'theme.scss';

.subscription-payment-container {
  .subscription-payment-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .subscription-search-field {
      margin-bottom: 8px;
      .search-field:hover {
        border: 1px solid $primary-color;
      }
    }
  }
  .subscription-payment-table-container {
    .subscription-payment-table {
      width: 100%;

      .payment-name {
        width: 50%;
      }
      .is-active {
        width: 45%;
      }
      .action-column {
        width: 5%;
      }

      .toggle-switch-menu .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
        background-color: $primary-color;
      }
      .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
        background-color: $primary-color;

        &:hover {
          background-color: $on-primary-hover;
        }
      }
      .p-inputswitch.p-focus .p-inputswitch-slider {
        border-color: $primary-color;
        box-shadow: $focus-shadow-color;
      }
    }
    .p-datatable .p-datatable-thead > tr > th {
      padding: $table-row-padding;
    }

    .p-datatable .p-datatable-tbody > tr > td {
      padding: $table-row-padding;
    }
    .edit-button {
      background-color: $white;
      border: none;
      outline: none;
      color: $primary-color;
      padding: 0;

      &:hover {
        background: $white;
        color: $primary-color;
      }

      &:active {
        background: $white;
        color: $primary-color;
        border-color: transparent;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }
  .subscription-payment-add-button.disabled-button {
    cursor: not-allowed;
    opacity: 0.6;
  }

  .subscription-payment-add-button.disabled-button .custom-tooltip-btn {
    cursor: default;
  }
}

.custom-tooltip {
  max-width: 330px;
  height: auto;
  color: $white;
  padding: 20px 15px;
  border-radius: 5px;
  transform: translateX(-4%);
}

.custom-tooltip::before {
  border-top-color: #333;
}

.custom-tooltip[x-placement^='bottom'] .tooltip-arrow {
  top: 0;
}
