@import 'theme.scss';

.add-usda-report-container {
  border-radius: $form-container-border-radius;
  padding: $form-container-padding;
  box-shadow: $form-container-box-shadow;

  .p-dropdown-panel .p-dropdown-header .p-dropdown-filter:hover {
    border-color: $primary-color;
  }

  .field-container {
    width: $field-width-60;
    margin-bottom: $form-field-margin;

    .dropdown-field {
      width: $field-width-100;
    }

    .p-dropdown:not(.p-disabled).p-focus {
      box-shadow: $focus-shadow-color;
      border-color: $primary-color;
    }

    .p-dropdown:not(.p-disabled):hover {
      border-color: $primary-color;
    }

    .input-text,
    .input-text-area,
    .p-calendar {
      width: $field-width-100;

      & ~ .field-label {
        margin-top: -0.75rem;
      }

      &.mandatory-field {
        border-color: $danger-red-color;

        &:hover {
          border-color: $danger-red-color;
        }

        &:focus {
          border-color: $danger-red-color;
          box-shadow: $focus-shadow-danger;
        }
      }

      & ~ .mandatory-field-label {
        margin-top: -1.25rem;
        color: $danger-red-color;

        &.text-area,
        &.calendar {
          margin-top: -0.75rem;
        }
      }

      &:focus {
        box-shadow: $focus-shadow-color;
        border-color: $primary-color;

        & ~ .mandatory-field-label {
          margin-top: -0.75rem;
        }
      }

      &:hover {
        border-color: $primary-color;
      }

      .calendar-input-text {
        &:hover {
          border-color: $primary-color;
        }

        &:focus {
          box-shadow: $focus-shadow-color;
          border-color: $primary-color;
        }

        &.mandatory-field {
          border-color: $danger-red-color;

          &:focus {
            border-color: $danger-red-color;
            box-shadow: $focus-shadow-danger;
          }
        }
      }
    }

    .required-field {
      color: $danger-red-color;
    }

    .p-button {
      background-color: $primary-color;
      border-color: $primary-color;
    }

    .error-message {
      color: $danger-red-color;
      font-size: $x-small;
      padding-top: 2px;
      padding-left: 4px;
    }
  }

  .toggle-button-container {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    gap: 4px;
    border: 1px solid #ced4da;
    width: max-content;
    padding: 8px 16px;
    border-radius: 6px;

    &:hover {
      border: 1px solid $primary-color;
    }

    .input-switch-wrapper {
      display: flex;
      margin-left: 9px;

      .toggle-button-active {
        margin-left: 6px;
      }

      .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
        background: $primary-color;
      }
    }
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: $form-button-gap;
  }

  .required-field {
    color: $danger-red-color;
    font-weight: $font-medium;
    font-size: $x-large;
    margin: 3px;
  }
}

@media screen and (max-width: $device-small) {
  .add-usda-report-container {
    border-radius: $form-container-border-radius;
    padding: $form-container-padding;
    box-shadow: $form-container-box-shadow;

    .field-container {
      width: $field-width-100;
    }
  }
}
