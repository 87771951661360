@import 'theme.scss';

.edit-language {
  border-radius: $form-container-border-radius;
  padding: $form-container-padding;
  box-shadow: $form-container-box-shadow;

  .country-name {
    width: $field-width-60;
    margin-bottom: $form-field-margin;
    label {
      font-size: $x-small;
      margin-left: 9.5px;
      margin-bottom: -1px;
      color: $grey-font-color-on-hover;
    }
  }
  .language-name,
  .local-language-name {
    width: $field-width-60;
    margin-bottom: $form-field-margin;
  }

  .p-dropdown:not(.p-disabled).p-focus {
    box-shadow: $focus-shadow-color;
    border-color: $primary-color;
  }

  .p-dropdown:not(.p-disabled):hover,
  .p-inputtext:hover {
    border-color: $primary-color;
  }

  .p-invalid + .form-fields:enabled:hover,
  .p-invalid-hover {
    border-color: $danger-red-color !important;
  }

  .p-float-label label {
    position: absolute;
    pointer-events: none;
    margin-top: $float-label-margin-top;
    transition-property: all;
    transition-timing-function: ease;
    font-weight: $font-normal;
    margin-left: $float-label-margin-left;
  }
  .p-error {
    color: $danger-red-color;
  }
  .error-message {
    color: $danger-red-color;
    margin-left: $form-error-message-margin;
    font-size: $small;
  }

  label {
    left: $form-label;
    color: $grey-font-color-on-hover;
  }
  .field-required {
    color: $danger-red-color;
    font-weight: $font-medium;
    font-size: $x-large;
  }
  .button-container {
    display: flex;
    justify-content: flex-end;
    gap: $form-button-gap;
  }
  .p-inputtext:enabled:focus {
    box-shadow: $focus-shadow-color;
  }
}

@media screen and (max-width: $device-small) {
  .edit-language {
    .country-name,
    .language-name,
    .local-language-name {
      width: $field-width-100;
    }
  }
}
