@import 'theme.scss';

.login-form {
  margin-top: 30px;
  position: absolute;
  width: 85%;

  .invalid-credential-error {
    color: $danger-red-color;
    margin-bottom: 25px;
    margin-top: -15px;
  }

  .field {
    margin-bottom: $form-field-margin;

    .p-inputtext {
      height: 40px;
    }

    .p-inputgroup-addon {
      height: 40px;
      width: 20%;
    }

    .p-inputtext:enabled:focus {
      box-shadow: $focus-shadow-color;
      border-color: $primary-color;
    }

    .p-inputtext:enabled:hover {
      border-color: $primary-color;
    }

    .p-inputtext.p-invalid.p-component,
    .p-invalid .p-inputtext:hover {
      border-color: $danger-red-color;
    }

    .p-inputgroup .p-float-label:last-child input {
      border-top-left-radius: 1px;
      border-bottom-left-radius: 1px;
      width: $field-width-100;
      position: absolute;
    }

    .p-inputtext:enabled:focus + label,
    .p-filled + label,
    .p-inputwrapper-focus + label,
    .p-inputwrapper-filled + label {
      margin-left: -64px;
      margin-top: -12px;
    }

    .required-star {
      color: $danger-red-color;
      font-weight: $font-medium;
      font-size: $x-large;
    }

    .p-input-icon-right > i:last-of-type {
      z-index: 1;
    }

    .label-text {
      margin-top: -12px;
    }

    .password-label-text {
      margin-top: 8px;
    }

    .p-icon {
      margin-bottom: 10px;
    }

    .p-error {
      margin-left: $float-label-margin-left;
      color: $danger-red-color;
    }

    .p-invalid + .form-fields:enabled:hover,
    .p-invalid-hover,
    .p-invalid .p-inputtext:focus {
      border-color: $danger-red-color;
    }
  }

  .login-button {
    background-color: $primary-color;
    border-color: $primary-color;
    height: 38px;
    width: $field-width-100;
  }

  .login-button:hover {
    background-color: $primary-color;
    border-color: $primary-color;
  }

  .login-button:focus {
    box-shadow: none;
  }
}
