@import 'theme.scss';

.user-stats-wrapper {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 50px;

  .user-stats-contrainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    .pie-chart-user-detail {
      display: flex;
    }
    .total-users {
      border-radius: 5px;
      display: grid;
      margin-bottom: 20px;
    }
    .info {
      grid-template-columns: auto 1fr;
      gap: 20px;
      align-items: center;
    }
    .p-chart {
      height: 300px;
      display: flex;
      align-items: center;
    }
    .pie-chart {
      display: flex;
      justify-content: center;
      canvas {
        width: 200px !important;
        height: 200px !important;
      }
    }
    .sub-counts {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
      .total-count {
        .count {
          font-size: 60px;
          font-weight: 600;
        }
        .labeling {
          font-size: 20px;
          font-weight: 600;
          text-align: center;
        }
      }
    }
    .user-details {
      display: flex;
      justify-content: space-around;
      width: 100%;
      align-items: flex-end;
      .each-subscription {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        .count {
          font-size: 35px;
          font-weight: 600;
        }
        .labeling {
          font-size: 16px;
        }
      }
    }
  }
  .user-stats-actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 15px;
    gap: 15px;
    .reports-section {
      display: grid;
      grid-template-rows: auto 1fr auto;
    }
    .filter-option {
      display: grid;
      grid-template-rows: auto 1fr auto;
    }
  }
  .table-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;
    .subscription-dropdown {
      width: 180px;
    }
    .p-dropdown:not(.p-disabled).p-focus {
      box-shadow: 0 0 0 0.2rem #b3d9f3a8;
      border-color: $primary-color;
    }
    .p-dropdown:not(.p-disabled):hover {
      border-color: $primary-color;
    }
  }
  .cards {
    border-radius: 5px;
    box-shadow: 0px 0px 4px $light-grey-border;
    padding: 20px;
    display: grid;
    .count {
      font-size: 30px;
    }

    .icons {
      background-color: rgb(167, 227, 253);
      padding: 20px;
      border-radius: 50%;
      color: $white;
      font-size: 20px;
    }
  }
}
