@import 'theme.scss';

.edit-subscription-payment-type {
  border-radius: $form-container-border-radius;
  padding: $form-container-padding;
  box-shadow: $form-container-box-shadow;

  .p-dropdown {
    display: flex;
    align-items: center;
    width: $field-width-60;
    margin-bottom: $form-field-margin;

    &:hover {
      border-color: $primary-color;
    }

    &:focus {
      $focus-shadow-color: 0 0 0 0.2rem #50b3f640;
    }

    .p-dropdown-label.p-placeholder {
      opacity: initial;
    }
  }

  .p-dropdown:not(.p-disabled).p-focus {
    box-shadow: none;
  }
  .p-dropdown:not(.p-disabled).p-focus {
    box-shadow: 0 0 0 0.2rem $box-shadow-primary-color;
    border-color: $primary-color;
  }
  .p-dropdown:not(.p-disabled):hover {
    border-color: $primary-color;
  }

  .p-float-label label {
    margin-top: $float-label-margin-top;
    left: $form-label;
    margin-left: $float-label-margin-left;
    font-weight: $font-normal;
    color: $grey-font-color-on-hover;
  }

  label {
    left: $form-label;
  }

  .p-float-label {
    width: $field-width-100;
  }
  .star-required {
    color: $danger-red-color;
    font-size: $x-large;
    font-weight: $font-medium;
  }
  .edit-subscription-payment-container-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: $field-margin-bottom-size;
  }
}

@media screen and (max-width: $device-small) {
  .edit-subscription-payment-type {
    .p-dropdown {
      width: $field-width-100;
      margin-bottom: $form-field-margin;
    }
  }
}
