@import 'theme.scss';

.edit-payment-container {
  min-width: $field-width-100;
  border-radius: $modal-border;
  padding: $form-container-padding;
  box-shadow: $form-container-box-shadow;

  .payment-form-container {
    .edit-input-fields {
      width: $field-width-60;
      margin-top: $form-field-margin;

      label {
        left: 4px;
      }

      .error-message {
        color: $danger-red-color;
      }

      .p-float-label label {
        margin-top: $float-label-margin-top;
      }

      .p-error {
        margin-left: 4px;
      }

      .edit-payment-label {
        color: $grey-font-color-on-hover;
      }
    }

    .payment-input-field-wrapper {
      display: flex;
      justify-content: space-between;

      .toggle-button-container {
        .input-switch-wrapper {
          display: flex;
          margin-left: 9px;
          margin-bottom: 14px;
          .toggle-button-active {
            margin-left: 6px;
          }

          .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
            background: $primary-color;
          }
        }
      }
    }
  }

  .payment-logo-container {
    width: $field-width-60;
    img {
      width: $field-width-100;
      height: 100%;
    }

    .image-upload-container {
      background: $white;
      padding: 0.7rem;
      border: 1px solid $light-grey-border;
      color: $dark_grayish_blue;
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
      position: relative;
    }
    .image-file-container {
      display: flex;
      justify-content: center;
    }

    .image-wrapper {
      display: flex;
      justify-content: center;
      border-radius: 7px;
      width: 150px;
      height: 150px;
      padding: 5px;
    }
    .image-cancel-button-container {
      display: flex;
      justify-content: center;
      position: absolute;
      right: 18px;

      .p-button.p-button-icon-only.p-button-rounded {
        margin-right: -12px;
        margin-top: -9px;
        width: 34px;
        height: 34px;
      }
    }
    .p-fileupload .p-fileupload-buttonbar .p-button {
      margin-right: 0.5rem;
      color: $white;
      background: $primary-color;
      border: 1px solid $primary-color;
      padding: $button-label-padding;
    }
  }

  .country-pick-list-container {
    .country-name-label {
      margin-bottom: 4px;
    }
    .country-list-wrapper {
      display: flex;
      gap: 10px;
      align-items: center;
      .country-flag-container {
        width: 8%;
      }
    }
    .country-mapping-button {
      background-color: $primary-color;
      border: 1px solid $primary-color;
      &:hover {
        background-color: $primary-color;
        border: 1px solid $primary-color;
      }
      &:focus {
        box-shadow: $focus-shadow-color;
      }
    }
    .p-picklist .p-picklist-list .p-picklist-item.p-highlight {
      color: $primary-color;
      background-color: $box-shadow-primary-color;
    }
    .p-picklist .p-picklist-list .p-picklist-item:focus {
      box-shadow: none;
    }
    .p-picklist .p-picklist-list {
      height: 22rem;
    }
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    gap: $form-button-gap;
    margin-top: 14px;
  }

  .star {
    color: $danger-red-color;
    font-weight: $font-medium;
    font-size: $x-large;
    margin: 3px;
  }

  label {
    display: inline-block;
    margin-bottom: -1px;
    margin-left: 8px;
    margin-top: 1rem;
    font-size: $x-small;
  }

  .p-inputtext:enabled:hover {
    border-color: $primary-color;
  }

  .p-inputtext:enabled:focus {
    border-color: $primary-color;
    box-shadow: 0 0 0 0.2rem $box-shadow-primary-color;
  }

  .p-button.p-button-danger.p-button-text {
    color: $danger-red-color;
    background: $white;
    border: none;
  }

  .p-button.p-button-danger.p-button-text:enabled:hover {
    color: $danger-red-color;
    background: $white;
    border: none;
  }

  .p-fileupload .p-fileupload-buttonbar {
    padding-left: 8px;
  }

  .p-fileupload .p-fileupload-buttonbar .p-button {
    padding: $button-label-padding;
  }

  .p-inputtext.p-invalid.p-component {
    border-color: $danger-red-color;
  }

  .p-button:focus {
    box-shadow: $focus-shadow-color;
  }

  .p-float-label label {
    position: absolute;
    pointer-events: none;
    transition-property: all;
    transition-timing-function: ease;
    margin-left: $float-label-margin-left;
    font-weight: $font-normal;
    color: $grey-font-color-on-hover;
  }
}
@media screen and (max-width: $device-small) {
  .edit-payment-container {
    .payment-form-container {
      .payment-input-field-wrapper {
        display: block;
      }
      .edit-input-fields {
        width: $field-width-100;
      }
    }
    .payment-logo-container {
      width: $field-width-100;
    }
    .country-pick-list-container {
      .country-list-wrapper {
        gap: 36px;
      }
    }
  }
}
