@import 'theme.scss';

.subscription-config-tab-container {
  .tab-container {
    .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
      background: $white;
      border-color: $primary-color;
      color: $primary-color;
    }
    .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
      box-shadow: none;
    }
    .p-tabview .p-tabview-nav .p-tabview-ink-bar {
      background-color: $primary-color;
    }
  }
}
