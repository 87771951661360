@import 'theme.scss';
.missing-payment-report-dialog-container {
  width: 50vw;
  .p-dialog-header {
    padding: 20px 1.5rem !important;
    text-transform: uppercase;
  }
  .p-dialog-content {
    padding: 8px 1.5rem 2rem 1.5rem;
  }

  .missing-payment-report-form {
    margin-top: 20px;
    .required-field {
      color: $danger-red-color;
      font-weight: $font-medium;
      font-size: $x-large;
      margin: 3px;
    }

    .form-input {
      display: flex;
      justify-content: space-between;
    }
    .field {
      width: 48%;
      margin-bottom: $form-field-margin;
    }
    label {
      margin-top: $float-label-margin-top;
      left: $form-label;
      margin-left: $float-label-margin-left;
    }

    .email-and-phoneNo-label {
      margin-top: -8px;
      left: $form-label;
      margin-left: $float-label-margin-left;
    }

    .p-datepicker-trigger {
      background-color: $primary-color;
      border: 1px solid $primary-color;

      &:hover {
        border: 1px solid $primary-color;
        box-shadow: none;
      }
    }

    .missing-payment-dropdown {
      border-color: $primary-color;

      &:hover {
        border-color: $primary-color;
      }
    }

    .p-dropdown:not(.p-disabled).p-focus {
      box-shadow: $focus-shadow-color;
      border-color: $primary-color;
    }

    .p-button:focus {
      box-shadow: none;
    }
    .p-inputtext {
      border-color: $primary-color;
    }
    .p-inputtext:enabled:focus {
      box-shadow: $focus-shadow-color;
    }
    .p-invalid + .form-fields:enabled:hover,
    .p-invalid-hover {
      border-color: $danger-red-color !important;
    }
  }
  .button-container {
    display: flex;
    flex-direction: row-reverse;
    gap: 6px;
  }
  .p-dialog-footer {
    padding: 20px 15px !important;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.missing-payment-report-panel-style {
  .p-multiselect-items-wrapper {
    max-height: 350px !important;
  }

  .p-multiselect-item.p-highlight {
    color: $primary-color !important;
    background-color: #f8f9fa !important;
    margin: 2px 0px;
  }

  .p-multiselect-item.p-highlight:focus {
    box-shadow: none !important;
  }

  .p-multiselect-items {
    padding: 0px !important;
  }
}

@media (max-width: $device-small) {
  .missing-payment-report-dialog-container {
    width: 90%;

    .missing-payment-report-form {
      .form-input {
        display: block;
      }
      .field {
        width: $field-width-100;
        margin-bottom: $form-field-margin;
      }
    }
  }
}
