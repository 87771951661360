@import 'theme.scss';

.subscription-payment-type {
  border-radius: $form-container-border-radius;
  padding: $form-container-padding;
  box-shadow: $form-container-box-shadow;

  .field {
    width: $field-width-60;
    margin-bottom: $form-field-margin;
    .p-invalid + .form-fields:enabled:hover,
    .p-invalid-hover {
      border-color: $danger-red-color !important;
    }
    .dropdown-field {
      width: $field-width-100;
    }
    .p-dropdown:not(.p-disabled).p-focus {
      box-shadow: $focus-shadow-color;
      border-color: $primary-color;
    }
    .p-dropdown:not(.p-disabled):hover {
      border-color: $primary-color;
    }
    .p-invalid + .p-dropdown:hover .p-invalid-hover {
      border-color: $danger-red-color;
    }
    label {
      margin-top: $float-label-margin-top;
      left: $form-label;
      margin-left: $float-label-margin-left;
    }
    .mapping-label {
      color: $grey-font-color-on-hover;
    }
    .error-message {
      margin-left: $form-error-message-margin;
      color: $danger-red-color;
      font-size: $small;
    }
    .p-error {
      margin-left: $float-label-margin-left;
      color: $danger-red-color;
    }
    .required-field {
      color: $danger-red-color;
      font-weight: $font-medium;
      font-size: $x-large;
      margin: 3px;
    }
  }
  .add-subscription-type-container-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .error-status {
    margin-left: $form-error-message-margin;
    font-size: $small;
    color: $danger-red-color;
  }
}

@media screen and (max-width: $device-small) {
  .subscription-payment-type {
    .field {
      width: $field-width-100;
      margin-bottom: $form-field-margin;
    }
  }
}
