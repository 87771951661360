@import 'theme.scss';

.edit-coffeequotes-dailog-modal {
  min-width: 700px;
  .edit-coffeequotes-form {
    padding: 30px 20px 20px 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    input,
    .p-calendar {
      width: 100%;
      margin-bottom: 7px;
    }

    .p-inputtext:enabled {
      &:hover {
        border: 1px solid $primary-color;
        box-shadow: 0 0 0 0.2rem $box-shadow-primary-color;
      }
      &:focus {
        box-shadow: 0 0 0 0.2rem $box-shadow-primary-color;
      }
    }
    .p-inputtext:enabled:focus,
    .p-float-label label {
      top: 50% !important;
    }
    .p-float-label input:focus ~ label,
    .p-float-label input.p-filled ~ label,
    .p-float-label .p-inputwrapper-focus ~ label,
    .p-float-label .p-inputwrapper-filled ~ label {
      top: -0.5rem !important;
      left: 6px;
    }

    .mandidate {
      border: 1px solid red !important;
      box-shadow: 0 0 0 0.2rem rgb(250, 194, 194) !important;
    }
  }

  .p-dialog-header {
    padding: 0.7rem 1.5rem;
    border-bottom: 1px solid lightgray;
  }

  .p-dialog-content {
    padding: 0;
  }
  .p-dialog-footer {
    padding: 10px;
    border-top: 1px solid lightgray;
    justify-content: flex-end;
    .button-container {
      display: flex;
      align-items: flex-end;
      gap: 8px;
      justify-content: flex-end;
      button {
        margin: 0;
      }
    }
  }
}

.p-datepicker.p-component {
  width: auto !important;
}
.p-datepicker table td {
  padding: 2px;
}
