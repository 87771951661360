@import 'theme.scss';

.profile-container {
  border-radius: $form-container-border-radius;
  padding: $form-container-padding;
  box-shadow: $form-container-box-shadow;

  .field {
    margin-bottom: $form-field-margin;
  }
  .p-fluid {
    .p-inputtext {
      width: $field-width-60;

      &:hover {
        border-color: $primary-color;
      }
    }
  }
  label {
    left: $form-label;
    margin-left: 7px;
    margin-top: -12px;
    color: $grey-font-color-on-hover;
  }
  .star-required {
    color: $danger-red-color;
    font-size: $x-large;
    font-weight: $font-medium;
  }
}
