@import 'theme.scss';

.holiday-component-module {
  .p-tabview-panels {
    padding: 0.75rem 0.75rem 1rem 0.75rem !important;
  }
  .holiday-list-tab-wrapper {
    .holiday-list-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .p-inputtext {
        font-family: 'Roboto', 'Arial', sans-serif;
      }
    }
    table {
      thead {
        .action-buttons-column-cell {
          width: 10%;
          div {
            justify-content: center;
          }
        }
      }
      tbody {
        tr td {
          padding: 0.65rem 1rem !important;
        }
        .action-buttons-column-cell {
          text-align: center !important;
          .toggle-switch-menu {
            .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
              background-color: $primary-color;
            }
            .p-inputswitch .p-inputswitch-slider {
              box-shadow: none;
            }
          }
          .action-buttons-wrapper {
            display: flex;
            gap: 20px;
            justify-content: center;
          }
          .p-button {
            background-color: $white;
            border: none;
            width: auto;
            padding: 0.3rem;
            &:focus {
              box-shadow: none;
            }
            &:hover {
              background-color: $white;
            }
            .p-button-icon {
              color: $primary-color;
              font-size: 1rem;
            }
          }
          .pi-trash {
            color: #ef4444 !important;
          }
        }
      }
    }
    .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
      background: $white;
      border-color: $primary-color;
      color: $primary-color;
    }
    .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
      box-shadow: none;
    }
    .p-tabview .p-tabview-nav .p-tabview-ink-bar {
      background-color: $primary-color;
    }
  }
}
