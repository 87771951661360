@import 'theme.scss';

.feature-toggle {
  border-radius: $form-container-border-radius;
  box-shadow: $form-container-box-shadow;
  height: 60vh;
  overflow-y: scroll;
  .options {
    width: 90%;
  }

  .feature-settings-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin: 6px;

    .p-button:focus {
      box-shadow: $focus-shadow-color;
    }
  }
  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: $primary-color;
    &:hover {
      background: $primary-color;
    }
  }
  .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background: $primary-color;
  }
  .p-inputswitch.p-focus .p-inputswitch-slider {
    box-shadow: 0 0 0 0.2rem $box-shadow-primary-color;
  }
}
