@import 'theme.scss';

.delete-news-dialog {
  width: 30rem;
  background-color: $white;
  .p-dialog-header {
    border-bottom: 1px solid #dee2e6;
    padding: 12px 10px 5px 24px;
  }
  .delete-news-button-container {
    display: flex;
    justify-content: flex-end;
    margin: -8px;
  }
  .dialog-content-wrapper {
    padding-top: 28px;
    .drop-down-field {
      .remark-drop-down {
        width: 80%;
      }

      .p-dropdown:hover {
        border: 1px solid $primary-color;
      }

      .p-dropdown:focus {
        border: 1px solid $primary-color;
        box-shadow: $focus-shadow-color;
      }

      .p-dropdown:not(.p-disabled).p-focus {
        border: 1px solid $primary-color;
        box-shadow: $focus-shadow-color;
      }

      .p-dropdown.p-invalid.p-component {
        border-color: $danger-red-color;
      }
    }
    .required-star {
      color: $danger-red-color;
      font-weight: $font-medium;
      font-size: $x-large;
      margin: 0px 3px;
    }
    .error-message {
      color: $danger-red-color;
      margin-left: $form-error-message-margin;
      margin-top: -2px;
    }
    .field-label {
      margin-left: -2px;
    }
    .custom-reason-field-wrapper {
      padding-top: 28px;
      .custom-reason-field-container {
        .custom-reason-field {
          width: 80%;
        }
      }
      .p-inputtext:enabled:hover {
        border-color: $primary-color;
      }
      .p-inputtext:enabled:focus {
        border: 1px solid $primary-color;
        box-shadow: $focus-shadow-color;
      }

      .p-inputtext.p-invalid.p-component {
        border-color: $danger-red-color;
      }
    }
  }
}

@media (max-width: $device-small) {
  .delete-news-dialog {
    width: 94%;
    .dialog-content-wrapper {
      .drop-down-field {
        .remark-drop-down {
          width: $field-width-100;
        }
      }
    }
    .dialog-content-wrapper {
      .custom-reason-field-wrapper {
        .custom-reason-field-container {
          .custom-reason-field {
            width: $field-width-100;
          }
        }
      }
    }
  }
}
