@import 'theme.scss';

.port-wise-dialog-modal {
  background-color: $white;

  &.arabica-form {
    width: 94%;
  }
  &.robusta-form {
    width: 70%;
  }

  .form-fields {
    width: 70%;
  }

  .p-dialog-content {
    padding-top: 25px;
  }
  .p-link:focus {
    box-shadow: $focus-shadow-color;
  }
  .p-dialog-title {
    text-transform: uppercase;
  }

  .required-text-field {
    color: $danger-red-color;
    margin-left: 2px;
    font-size: $x-large;
  }

  .field-label {
    margin-top: $float-label-margin-top;
    left: $form-label;
    margin-left: $float-label-margin-left;
    &.error-label {
      color: $danger-red-color;
    }
  }

  .error-message {
    color: $danger-red-color;
    margin-left: $form-error-message-margin;
  }

  .report-data-form {
    .input-fields {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
      width: 100%;

      .form-fields {
        width: 100%;
        margin: 1rem 0;

        .text-field {
          width: $field-width-100;

          &:hover {
            border-color: $primary-color;
          }
          &:focus {
            border: 1px solid $primary-color;
            box-shadow: $focus-shadow-color;
          }
          &.error-field {
            border: 1px solid $danger-red-color;
            border-radius: 4px;
          }
        }

        .dropdown-field {
          width: $field-width-100;
          &:hover {
            border-color: $primary-color;
          }
          &.error-field {
            border-color: $danger-red-color;
          }
        }
        .p-dropdown:not(.p-disabled).p-focus {
          border: 1px solid $primary-color;
          box-shadow: $focus-shadow-color;
        }

        .error-field.p-dropdown:not(.p-disabled).p-focus {
          border: 1px solid $danger-red-color;
        }
      }
    }

    .button-content {
      display: flex;
      justify-content: center;
      gap: 12px;
      .edit-button {
        background-color: $primary-color;
        border: 1px solid $primary-color;
        width: 36px;
        height: 36px;

        &:hover {
          color: $white;
          border: 1px solid $on-primary-hover;
          background-color: $on-primary-hover;
          border-color: $on-primary-hover;
        }
        &:focus {
          box-shadow:
            0 0 0 2px $white,
            0 0 0 4px $primary-color,
            0 1px 2px 0 $grey-font-color-on-hover;
        }
      }

      .delete-button {
        width: 36px;
        height: 36px;

        background-color: $danger-red-color;
        border: 1px solid $danger-red-color;
        &:hover {
          background-color: #d32f2f;
        }
        &:focus {
          box-shadow:
            0 0 0 2px #fff,
            0 0 0 4px #ffcece,
            0 1px 2px 0 #343a40;
        }
      }
    }
  }
  .calendar-input {
    margin-bottom: 1rem;
    .calendar-field {
      width: $field-width-40;
      &:hover {
        border-color: $primary-color;
      }
      .p-button.p-button-icon-only {
        width: 3rem;
        padding: 0.75rem 0;
      }

      .p-button-icon-only {
        justify-content: center;
      }
      .p-button {
        margin: 0;
        color: $white;
        background: $primary-color;
        border: 1px solid $primary-color;
        transition:
          background-color 0.2s,
          color 0.2s,
          border-color 0.2s,
          box-shadow 0.2s;
      }
      .p-button:focus {
        box-shadow: $focus-shadow-color;
      }
      .p-inputtext:enabled:hover {
        border-color: $primary-color;
      }
      .p-inputtext:enabled:focus {
        box-shadow: $focus-shadow-color;
        border-color: $primary-color;
      }
      &.error-field {
        .p-inputtext:enabled {
          border-color: $danger-red-color;
        }
        .p-inputtext:enabled:hover {
          border-color: $danger-red-color;
        }
        .p-inputtext:enabled:focus {
          border-color: $danger-red-color;
        }
      }
    }
  }

  .footer-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
  }
}

@media (max-width: $device-small) {
  .port-wise-dialog-modal {
    &.arabica-form,
    &.robusta-form {
      width: 96%;
    }
    background-color: $white;
    .report-data-form {
      .input-fields {
        display: block;
        .form-fields {
          margin: 2rem 0;
        }
      }
    }

    .form-fields {
      width: 100%;
    }
    .calendar-input {
      margin-bottom: 2rem;
      .calendar-field {
        width: $field-width-100;
      }
    }
  }
}
