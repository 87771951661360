.error-boundary-container {
  background-color: #f8d7da;
  border: 1px solid #dc3545;
  padding: 20px;
  margin: 20px auto;
  max-width: 400px;
}

.error-boundary-container h2 {
  color: #dc3545;
}

.error-boundary-container button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.error-boundary-container button:hover {
  background-color: #c82333;
}
