@import 'theme.scss';

.list-roles {
  .p-datatable .p-datatable-thead > tr > th {
    padding: $table-row-padding;
  }

  .p-datatable .p-datatable-tbody > tr > td {
    padding: $table-row-padding;
  }
  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    color: $primary-color;
  }
  .p-link:focus {
    box-shadow: $focus-shadow-color;
  }
  .list-role-search-bar {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 8px;
    input {
      padding: 9px 10px 9px 31px;
    }
    input:hover {
      border-color: $primary-color;
    }
    input:focus {
      box-shadow: $focus-shadow-color;
      border-color: $primary-color;
    }
  }
  .list-role-table {
    width: 50%;
  }
  .list-role-code {
    width: 50%;
  }
}

@media (max-width: $device-medium) {
  .list-role-table {
    width: 60%;
  }
}
