@import 'theme.scss';

.total-certified-stock-report {
  .header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 4px;
  }
  .dropdown-wrapper {
    .p-dropdown {
      min-width: 180px;
      width: 100%;
    }
    .p-dropdown:not(.p-disabled).p-focus {
      border: 1px solid $primary-color;
      box-shadow: 0 0 0 0.2rem rgba(80, 179, 246, 0.2509803922);
    }

    .p-dropdown:not(.p-disabled):hover {
      border: 1px solid $primary-color;
    }
  }

  .table-wrapper {
    .header-container-wrapper {
      background-color: $white;
      position: sticky;
      top: 0px;
      padding-bottom: 4px;
      // z-index: 12;
      .header-container {
        display: grid;
        grid-template-columns: 1fr 3fr 1fr;
        background-color: $primary-color;
        padding: 4px 4px;
        border-radius: 4px;

        .content-title {
          font-weight: $font-bold;
          color: $white;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .market-selector {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          text-transform: uppercase;
          gap: 4px;
          color: $white;
          font-weight: $font-bold;
          .arrow-toggle {
            display: flex;
            flex-direction: column;
            .arrow-up {
              font-size: $x-small;
              font-weight: $font-bold;
            }
            .arrow-down {
              font-size: $x-small;
              font-weight: $font-bold;
            }
          }
        }
      }
    }

    .p-datatable .p-datatable-thead > tr > th {
      padding: $table-row-padding;
      min-width: 120px;
    }

    .p-datatable .p-datatable-tbody > tr > td {
      padding: $table-row-padding;
    }

    .date-column {
      width: 10%;
    }
    .certified-stock-column {
      width: 12%;
    }
    .compare-to-pre-column {
      width: 14%;
    }
    .grading-column,
    .pending-report-column {
      width: 32%;
    }
    .action-column {
      width: 2%;
    }
    .action-button-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .edit-button {
        background-color: $white;
        color: $primary-color;
        border: none;
        padding: 0;
        &:hover {
          background-color: $white;
          color: $primary-color;
        }
        &:focus {
          box-shadow: none;
        }
      }

      .deleted-button {
        background-color: $white;
        color: $danger-red-color;
        border: none;
        padding: 0;
        &:hover {
          background-color: $white;
          color: $danger-red-color;
        }
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}
