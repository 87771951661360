@import 'theme.scss';

.trader-wise-report-edit-modal {
  width: 50vw;
  background-color: $white;

  .p-dialog-content {
    padding-top: 25px;
  }
  .p-dialog-title {
    text-transform: uppercase;
  }
  .p-link:focus {
    box-shadow: $focus-shadow-color;
  }

  .trader-wise-report-footer {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }

  .edit-form-container {
    .action-button {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 1rem;
    }

    .form-section {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }

    .form-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      .form-fields {
        margin-bottom: 1rem;
        .text-field {
          width: $field-width-100;

          &:hover {
            border: 1px solid $primary-color;
          }

          &:focus {
            border: 1px solid $primary-color;
            box-shadow: $focus-shadow-color;
          }
          &.error-fields {
            border-color: $danger-red-color !important;
          }
        }

        .field-label {
          margin-top: $float-label-margin-top;
          left: $form-label;
          margin-left: $float-label-margin-left;
          &.error-fields {
            color: $danger-red-color;
          }
        }

        .required-text-field {
          color: $danger-red-color;
          margin-left: 2px;
          font-size: $x-large;
        }

        .error-message {
          color: $danger-red-color;
          margin-left: $form-error-message-margin;
        }

        .calendar-input {
          width: $field-width-100;
          .p-button.p-button-icon-only {
            width: 3rem;
            padding: 0.75rem 0;
          }

          .p-button-icon-only {
            justify-content: center;
          }
          .p-button {
            margin: 0;
            color: $white;
            background: $primary-color;
            border: 1px solid $primary-color;
            transition:
              background-color 0.2s,
              color 0.2s,
              border-color 0.2s,
              box-shadow 0.2s;
          }
          .p-button:focus {
            box-shadow: $focus-shadow-color;
          }
          .p-inputtext:enabled:hover {
            border-color: $primary-color;
          }

          &.error-fields {
            .p-inputtext:enabled {
              border-color: $danger-red-color;
            }
            .p-inputtext:enabled:hover {
              border-color: $danger-red-color;
            }
            .p-inputtext:enabled:focus {
              border-color: $danger-red-color !important;
            }
          }
        }
      }
    }
  }
}
