@import 'theme.scss';

.global-raw-data-container {
  .global-data-table {
    border: 1px solid lightgray;
    margin-top: 10px;
  }
  .p-tabview-panels {
    padding: 0 !important;
  }

  .p-message.p-message-info {
    width: fit-content;
  }

  .p-message.p-message-error + .p-datatable .p-datatable-thead > tr > th {
    padding: $table-row-padding;
  }

  .global-data-table {
    .p-tabview-panels {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .p-datatable .p-datatable-tbody > tr > td,
  .p-datatable .p-datatable-thead > tr > th {
    padding: $table-row-padding;
    text-align: center;
    .p-column-header-content {
      justify-content: center;
    }
  }
  .p-datatable .p-datatable-thead > tr > th {
  }

  .global-raw-data-tab-panel {
    border-bottom: 1px solid $white-smoke;
    // padding-top: 10px;
  }

  .row-data-content {
    width: 50px;
    display: inline-block;
  }

  .column-price-rate {
    margin: 0 6px;
  }

  .header-content {
    margin-left: 0px;
  }

  .country-select-component {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .p-dropdown-label {
      div {
        display: flex;
        gap: 7px;
        align-items: center;
      }
    }
    .p-dropdown:not(.p-disabled).p-focus {
      box-shadow: $focus-shadow-color;
      border-color: $primary-color;
    }
    .p-dropdown {
      width: 250px;
      border-radius: 5px;
    }
    .action-buttons {
      display: flex;
      gap: 10px;
    }
  }

  .column-data {
    width: 5%;
  }

  .column-producing-country {
    width: 18%;
  }

  .column-price-bag {
    width: 12%;
  }

  .column-price-bag-rate {
    width: 7%;
  }

  .column-row-data {
    width: 15%;
  }

  .column-outTurn {
    width: 10%;
  }

  .p-button:focus {
    box-shadow: $focus-shadow-color;
  }

  .p-link:focus {
    box-shadow: $focus-shadow-color;
  }
}

@media (max-width: $device-medium) {
  .global-raw-data-container {
    .country-select-component {
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .pi {
      font-size: $x-large;
    }

    .pi-check-circle:before {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .p-paginator-bottom {
      padding-bottom: 50px;
    }
  }

  .p-tabview-panels {
    padding: 0;
  }
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: $focus-shadow-color !important;
  border-color: $primary-color !important;
}
