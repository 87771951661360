@import 'theme.scss';

.dashboard-container {
  padding: 1rem;
  min-height: -webkit-fill-available;
  .dashboard_Body {
    display: flex;
    justify-content: center;
    font-size: larger;
    color: $primary-color;
  }
}

@media (max-width: $device-small) {
  .dashboard-container {
    padding-top: 0px;

    .dashboard_Body {
      font-size: smaller;
    }
  }
}
