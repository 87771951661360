@import 'theme.scss';

.p-component-overlay-leave {
  animation: $modal-overlay-animation;
}

.add-payment-country-master {
  min-width: $field-width-100;
  border-radius: $modal-border;
  padding: $form-container-padding;
  box-shadow: $form-container-box-shadow;
  .country-name {
    font-size: $x-small;
    margin-left: 9px;
    margin-bottom: -1px;
  }
  .p-error {
    margin-left: 4px;
    font-size: $x-small;
  }

  .add-payment-country-fields {
    width: $field-width-60;
    .error-message {
      color: $danger-red-color;
    }
    .star {
      color: $danger-red-color;
      font-weight: $font-medium;
      font-size: $x-large;
      margin: 3px;
    }
    .add-payment-country {
      color: $grey-font-color-on-hover;
    }
  }

  .p-dropdown:not(.p-disabled).p-focus {
    box-shadow: $focus-shadow-color;
    border-color: $primary-color;
  }

  .p-dropdown:not(.p-disabled):hover {
    border-color: $primary-color;
  }

  .p-invalid + .p-inputtext:enabled:hover,
  .p-invalid-hover {
    border: 1px solid $danger-red-color !important;
  }

  .p-inputtext:enabled:focus {
    border-color: $primary-color;
    box-shadow: 0 0 0 0.2rem $box-shadow-primary-color;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: $primary-color;
    border-color: $primary-color;
  }
  .p-float-label label {
    position: absolute;
    pointer-events: none;
    margin-top: $float-label-margin-top;
    transition-property: all;
    transition-timing-function: ease;
    margin-left: $float-label-margin-left;
    font-weight: $font-normal;
    color: $grey-font-color-on-hover;
  }

  label {
    left: $form-label;
  }
  .add-payment-container-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: $form-field-margin;
  }
}

@media screen and (max-width: $device-small) {
  .add-payment-country-master {
    .add-payment-country-fields {
      width: $field-width-100;
    }
  }
}
