@import 'theme.scss';

.country-wise-dialog-modal {
  &.arabica-form {
    width: 94%;
  }
  &.robusta-form {
    width: 70%;
  }
  .p-dialog-content {
    padding-top: 25px;
  }
  .p-link:focus {
    box-shadow: $focus-shadow-color;
  }
  .p-dialog-title {
    text-transform: uppercase;
  }

  .form-container {
    .input-field-wrapper {
      width: 70%;
    }
  }

  .input-container {
    width: 100%;

    .field-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
    }
    .button-content {
      display: flex;
      justify-content: center;
      gap: 12px;
      .edit-button {
        background-color: $primary-color;
        border: 1px solid $primary-color;
        width: 36px;
        height: 36px;

        &:hover {
          color: $white;
          border: 1px solid $on-primary-hover;
          background-color: $on-primary-hover;
          border-color: $on-primary-hover;
        }
        &:focus {
          box-shadow:
            0 0 0 2px $white,
            0 0 0 4px $primary-color,
            0 1px 2px 0 $grey-font-color-on-hover;
        }
      }

      .delete-button {
        width: 36px;
        height: 36px;

        background-color: $danger-red-color;
        border: 1px solid $danger-red-color;
        &:hover {
          background-color: #d32f2f;
        }
        &:focus {
          box-shadow:
            0 0 0 2px #fff,
            0 0 0 4px #ffcece,
            0 1px 2px 0 #343a40;
        }
      }
    }
  }
  .input-field-wrapper {
    margin: 1rem 0;
    width: 100%;

    .input-field {
      width: $field-width-100;
      &:hover {
        border: 1px solid $primary-color;
      }
      &.error {
        border: 1px solid $danger-red-color;
        border-radius: 4px;
      }
    }
    .error.p-inputtext:enabled:focus {
      border-color: $danger-red-color;
    }

    .required-text-field {
      color: $danger-red-color;
      margin-left: 2px;
      font-size: $x-large;
    }

    .field-label {
      margin-top: $float-label-margin-top;
      left: $form-label;
      margin-left: $float-label-margin-left;
      &.error {
        color: $danger-red-color;
      }
    }

    .error-message {
      color: $danger-red-color;
      margin-left: $form-error-message-margin;
    }
    .p-inputtext:enabled:focus {
      box-shadow: $focus-shadow-color;
      border-color: $primary-color;
    }

    .p-calendar {
      width: $field-width-40;
    }

    .p-dropdown {
      width: $field-width-100;
    }

    .p-dropdown:not(.p-disabled).p-focus {
      border: 1px solid $primary-color;
      box-shadow: $focus-shadow-color;
    }
    .p-dropdown:not(.p-disabled).p-focus {
      border: 1px solid $primary-color;
      box-shadow: $focus-shadow-color;
    }

    .p-dropdown:not(.p-disabled):hover {
      border: 1px solid $primary-color;
    }

    .error.p-dropdown:not(.p-disabled).p-focus {
      border: 1px solid $danger-red-color;
    }
    .error.p-dropdown:not(.p-disabled).p-focus {
      border: 1px solid $danger-red-color;
    }

    .error.p-dropdown:not(.p-disabled).p-focus {
      border: 1px solid $danger-red-color;
    }

    .error.p-dropdown:not(.p-disabled):hover {
      border: 1px solid $danger-red-color;
    }

    .calendar-field {
      .p-button.p-button-icon-only {
        width: 3rem;
        padding: 0.75rem 0;
      }

      .p-button-icon-only {
        justify-content: center;
      }
      .p-button {
        margin: 0;
        color: $white;
        background: $primary-color;
        border: 1px solid $primary-color;
        transition:
          background-color 0.2s,
          color 0.2s,
          border-color 0.2s,
          box-shadow 0.2s;
      }
      .p-button:focus {
        box-shadow: $focus-shadow-color;
      }
      .p-inputtext:enabled:hover {
        border-color: $primary-color;
      }
      &.error {
        .p-inputtext:enabled {
          border-color: $danger-red-color;
        }
        .p-inputtext:enabled:hover {
          border-color: $danger-red-color;
        }
        .p-inputtext:enabled:focus {
          border-color: $danger-red-color;
        }
      }
    }
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }
}

@media (max-width: $device-small) {
  .country-wise-dialog-modal {
    background-color: $white;
    &.arabica-form,
    &.robusta-form {
      width: 94%;
    }

    .p-dialog-content {
      padding-top: 0px;
    }

    .form-container {
      display: block;

      .input-field-wrapper {
        width: 100%;
      }
    }
    .input-container {
      .field-wrapper {
        display: block;
      }
    }

    .input-field-wrapper {
      margin: 2rem 0;
      .p-calendar {
        width: $field-width-100;
      }
    }
  }
}
