@import 'theme.scss';

.edit-profile-information {
  .profileInformation-card {
    .button-group {
      display: flex;
      justify-content: flex-end;
      gap: $form-button-gap;
    }
  }
}
