@import 'theme.scss';

.dialog-component {
  min-width: 400px;
  max-width: 400px;
  min-height: 18%;
  background-color: $white;
  word-wrap: break-word;

  .p-dialog-header {
    border-bottom: 1px solid $light-grey-border;
    padding: 8px 10px 8px 24px;

    .p-dialog-header-icon:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: $focus-shadow-color;
    }

    .dialog-component-error {
      color: $danger-red-color;
    }

    .dialog-component-success {
      color: $success-background;
    }

    .dialog-component-info {
      color: $grey-font-color-on-hover;
    }
  }

  .p-dialog-content {
    padding: 1rem 1rem 1.5rem 1.5rem;
  }

  .dialog-component-message-confirmation {
    display: flex;
    align-items: center;
    // font-size: $large;
    // padding-top: 12px;
    gap: 10px;

    .pi-exclamation-triangle {
      font-size: $xxx-large;
      color: $danger-red-color;
    }
  }

  .p-dialog-footer {
    padding: 0 1rem 1rem 1.5rem;

    .dialog-component-footer {
      .neutral-button {
        color: $primary-color;
        border: 1px solid $primary-color;
        border-color: $primary-color;
        background-color: $white;
        min-width: 98px;
        padding: $button-label-padding;
      }

      .neutral-button:focus {
        box-shadow:
          0 0 0 2px $white,
          0 0 0 4px $primary-color,
          0 1px 2px 0 $grey-font-color-on-hover;
      }

      .neutral-button:hover {
        color: $on-primary-hover;
        border-color: $on-primary-hover;
        background-color: $white;
      }

      .retry-button {
        color: $white;
        border: 1px solid $primary-color;
        background-color: $primary-color;
        border-color: $primary-color;
        min-width: 98px;
        padding: $button-label-padding;
      }

      .retry-button:focus {
        box-shadow:
          0 0 0 2px $white,
          0 0 0 4px $primary-color,
          0 1px 2px 0 $grey-font-color-on-hover;
      }

      .retry-button:hover {
        color: $white;
        border: 1px solid $on-primary-hover;
        background-color: $on-primary-hover;
        border-color: $on-primary-hover;
      }

      .confirm-action-button {
        color: $white;
        background-color: $primary-color;
        border-color: $primary-color;
        min-width: 98px;
        padding: $button-label-padding;
      }

      .confirm-action-button:hover {
        color: $white;
        background-color: $on-primary-hover;
        border-color: $on-primary-hover;
      }

      .confirm-action-button:focus {
        box-shadow:
          0 0 0 2px $white,
          0 0 0 4px $primary-color,
          0 1px 2px 0 $grey-font-color-on-hover;
      }
    }

    .dialog-component-footer button:last-child {
      margin: 0;
    }
  }
}

@media (max-width: $device-small) {
  .dialog-component {
    min-width: 92%;
    max-width: 92%;
  }
}
