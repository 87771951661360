@import 'theme.scss';

.add-terminal-form {
  border-radius: $form-container-border-radius;
  padding: $form-container-padding;
  box-shadow: $form-container-box-shadow;

  .field {
    width: $field-width-60;
    margin-bottom: $form-field-margin;

    label {
      left: $form-label;
      margin-top: $float-label-margin-top;
      margin-left: $float-label-margin-left;
    }
    .p-float-label > label {
      margin-left: $float-label-margin-left;
      color: $grey-font-color-on-hover;
    }
    .p-error {
      color: $danger-red-color;
    }

    .p-float-label > label.p-error {
      color: $danger-red-color;
      // margin-left: $form-error-message-margin;
    }

    .error-message {
      font-size: $small;
      margin-left: $form-error-message-margin;
    }
    .form-fields:focus {
      box-shadow: $focus-shadow-color;
      border-color: $primary-color;
    }
    .p-dropdown:not(.p-disabled):hover {
      border-color: $primary-color;
    }
    .p-dropdown:not(.p-disabled).p-focus {
      box-shadow: $focus-shadow-color;
      border-color: $primary-color;
    }
    .p-invalid + .form-fields:enabled:hover,
    .p-invalid-hover {
      border-color: $danger-red-color !important;
    }
    .p-inputtext:enabled:hover {
      border-color: $primary-color;
    }

    .p-invalid .p-inputtext:enabled:hover {
      border-color: $danger-red-color;
    }
    .p-invalid .p-inputtext:enabled:focus {
      border-color: $danger-red-color;
    }
    .p-filled .p-inputtext:enabled:focus {
      border-color: $primary-color;
    }
  }
  .add-terminal-button {
    display: flex;
    justify-content: flex-end;
  }
  .span-text-color {
    color: $danger-red-color;
    font-size: $x-large;
  }

  .p-button {
    padding: $button-label-padding;
    background-color: $primary-color;
    border-color: $primary-color;
  }
  .p-button:hover {
    background-color: $on-primary-hover;
    border-color: $on-primary-hover;
  }
  .p-button:focus {
    box-shadow: $focus-shadow-color;
  }
  .p-inputtext:enabled:focus {
    box-shadow: $focus-shadow-color;
    border-color: $primary-color;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background-color: $light-grey;
    color: $primary-color;
  }
}
.p-datepicker table th {
  padding: 0.5rem;
  text-align: center;
}

@media screen and (max-width: $device-small) {
  .add-terminal-form {
    .field {
      width: $field-width-100;
    }
  }
}
