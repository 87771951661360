@import 'theme.scss';

.list-language {
  .search-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 8px;
    gap: $action-header-gap;

    .search-field {
      width: 190px;

      .p-inputtext {
        width: 100%;

        &:hover {
          border: 1px solid $primary-color;
        }

        &:focus {
          border: 1px solid $primary-color;
          box-shadow: $focus-shadow-color;
        }
      }
    }
  }

  .edit-button {
    background-color: $white;
    border: none;
    outline: none;
    color: $primary-color;
    padding: 0;

    &:hover {
      background-color: $white;
      color: $primary-color;
    }
  }

  .p-link:focus {
    box-shadow: $focus-shadow-color;
  }

  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    color: $primary-color;
  }

  .list-language-data-table {
    .p-datatable .p-datatable-thead > tr > th {
      padding: $table-row-padding;
    }

    .p-datatable .p-datatable-tbody > tr > td {
      padding: $table-row-padding;
    }
  }

  .toggle-switch {
    display: flex;
    align-items: center;

    .p-inputswitch.p-inputswitch-checked {
      .p-inputswitch-slider,
      .p-inputswitch-slider:hover {
        background-color: $primary-color;
      }
    }
    .p-inputswitch.p-focus .p-inputswitch-slider {
      box-shadow: 0 0 0 0.2rem $box-shadow-primary-color;
    }
  }

  .column-headers {
    width: 30%;
  }

  .local-column {
    width: 22%;
  }

  .toggle-column {
    width: 9%;
  }

  .action-column {
    width: 5%;
  }
}
