@import 'theme.scss';
.admin-menu-wrapper {
  .admin-menu-table-container {
    .p-datatable .p-column-header-content {
      display: flex;
      justify-content: center;
    }

    .p-datatable .p-datatable-thead > tr > th {
      padding: $table-row-padding;
    }

    .p-datatable .p-datatable-tbody > tr > td {
      padding: $table-row-padding;
    }
    .search-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
      .p-inputtext:enabled:hover {
        border-color: $primary-color;
      }
      .p-inputtext:enabled:focus {
        border-color: $primary-color;
        box-shadow: 0 0 0 0.2rem $box-shadow-primary-color;
      }

      .add-button {
        display: flex;
        align-items: center;
      }
    }

    .admin-menu-table {
      width: 100%;
      margin-bottom: $table-margin-bottom;

      .menu-name {
        width: 53%;
        div {
          justify-content: flex-start;
        }
      }

      .is-active {
        width: 14%;
      }
      .is-launch {
        width: 14%;
      }
      .web-display {
        width: 14%;
      }

      .action-column {
        width: 5%;
      }

      .toggle-switch-menu {
        display: flex;
        align-items: center;
        justify-content: center;
        .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
          background-color: $primary-color;
        }
        .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:hover {
          background-color: $primary-color;
        }
        .p-inputswitch.p-focus .p-inputswitch-slider {
          box-shadow: 0 0 0 0.2rem $box-shadow-primary-color;
        }
      }
      .edit-button {
        background-color: $white;
        border: none;
        outline: none;
        color: $primary-color;
        padding: 0;

        &:hover {
          background-color: $white;
          color: $primary-color;
          border: none;
        }

        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}
