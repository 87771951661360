@import 'theme.scss';

.subscription-map-menu-container {
  box-shadow: $form-container-box-shadow;
  border-radius: $form-container-border-radius;
  padding: 18px;

  .subscription-menu-container-head {
    display: flex;
    justify-content: space-between;
    .menu-heading {
      font-size: $x-large;
      font-weight: $font-bold;
      margin-left: 12px;
    }
  }

  .subscription-menu-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 20px;
    padding: 12px;

    .subscription-menu-data {
      display: flex;
      gap: 8px;
      margin-bottom: 10px;
      align-items: center;

      .p-checkbox .p-checkbox-box.p-highlight {
        border-color: $primary-color;
        background: $primary-color;
      }

      .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
        border-color: $primary-color;
      }

      .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:focus {
        border-color: $primary-color;
      }

      .p-checkbox,
      .p-focus {
        outline: 0 none;
        outline-offset: 0;
      }

      .p-focus {
        border-color: $primary-color;
        box-shadow: $focus-shadow-color;
      }
    }
  }
  .submit-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

@media screen and (max-width: $device-small) {
  .subscription-map-menu-container {
    .subscription-menu-wrapper {
      display: block;
    }
  }
}
