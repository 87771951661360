@import 'theme.scss';

.admin-subscription {
  .edit-button {
    background-color: $white;
    border: none;
    outline: none;
    color: $primary-color;
    padding: 0;

    &:hover {
      background-color: $white;
      color: $primary-color;
    }
  }
  .edit-button:enabled:active {
    background: none;
    color: $primary-color;
    border-color: none;
  }
  .edit-button:focus {
    box-shadow: none;
  }
  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    color: $primary-color;
  }

  .p-link:focus {
    box-shadow: $focus-shadow-color;
  }

  .toggle-switch {
    display: flex;
    align-items: center;

    .p-inputswitch.p-inputswitch-checked {
      .p-inputswitch-slider,
      .p-inputswitch-slider:hover {
        background-color: $primary-color;
      }
    }
    .p-inputswitch.p-focus .p-inputswitch-slider {
      box-shadow: 0 0 0 0.2rem $box-shadow-primary-color;
    }
  }

  .subscription-table-container {
    .p-datatable .p-datatable-thead > tr > th {
      padding: $table-row-padding;
    }

    .p-datatable .p-datatable-tbody > tr > td {
      padding: $table-row-padding;
    }
    .action-container {
      display: flex;
      margin-left: -14px;
      .config-button {
        background-color: $white;
        border: none;
        outline: none;
        color: $primary-color;
        padding: 0;

        &:hover {
          background-color: $white;
          color: $primary-color;
        }
      }
      .config-button:enabled:active {
        background: none;
        color: $primary-color;
        border-color: none;
      }
      .config-button:focus {
        box-shadow: none;
      }
    }
  }

  .subscription-column {
    width: 24%;
  }

  .amount-column {
    width: 15%;
  }

  .currency-column {
    width: 8%;
  }

  .country-column {
    width: 13%;
  }

  .code-column {
    width: 13%;
  }

  .ios-column {
    width: 15%;
  }

  .action-column {
    width: 5%;
  }

  .toggle-column {
    width: 8%;
  }
}
