//media-query-device-sizes
$device-medium: 992px;
$device-small: 768px;

/* Font sizes in pixels */
$xxx-small: 8px;
$xx-small: 10px;
$x-small: 12px;
$small: 14px;
$medium: 16px;
$large: 18px;
$x-large: 20px;
$xx-large: 24px;
$xxx-large: 32px;

//Font-Weight
$font-thin: 100;
$font-extra-light: 200;
$font-light: 300;
$font-normal: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;
$font-extrabold: 800;
$font-black: 900;

// colors
$primary-color: #50b3f6;
$on-primary-hover: #2078b3;
$box-shadow-primary-color: #c8e9fa;

$danger-red-color: #ef4444;
$danger-red-on-hover: #ed2828;

$success-background: green;
$success-heading: #5cb85c;

$white: #fff;
$white-smoke: #e9e9e9;

// font colors
$light-grey: #f8f9fa;
$light-grey-on-hover: #e9ecef;
$light-grey-border: #dee2e6;

$grey-font-color: #6c757d;
$grey-font-color-on-hover: #343a40;
// $light-grey-hover: #343a40;

$grey-primary-color: #a1a4a9;
$grey-secondary-color: #64748b;
$dark_grayish_blue: #495057;
$secondary-border-color: #cacaca;

// $secondary-color: #4296cf; //this is previous color
// $on-primary-hover-2: #2078b3;
// $on-primary-hover: #4e7ef5;
// newly added - not used
// $text-on-highlight:
// $button-disable-primary-color: #bdc3c7;

$field-width-40: 40%;
$field-width-60: 60%;
$field-width-100: 100%;

$field-margin-bottom-size: 1.5rem;
$button-min-width: 86px;
$dialog-header-padding: 20px 10px 5px 30px;
$dialog-header-message-padding: 20px 0.5rem 0rem 0.5rem;

// padding
$box-label-padding: 0.75rem 1.25rem;
$button-label-padding: 0.5rem 0.75rem;
$icon-button-padding: 0.5rem 1.25rem;
$icon-only-padding: 0.5rem 1.25rem;

//Main-Form-Container
$form-container-border-radius: 10px;
$form-container-padding: 35px 25px 10px;
// $form-container-padding: 45px 30px 10px;
$form-container-box-shadow:
  0 4px 10px rgba(0, 0, 0, 0.03),
  0 0 2px rgba(0, 0, 0, 0.06),
  0 2px 6px rgba(0, 0, 0, 0.12);

//input-fields
$form-field-margin: 2rem;
$form-label: 5px;
$form-button-gap: 12px;
$form-error-message-margin: 2px;
$float-label-margin-top: -12px;
$float-label-margin-left: 4px;

//box-shadow for field
$focus-shadow-color: 0 0 0 0.2rem #50b3f640;
$focus-shadow-danger: 0 0 0 0.2rem #f7a2a280;

//dialog-box -  remove unused instances
$modal-border: 10px;
$modal-header-border-bottom: 2px solid #ccc;
$modal-header-padding: 20px 10px 5px 10px;
$modal-success-heading: 13px;
$modal-content-padding: 26px;

//Form-Variables--Mobile - review them
$modal-mobile-button-container-margin: 44%;
$modal-mobile-width: 95%;

//Table-Variables - Desktop
$table-margin-bottom: 0;
$table-row-padding: 0.75rem 1rem;
$table-row-sm-padding: 0.75rem 0.5rem;
$table-padding: 0.8rem;
$action-header-gap: 12px;

//tiny-editor
$tiny-editor-height: 650;

//Modal Overlay Background Animation
$modal-overlay-animation: p-component-overlay-enter-animation 150ms forwards;

:export {
  primaryColor: $primary-color;
  tinyEditorHeight: $tiny-editor-height;
  trendingNewsDefaultColor: $dark_grayish_blue;
  smallDevice: $device-small;
  fontBold: $font-bold;
  fontNormal: $font-normal;
}
