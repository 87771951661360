@import 'theme.scss';

.list-payment-mapping {
  .search-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 8px;
    gap: $action-header-gap;

    .search-field {
      width: 190px;

      .p-inputtext {
        width: $field-width-100;

        &:hover {
          border: 1px solid $primary-color;
        }

        &:focus {
          border: 1px solid $primary-color;
          box-shadow: $focus-shadow-color;
        }
      }
    }
  }
  .p-datatable .p-datatable-thead > tr > th {
    padding: $table-row-padding;
  }

  .p-datatable .p-datatable-tbody > tr > td {
    padding: $table-row-padding;
  }
  .table-column-heading-active {
    width: 8%;
  }
  .toggle-switch {
    display: flex;
    align-items: center;
    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
      background-color: $primary-color;
    }

    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:hover {
      background-color: $primary-color;
    }
  }
  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    color: $primary-color;
  }
  .p-link:focus {
    box-shadow: $focus-shadow-color;
  }
  .country-column {
    width: 40%;
  }
  .payment-column {
    width: 40%;
  }
  .action-column {
    width: 5%;
  }
}
