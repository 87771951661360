@import 'theme.scss';

.change-password-dialog {
  background-color: $white;
  min-width: 650px;
  max-width: 650px;
  word-wrap: break-word;
  position: absolute;

  .p-dialog-header {
    border-bottom: 1px solid $light-grey-border;
    padding: 16px 10px 8px 24px;
  }

  .change-password-container {
    margin-top: 25px;

    .field {
      margin-bottom: $form-field-margin;

      .password-input {
        border-top-left-radius: 1px;
        border-bottom-left-radius: 1px;
      }
      .p-inputtext:hover {
        border-color: $primary-color;
      }
      .p-inputtext:enabled:focus {
        box-shadow: $focus-shadow-color;
        border-color: $primary-color;
      }
      .p-inputtext.p-invalid.p-component,
      .p-invalid .p-inputtext:hover {
        border-color: $danger-red-color;
      }
      .p-invalid + label,
      .p-float-label > label.p-error + label {
        color: $danger-red-color;
      }
      .p-error {
        margin-left: $float-label-margin-left;
        color: $danger-red-color;
      }
      .mismatch-password-error {
        position: relative;
        left: 4px;
        margin: 0px;
      }
      label {
        left: $form-label;
        margin-left: 5px;
        margin-top: -12px;
      }

      .p-input-icon-right > i:last-of-type {
        z-index: 1;
      }
      .p-inputtext.phone-input:enabled:focus + label,
      .p-filled.phone-input + label,
      .p-inputwrapper-focus.phone-input + label,
      .p-inputwrapper-filled.phone-input + label,
      .p-inputtext:enabled:focus + label,
      .p-filled + label {
        margin-left: -40px;
      }
      .required-star {
        color: $danger-red-color;
        font-weight: $font-medium;
        font-size: $x-large;
        margin: 0px 3px;
      }
    }
    .star-required {
      color: $danger-red-color;
      font-size: $x-large;
      font-weight: $font-medium;
    }
    .current-password {
      width: 48.9%;
    }
    .new-password-container {
      display: flex;

      .new-password {
        width: 95%;
        margin-right: 15px;
      }
      .confirm-password {
        width: 95%;
      }
    }
  }
  .button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .p-button {
      margin-left: $form-button-gap;
    }
  }
}

@media screen and (max-width: $device-small) {
  .change-password-dialog {
    min-width: 92%;
    max-width: 92%;

    .change-password-container {
      .current-password {
        width: 100%;
      }
      .new-password-container {
        display: block;

        .new-password {
          width: 100%;
        }
        .confirm-password {
          width: 100%;
        }
      }
    }
  }
}
