@import 'theme.scss';

.total-certified-stock-modal {
  width: 50vw;
  background-color: $white;
  .p-dialog-content {
    padding-top: 25px;
  }
  .p-link:focus {
    box-shadow: $focus-shadow-color;
  }
  .p-dialog-title {
    text-transform: uppercase;
  }
  .footer-button-container {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }
  .form-wrapper {
    .grid-input {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;

      .required-text-field {
        color: $danger-red-color;
        margin-left: 2px;
        font-size: $x-large;
      }

      .field {
        margin-bottom: $form-field-margin;
        .p-inputtext {
          width: $field-width-100;
        }
        .p-dropdown {
          width: $field-width-100;
        }

        label {
          margin-top: $float-label-margin-top;
          left: $form-label;
          margin-left: $float-label-margin-left;
        }
        .error-label {
          color: $danger-red-color;
        }
        .p-inputtext:enabled:focus {
          box-shadow: $focus-shadow-color;
          border-color: $primary-color;
        }
        .p-inputtext:enabled:hover {
          border-color: $primary-color;
        }
        .p-invalid + .form-fields:enabled:hover,
        .p-invalid-hover {
          border-color: $danger-red-color !important;
        }

        .p-invalid + .form-fields:enabled:hover,
        .p-invalid-hover {
          border-color: $danger-red-color;
        }
        .api-error-message {
          margin-left: $form-error-message-margin;
        }
        .error-message {
          color: $danger-red-color;
          margin-left: $form-error-message-margin;
        }
      }

      .dropdown-wrapper {
        .dropdown-field {
          &:hover {
            border-color: $primary-color;
          }
        }
        .p-dropdown {
          width: $field-width-100;
        }
        label {
          margin-top: $float-label-margin-top;
          left: $form-label;
          margin-left: $float-label-margin-left;
        }
        .p-dropdown:not(.p-disabled).p-focus {
          border: 1px solid $primary-color;
          box-shadow: $focus-shadow-color;
        }
        .p-dropdown.p-invalid.p-component {
          border-color: #e24c4c;
        }
      }
      .calendar-wrapper {
        margin-bottom: $form-field-margin;

        .p-calendar {
          width: 100%;
        }

        .p-calendar-w-btn-right .p-datepicker-trigger {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }

        .p-button.p-button-icon-only {
          width: 3rem;
          padding: 0.75rem 0;
        }

        .p-button-icon-only {
          justify-content: center;
        }
        .p-button {
          margin: 0;
          color: $white;
          background: $primary-color;
          border: 1px solid $primary-color;

          transition:
            background-color 0.2s,
            color 0.2s,
            border-color 0.2s,
            box-shadow 0.2s;
          border-radius: 6px;
        }
        .p-button:focus {
          box-shadow: $focus-shadow-color;
        }
        .p-inputtext:enabled:hover {
          border-color: $primary-color;
        }
        .p-invalid + .form-fields:enabled:hover,
        .p-invalid-hover {
          border-color: $danger-red-color;
        }
        .error-message {
          color: $danger-red-color;
          margin-left: $form-error-message-margin;
        }
        label {
          margin-top: $float-label-margin-top;
          left: $form-label;
          margin-left: $float-label-margin-left;
        }
        .p-invalid .p-inputtext:enabled:hover {
          border-color: $danger-red-color;
        }
        .p-invalid .p-inputtext:enabled:focus {
          border-color: $danger-red-color;
        }
        .p-filled .p-inputtext:enabled:focus {
          border-color: $primary-color;
        }

        .p-button:enabled:hover,
        .p-button:not(button):not(a):not(.p-disabled):hover {
          background-color: $primary-color;
          border: 1px solid $primary-color;
        }

        .p-inputtext:enabled:focus {
          box-shadow: $focus-shadow-color;
          border-color: $primary-color;
        }
      }
    }

    .field-label {
      color: $grey-font-color-on-hover;
      margin-top: -6px;
      margin-left: -4px;
    }
  }
}

@media (max-width: $device-small) {
  .total-certified-stock-modal {
    width: 98%;
    .form-wrapper {
      .grid-input {
        grid-template-columns: 1fr;
        gap: 0px;
      }
    }
  }
}
