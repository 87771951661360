@import 'theme.scss';

.country-selector-dropdown-container {
  .country-selector-dropdown {
    width: 280px;
    height: 43px;
    border-radius: 5px;
    margin-right: 10px;
    display: flex;
    align-items: center;
  }
  .form-component {
    width: $field-width-100;
    height: 46px;
    .dropdown-selector {
      display: flex;
      margin-left: -8px;
      .flag-icon {
        margin: 0px 5px;
        margin-top: -1px;
        height: 23px;
        border-radius: 2px;
        border: 1px solid $light-grey-border;
      }
    }
    .currency-details {
      .currency-code {
        font-size: $x-large;
        margin-top: -1px;
      }
    }
  }

  .p-dropdown:not(.p-disabled):hover,
  .p-dropdown:not(.p-disabled):focus {
    border-color: $primary-color;
  }

  .p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
    height: 43px;
  }
  .p-inputtext:enabled:hover {
    border-color: $primary-color;
  }
  .p-inputtext:enabled:focus {
    box-shadow: $focus-shadow-color;
    border-color: $primary-color;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background-color: $light-grey;
    color: $primary-color;
  }
  .error-message-country {
    color: $danger-red-color;
    margin-left: $form-error-message-margin;
    font-size: $x-small;
    margin-top: 2px;
  }
  .p-error {
    color: $danger-red-color;
  }
}
.dropdown-selector {
  display: flex;
  margin-left: -8px;
  .flag-icon {
    margin: 0px 5px;
    margin-top: 1px;
    height: 23px;
    border-radius: 2px;
    border: 1px solid $light-grey-border;
  }

  .currency-details {
    .currency-code {
      font-size: $x-large;
    }
  }
}
