@import 'theme.scss';

.breadcrumbs-container {
  padding: 0px 22px;
  .breadcrumb-list {
    list-style: none;
    padding: 0 0 0 20px;
    margin: 0;
    display: flex;
    flex-direction: row;
    position: relative;
  }

  .breadcrumb-item {
    display: inline-block;
    margin-left: -16px;
    background: $primary-color;
    border-radius: 10px 50px 50px 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;

    &:first-child {
      z-index: 4;
      border-radius: 10rem;
      box-shadow: 1px 0 1px $on-primary-hover;
      .breadcrumb-link:hover {
        text-decoration: underline;
      }
    }
    &:nth-child(2) {
      z-index: 3;
      box-shadow: 1px 0 2px $on-primary-hover;
    }
    &:nth-child(3) {
      box-shadow: 1 0px 2px $on-primary-hover;
      z-index: 2;
      .breadcrumb-link[href]:hover {
        text-decoration: underline;
      }
    }
    &:last-child {
      box-shadow: 1px 0 2px $light-grey-border;
    }
  }
  .breadcrumb-link {
    text-decoration: none;
    color: $white;
    padding: $box-label-padding;
    display: block;
    font-weight: $font-bold;
    font-size: $x-small;
    text-align: center;
    text-transform: uppercase;
  }
  .breadcrumb-item:last-child .breadcrumb-link {
    color: $primary-color;
  }
  .breadcrumb-item:last-child {
    background: $light-grey;
    border: 1px solid $light-grey-border;
  }

  .breadcrumb-item + .breadcrumb-item::before {
    display: none;
  }
  .breadcrumb-item:first-child {
    z-index: 4;
    border-radius: 10rem;
    box-shadow: 1px 0 1px $on-primary-hover;
    background: $primary-color;
    color: $light-grey;
    border: none;
  }

  .breadcrumb-list:has(> .breadcrumb-item:nth-last-child(2):first-child) .breadcrumb-item:first-child {
    box-shadow: 1px 0 2px $on-primary-hover;
  }

  .breadcrumb-item:first-child .breadcrumb-link {
    color: $white;
  }
}

@media (max-width: $device-small) {
  .breadcrumbs-container {
    padding: 5px 0 0 0;
    .breadcrumb-link {
      text-decoration: none;
      color: $white;
      padding: 10px 18px;
      display: block;
      font-weight: $font-bold;
      font-size: $xxx-small;
      text-align: center;
      text-transform: uppercase;
      width: max-content;
    }

    .breadcrumb-list {
      flex-wrap: wrap;
    }
    .breadcrumb-item {
      margin-right: 5px;
      margin-bottom: 5px;
      margin-left: -20px;
    }
    .breadcrumb-item:last-child {
      border-radius: 12rem;
    }
  }
}
