@import 'theme.scss';

.login-with-otp-form {
  margin-top: 30px;
  position: absolute;
  width: 85%;

  .login-with-text {
    margin: 0px 0px 5px 9px;
    font-size: $x-small;
  }

  .field {
    margin-bottom: $form-field-margin;

    .change-email-address {
      display: flex;
      justify-content: flex-end;
      margin-top: 5px;
      .change-email-text {
        cursor: pointer;
        color: $primary-color;
        font-size: $small;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .p-inputtext {
      height: 40px;
    }

    .p-inputgroup-addon {
      height: 40px;
      padding: 0px;
      width: 20%;
    }

    .p-inputtext:hover {
      border-color: $primary-color;
    }

    .p-inputtext:enabled:focus,
    .p-dropdown:not(.p-disabled).p-focus {
      box-shadow: $focus-shadow-color;
      border-color: $primary-color;
    }

    .p-inputtext:enabled:hover,
    .p-dropdown:not(.p-disabled):hover {
      border-color: $primary-color;
    }

    label {
      margin-top: -12px;
    }
    .label-text-otp {
      left: 5px;
      margin-left: 4px;
    }

    .otp-error {
      color: $danger-red-color;
    }

    .p-inputtext.p-invalid.p-component {
      border-color: $danger-red-color;
    }

    .p-error {
      margin-left: $float-label-margin-left;
      color: $danger-red-color;
    }

    .p-error-message {
      margin-left: $float-label-margin-left;
      color: $danger-red-color;
      left: $form-label;
    }

    .p-button:focus {
      box-shadow: none;
    }

    .required-star {
      color: $danger-red-color;
      font-weight: $font-medium;
      font-size: $x-large;
      margin: 0px 3px;
    }

    .p-inputtext:enabled:focus + .label-text,
    .p-filled + .label-text,
    .p-inputwrapper-focus + .label-text,
    .p-invalid:focus + .p-error,
    .p-inputwrapper-filled + .label-text {
      margin-left: -64px !important;
    }

    .error-message {
      color: $danger-red-color;
    }

    .email-error {
      font-size: $x-small;
      margin-top: 4px;
      margin-left: 4px;
    }
  }

  .otp-field {
    margin: 0.2rem 0px;
  }

  .otp-success-div {
    margin-top: -45px;
    display: flex;
    justify-content: space-between;

    .otp-success-text {
      color: $success-background;
    }

    .otp-resend-text {
      color: $primary-color;
      cursor: pointer;
      text-decoration: underline;
    }

    .otp-timer-text {
      color: $primary-color;
    }
  }

  .login-with-otp-button {
    background-color: $primary-color;
    border-color: $primary-color;
    height: 40px;
  }

  .p-button:enabled:hover {
    background-color: $primary-color;
    border-color: $primary-color;
  }
}
