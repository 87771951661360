@import 'theme.scss';

.weekly-wise-edit-modal {
  width: 70vw;
  background-color: $white;

  .p-dialog-title {
    text-transform: uppercase;
  }
  .p-link:focus {
    box-shadow: $focus-shadow-color;
  }
  .edit-weekly-wise-modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }

  .open-interest-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
    .open-interest-title {
      text-transform: uppercase;
      color: #343a40;
    }
  }

  .edit-form-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    .form-fields {
      width: 100%;
      .input-fields {
        width: 100%;

        &:hover {
          border: 1px solid $primary-color;
        }

        &:focus {
          border: 1px solid $primary-color;
          box-shadow: $focus-shadow-color;
        }

        &.error-fields {
          border-color: $danger-red-color !important;
        }
      }
    }

    .input-label {
      margin-top: $float-label-margin-top;
      left: $form-label;
      margin-left: $float-label-margin-left;
      &.error-label {
        color: $danger-red-color;
      }
    }

    .required-text-field {
      color: $danger-red-color;
      margin-left: 2px;
      font-size: $x-large;
    }
    .error-message {
      color: $danger-red-color;
      margin-left: $form-error-message-margin;
    }
  }

  .category-form-container {
    .form-title {
      text-transform: uppercase;
      color: #343a40;
      padding-top: 12px;
    }

    .form-card {
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      .form-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;

        .form-fields {
          margin-bottom: 2.5rem;
          .input-fields {
            width: 100%;

            &:hover {
              border: 1px solid $primary-color;
            }

            &:focus {
              border: 1px solid $primary-color;
              box-shadow: $focus-shadow-color;
            }
            &.error-fields {
              border-color: $danger-red-color !important;
            }
          }

          .required-text-field {
            color: $danger-red-color;
            margin-left: 2px;
            font-size: $x-large;
          }

          .input-label {
            margin-top: $float-label-margin-top;
            left: $form-label;
            margin-left: $float-label-margin-left;

            &.error-label {
              color: $danger-red-color;
            }
          }
          .error-message {
            color: $danger-red-color;
            margin-left: $form-error-message-margin;
          }
        }
      }
    }
  }

  .p-card .p-card-body {
    padding: 2rem 1.25rem 0rem;
  }
}
