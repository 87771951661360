@import 'theme.scss';

.client-menu-wrapper {
  .client-menu-table-container {
    .p-datatable .p-column-header-content {
      display: flex;
      justify-content: center;
    }

    .p-datatable .p-datatable-thead > tr > th {
      padding: $table-row-padding;
    }

    .p-datatable .p-datatable-tbody > tr > td {
      padding: $table-row-padding;
    }

    .search-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;

      .p-inputtext:enabled:hover {
        border-color: $primary-color;
      }

      .p-inputtext:enabled:focus {
        border-color: $primary-color;
        box-shadow: 0 0 0 0.2rem $box-shadow-primary-color;
      }

      .add-button {
        display: flex;
        align-items: center;
      }
    }

    .client-menu-table {
      width: 100%;
      margin-bottom: $table-margin-bottom;

      .menu-name {
        width: 25%;

        div {
          justify-content: flex-start;
        }
      }

      .guest-display {
        width: 13%;
        text-align: center;
      }

      .web-display {
        width: 13%;
        text-align: center;
      }

      .is-active {
        width: 13%;
        text-align: center;
      }

      .is-launch {
        width: 13%;
        text-align: center;
      }

      .country-based {
        width: 13%;
        text-align: center;
      }

      .action-container {
        display: flex;
        justify-content: center;

        .config-button {
          background-color: $white;
          border: none;
          outline: none;
          color: $primary-color;
          padding: 0;

          &:hover {
            background-color: $white;
            color: $primary-color;
          }
        }

        .config-button:enabled:active {
          background: none;
          color: $primary-color;
          border-color: none;
        }

        .config-button:focus {
          box-shadow: none;
        }
      }

      .toggle-switch-menu {
        display: flex;
        justify-content: center;

        .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
          background-color: $primary-color;
        }

        .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:hover {
          background-color: $primary-color;
        }

        .p-inputswitch.p-focus .p-inputswitch-slider {
          box-shadow: 0 0 0 0.2rem $box-shadow-primary-color;
        }
      }

      .edit-button {
        background-color: $white;
        border: none;
        outline: none;
        color: $primary-color;
        padding: 0;

        &:hover {
          background-color: $white;
          color: $primary-color;
          border: none;
        }

        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}

.multi-select {
  &.dialog-component {
    min-width: 440px;
    max-width: 440px;
    min-height: 18%;
    background-color: $white;
  }

  .dialog-message {
    padding: 0 0 16px;
  }

  .dialog-component-footer {
    display: flex;
    justify-content: flex-end;

    div {
      &:first-child {
        margin-right: 12px;
      }
    }
  }

  .p-multiselect {
    width: 100%;
    margin-bottom: 16px;

    &:hover {
      border-color: #50b3f6;
    }
  }
}
