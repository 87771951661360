@import 'theme.scss';

.news-user-preview-container {
  user-select: none;
  display: grid;
  grid-template-columns: 360px auto;
  gap: 10px;
  .user-preview-image-wrapper {
    width: 100%;
    .go-back-button {
      background-color: $primary-color;
      border-color: $primary-color;
    }
    .go-back-button:focus {
      box-shadow:
        0 0 0 2px $white,
        0 0 0 4px $primary-color,
        0 1px 2px 0 rgb(0, 0, 0);
    }
    .p-selectbutton .p-button.p-highlight {
      background-color: $primary-color;
      border-color: $primary-color;
    }
    .p-selectbutton .p-button.p-highlight:hover {
      background-color: $primary-color;
      border-color: $primary-color;
    }
    .user-preview-action-button {
      position: sticky;
      top: 0;
      display: flex;
      justify-content: space-around;
      gap: 10px;
      background-color: $white;
    }
    .user-preview-images {
      height: 67vh;
      margin-top: 10px;
      border-radius: 10px;
      overflow-y: auto;
      box-shadow: $grey-primary-color 0px 0px 5px;
      padding: 10px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      .images-wrapper {
        img {
          width: 100%;
          border-radius: 10px;
        }
      }
      video {
        width: 100%;
        border-radius: 10px;
        height: 200px;
      }
    }
  }
  .user-preview-content-wrapper {
    width: 100%;
    .content-banner {
      width: 100%;
      position: sticky;
      top: 0;
      border-radius: 10px;
      box-shadow: $grey-primary-color 0px 0px 5px;
      padding: 10px;
      background-color: $white;
      .news-date-and-time {
        display: grid;
        grid-template-columns: auto 100px;
      }
      .news-subject {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 10px 0px;
        font-size: 25px;
        color: $primary-color;
      }
      .news-author-and-view {
        display: grid;
        grid-template-columns: auto 100px;
      }
    }
    .document-view-button {
      padding: 10px 1px;
      display: flex;
      justify-content: flex-end;
      .view-button {
        background-color: $primary-color;
        border-color: $primary-color;
      }
      .view-button:focus {
        box-shadow:
          0 0 0 2px $white,
          0 0 0 4px $box-shadow-primary-color,
          0 1px 2px 0 rgb(0, 0, 0);
      }
    }
    .content-description-wrapper {
      width: 100%;
      padding: 10px;
      height: 50vh;
      overflow-y: auto;
    }
  }
  i {
    color: $primary-color;
  }
  .p-button:focus {
    box-shadow:
      0 0 0 2px $white,
      0 0 0 4px $box-shadow-primary-color,
      0 1px 2px 0 rgb(0, 0, 0);
  }
}

.reject-remark-dialog {
  background-color: $white;
  width: 50vw;
  .reject-remark-dialog-footer {
    .cancel-button {
      color: $primary-color;
      border: 1px solid $primary-color;
      border-color: $primary-color;
      background-color: $white;
      min-width: $button-min-width;
      padding: $button-label-padding;
    }
    .submit-button {
      color: $white;
      background-color: $primary-color;
      border-color: $primary-color;
      min-width: $button-min-width;
      padding: $button-label-padding;
    }
  }
  .reject-remark-input {
    width: 100%;
  }
  .p-inputtext:enabled:hover {
    border-color: $primary-color;
  }
}

.news-approve-dialog {
  background-color: $white;
  .p-dialog-header {
    border-bottom: 1px solid $light-grey-border;
    padding: 12px 10px 5px 24px;
  }
  .dialog-content-message {
    padding-top: 12px;
    font-size: $large;
    color: $grey-font-color-on-hover;
  }
  .news-approve-dialog-footer {
    .neutral-button {
      color: $primary-color;
      border: 1px solid $primary-color;
      border-color: $primary-color;
      background-color: $white;
      min-width: 98px;
      padding: $button-label-padding;
    }
    .neutral-button:focus {
      box-shadow:
        0 0 0 2px $white,
        0 0 0 4px $primary-color,
        0 1px 2px 0 $box-shadow-primary-color;
    }

    .neutral-button:hover {
      color: $on-primary-hover;
      border-color: $on-primary-hover;
      background-color: $white;
    }
    .confirm-action-button {
      color: $white;
      background-color: $primary-color;
      border-color: $primary-color;
      min-width: 98px;
      padding: $button-label-padding;
    }
    .confirm-action-button:hover {
      color: $white;
      background-color: $on-primary-hover;
      border-color: $on-primary-hover;
    }

    .confirm-action-button:focus {
      box-shadow:
        0 0 0 2px $white,
        0 0 0 4px $primary-color,
        0 1px 2px 0 $box-shadow-primary-color;
    }
  }
  .check-box-container {
    margin: 12px;

    .p-checkbox .p-checkbox-box.p-highlight {
      border-color: $primary-color;
      background: $primary-color;
    }
    .p-checkbox .p-checkbox-box.p-highlight:hover {
      border-color: $primary-color;
      background: $primary-color;
    }
    .p-checkbox .p-checkbox-box:hover {
      border-color: $primary-color;
    }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
      border-color: $primary-color;
      box-shadow: $focus-shadow-color;
    }

    .notification-label {
      padding: 12px;
    }
  }
}

.news-notification-dialog {
  width: 30rem;
  height: 12rem;
  .p-dialog-header {
    border-bottom: 1px solid $light-grey-border;
    padding: 12px 10px 5px 24px;
  }
  .dialog-content-message {
    font-weight: $font-bold;
    padding-top: 18px;
    font-size: $xx-large;
    color: $grey-font-color-on-hover;
  }
  .notification-spinner {
    display: flex;
    justify-content: center;
    width: 50px;
    height: 50px;
    animation-duration: 0.5s;
  }
  // .p-dialog .p-dialog-content {
  //   padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  // }
}

@media (max-width: $device-small) {
  .news-user-preview-container {
    grid-template-columns: 1fr;
    .user-preview-image-wrapper {
      .user-preview-images {
        height: 250px;
      }
      .user-preview-action-button {
        top: -6px;
      }
    }
    .user-preview-content-wrapper {
      .content-banner {
        top: -5px;
      }
    }
  }
  .reject-remark-dialog {
    width: 90vw;
  }
}
