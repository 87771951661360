@import 'theme.scss';

.country-code-selector {
  margin-top: $float-label-margin-top;

  .phone-field {
    display: flex;
    margin-top: 28px;
  }

  .country-field {
    width: $field-width-100;
    margin-top: 45px;
    margin-bottom: $form-field-margin;
  }

  .phone-input-field {
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
  }

  .phone-field-label,
  .country-phone-field {
    width: $field-width-100;
  }

  .country-dropdown {
    border-color: $light-grey-border;
    width: $field-width-100;
  }

  .required-field {
    color: $danger-red-color;
    font-size: $x-large;
    margin-left: $form-error-message-margin;
  }

  .error-message {
    color: $danger-red-color;
    margin-left: 2px;
    font-size: $small;
    font-weight: $font-normal;
    margin-top: 2px;
  }

  .country-dropdown:hover {
    border-color: $primary-color;
  }

  .p-dropdown:not(.p-disabled).p-focus {
    box-shadow: $focus-shadow-color;
  }

  .custom-dropdown {
    position: relative;
    display: inline-block;
    width: $field-width-100;
  }

  .p-float-label label {
    position: absolute;
    pointer-events: none;
    margin-top: $float-label-margin-top;
    transition-property: all;
    transition-timing-function: ease;
    margin-left: $float-label-margin-left;
    font-weight: $font-normal;
  }

  label {
    left: $form-label;
    color: $grey-font-color-on-hover;
    font-weight: $font-extra-light;
  }

  .p-error {
    margin-left: $float-label-margin-left;
    color: $danger-red-color;
  }

  .p-inputtext.p-invalid.p-component {
    border-color: $danger-red-color;
  }

  .p-invalid + .p-inputtext:enabled:hover,
  .p-invalid-hover {
    border-color: $danger-red-color;
  }

  .country-dropdown + .p-inputtext:enabled:hover,
  .p-invalid-hover {
    border-color: $danger-red-color;
  }

  .country-dropdown.p-invalid:focus + .p-inputtext,
  .p-invalid-hover:focus {
    border-color: $primary-color;
  }

  .country-phone-field:disabled {
    cursor: not-allowed;
    background-color: $white;
  }
  .p-inputtext:enabled:focus + label {
    margin-left: -46px;
    margin-top: -12px;
  }
  .p-filled + label {
    margin-left: -46px;
    margin-top: -12px;
  }
}
.p-inputtext:enabled:focus {
  border-color: $primary-color;
  box-shadow: $box-shadow-primary-color;
}
@media screen and (max-width: $device-small) {
  .country-code-selector {
    .p-inputgroup-addon {
      padding: $box-label-padding;
      min-width: 4rem;
    }
    .p-inputtext:enabled:focus + label {
      margin-left: -60px;
    }
    .p-filled + label {
      margin-left: -60px !important;
    }
    .phone-input-field + label {
      margin-left: 0;
    }
  }
}
