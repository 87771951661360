@import 'theme.scss';

.role-features-master {
  .role-features-content {
    border-radius: $modal-border;
    margin-bottom: $field-margin-bottom-size;
    padding: $form-container-padding;
    box-shadow: $form-container-box-shadow;

    .role-features-subcontinent {
      margin: auto;
      flex-wrap: wrap;
      justify-content: center;
      gap: 30px;

      .role-features-dropdown-role {
        width: $field-width-60;
        margin-bottom: $form-field-margin;

        .error-text {
          margin-left: $float-label-margin-left;
          color: $danger-red-color;
        }

        label {
          margin-top: $float-label-margin-top;
          left: $form-label;
          margin-left: $float-label-margin-left;
          color: $grey-font-color-on-hover;
        }

        .p-dropdown:not(.p-disabled).p-focus {
          box-shadow: $focus-shadow-color;
        }

        .p-dropdown:not(.p-disabled):hover {
          border-color: $primary-color;
        }

        .role-feature-error-message {
          color: $danger-red-color;
          font-size: $small;
          margin-left: 3px;
        }
      }

      .dropdown-field {
        width: $field-width-100;
      }

      .error-border {
        border-color: $danger-red-color;
      }

      .p-dropdown.error-border:hover,
      .p-dropdown.error-border:focus {
        border-color: $danger-red-color !important;
      }

      .p-dropdown:not(.p-disabled).p-focus {
        border-color: $primary-color;
        box-shadow: 0 0 0 0.2rem $box-shadow-primary-color;
      }

      .p-dropdown.p-invalid.p-component:hover {
        border-color: $danger-red-color;
      }

      .p-error {
        margin-left: $form-error-message-margin;
      }

      .required-field {
        color: $danger-red-color;
        font-weight: $font-medium;
        font-size: $x-large;
        margin: 3px;
      }

      .role-screen-list {
        margin-left: 9px;
        font-size: 12px;
        color: $grey-font-color-on-hover;
        margin-bottom: 2px;
      }

      .role-features-role-screen {
        border-radius: 8px;
        padding: 20px;
        width: $field-width-60;
        margin-bottom: 2rem;
        box-shadow: $form-container-box-shadow;

        .role-features-role-data {
          height: 310px;
          overflow-y: scroll;

          .role-features-menu-names {
            display: flex;
            gap: 7px;
            margin-left: 5px;
            margin-top: 15px;

            .p-checkbox .p-checkbox-box.p-highlight {
              border-color: $primary-color;
              background: $primary-color;
            }

            .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
              border-color: $primary-color;
            }

            .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:focus {
              border-color: $primary-color;
            }

            .p-checkbox,
            .p-focus {
              outline: 0 none;
              outline-offset: 0;
            }

            .p-focus {
              border-color: $primary-color;
              box-shadow: $focus-shadow-color;
            }
          }
        }
      }
    }
  }

  .roles-feature-button-container {
    display: flex;
    justify-content: flex-end;
  }
}

@media screen and (max-width: $device-small) {
  .role-features-master .role-features-content .role-features-subcontinent .role-features-dropdown-role {
    width: $field-width-100;
  }

  .role-features-master .role-features-content .role-features-subcontinent .role-features-role-screen {
    width: $field-width-100;
  }
}
