@import 'theme.scss';

.body-container {
  margin: auto;
  height: auto;
  overflow: hidden;
  border-radius: 4px;
  margin-top: 12px;
  position: relative;

  .heading-action-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 22px 0;
    align-items: center;

    .body-heading {
      color: $grey-font-color-on-hover;
      font-size: $xx-large;
      font-weight: $font-semibold;
      width: 40%;
    }

    .body-heading-full {
      color: $grey-font-color-on-hover;
      font-size: $xx-large;
      font-weight: $font-semibold;
      width: 100%;
    }

    .heading-button-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 60%;
      gap: 1rem;

      .edit-button,
      .add-button {
        background-color: $primary-color;
        border: 1px solid $primary-color;
        border-radius: 5px;
        padding: $button-label-padding;
        cursor: pointer;
        transition: background-color 0.3s;
        box-shadow: none;
      }

      .edit-button:hover,
      .add-button:hover {
        background: $on-primary-hover;
        border-color: $on-primary-hover;
      }
    }
  }

  .body-content {
    height: 75vh;
    width: 100%;
    margin: auto;
    padding: 5px 22px;
    overflow-y: auto;
  }

  .edit-icon-button,
  .add-icon-button {
    display: none;
    border-radius: 5px;
    width: 2rem;
    height: 2rem;
    transition: background-color 0.3s;
    cursor: pointer;
  }
}

@media (max-width: $device-medium) {
  .body-container {
    margin-top: 0;
    padding-left: 12px;
    padding-right: 12px;

    .heading-action-wrapper {
      padding: 1rem 2px;

      .body-heading {
        font-size: $large;
      }

      .body-heading-full {
        font-size: $large;
        width: 100%;
      }

      .heading-button-container {
        padding-left: 0;
        padding-right: 0;
        gap: 4px;

        .edit-button,
        .add-button {
          display: none;
        }

        .edit-icon-button,
        .add-icon-button {
          display: flex;
          background: $primary-color;
          border: 1px solid $primary-color;
        }

        .edit-icon-button:hover,
        .add-icon-button:hover {
          background: $on-primary-hover;
          border: 1px solid $on-primary-hover;
        }
      }
    }

    .body-content {
      overflow-y: scroll;
      padding: 6px 2px 8px 2px;
      height: 72vh;
    }
  }
}
