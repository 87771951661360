@import 'theme.scss';

.payment-report-container {
  .payment-report-table-wrapper {
    .p-inputtext {
      border-color: $primary-color;
      border-bottom-right-radius: 0px;
      border-top-right-radius: 0px;
      border-right: none;
      width: 200px;
      height: 45px;

      &:hover {
        border-color: $primary-color;
      }

      &:enabled:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none !important;
      }
    }

    .p-datatable .p-datatable-thead > tr > th {
      padding: $table-row-padding;
    }

    .p-datatable .p-datatable-tbody > tr > td {
      padding: $table-row-padding;
    }

    .p-link:focus {
      box-shadow: 0 0 0 0.2rem $box-shadow-primary-color;
    }

    .search-input-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      .filter-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 15px 10px;
      }

      .search-field-wrapper {
        .search-input-field {
          &:hover {
            border-color: $primary-color;
          }

          &:focus {
            border-color: $primary-color;
            box-shadow: none !important;
          }
        }
      }

      .drop-down-wrapper {
        display: flex;

        .subscription-multi-select {
          border: 1px solid $primary-color;
          border-bottom-right-radius: 0px;
          border-top-right-radius: 0px;
          border-right: none;
          height: 45px;
          width: 200px;
        }
      }

      .p-dropdown {
        border: 1px solid $primary-color;
        height: 45px;
      }

      .field-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 45px;
        border: 1px solid $primary-color;
        border-left: none;
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
        color: $primary-color;
        padding: 0px 15px;
        cursor: pointer;
      }

      .payment-status-dropdown {
        width: 200px;
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
        border-right: none;

        &:hover {
          border-color: $primary-color;
        }
      }

      .drop-down-field {
        width: 250px;

        &:hover {
          border-color: $primary-color;
        }
      }

      .p-dropdown:not(.p-disabled).p-focus {
        border-color: none;
        box-shadow: none !important;
      }
    }

    .payment-report-table {
      width: 100%;

      .invoice-number-clickable {
        width: 15%;
        cursor: pointer;

        &:hover {
          color: $primary-color;
          text-decoration: underline;
          font-weight: bold;
          cursor: pointer;
        }
      }

      .order-id-column {
        width: 7%;
      }

      .invoice-number-column {
        width: 10%;
      }

      .subscription-name-column {
        width: 11%;
      }

      .total-amount-column {
        width: 7%;
      }

      .payment-status-column {
        width: 9%;
      }

      .payment-source-column {
        width: 13%;
      }

      .payment-date-column {
        width: 17%;
      }

      .payment-id-column {
        width: 6%;
      }

      .reconcile-column {
        width: 4%;
      }
    }
  }

  .pi-edit-icon {
    border: none;
    background-color: $white;
    color: $primary-color;

    &:hover {
      background-color: $white;
      color: $on-primary-hover;
      border: none;
    }

    &:focus {
      box-shadow: none;
      background-color: $white;
      color: $on-primary-hover;
    }
  }
}

.download-report-button-container {
  display: flex;
  justify-content: flex-end;
}

.payment-dropdown-panel {
  .p-multiselect-items-wrapper {
    max-height: 350px !important;
  }

  .p-multiselect-item.p-highlight {
    color: $primary-color !important;
    background-color: #f8f9fa !important;
    margin: 2px 0px;
  }

  .p-multiselect-item.p-highlight:focus {
    box-shadow: none !important;
  }

  .p-multiselect-items {
    padding: 0px !important;
  }
}

.invoice-dialog-modal {
  width: 65%;

  .invoice-header-container {
    display: flex;
    justify-content: space-between;

    .invoice-button {
      margin-right: 40px;
    }
  }
  .p-dialog-content {
    padding: 0px 0px 40px !important;
  }
}

.p-datepicker table td.p-datepicker-today > span.p-highlight,
.p-datepicker table td > span.p-highlight {
  color: $primary-color;
  background-color: $light-grey;
}

.p-paginator-page.p-highlight {
  color: $primary-color !important;
  background-color: $light-grey !important;
  border-color: $primary-color !important;
}

.p-link:focus {
  box-shadow: 0 0 0 0.2rem $box-shadow-primary-color;
}

@media ((max-width: 1368px)and (min-height: 1024px)) {
  .payment-report-container {
    .payment-report-table-wrapper {
      .search-input-container {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 10px;

        .filter-container {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 10px;
          order: 2;
          grid-column: 1;
        }

        .search-field-wrapper {
          .search-input-field {
            width: 100%;
          }
        }

        .drop-down-field {
          width: 250px;
        }
      }
    }
  }
}

@media (max-width: $device-small) {
  .payment-report-container {
    .payment-report-table-wrapper {
      .subscription-multi-select,
      .drop-down-wrapper,
      .payment-status-dropdown,
      .p-inputtext {
        width: 120px !important;
      }

      .search-input-container {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 10px;

        .filter-container {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 10px;
          order: 2;
          grid-column: 1;
        }

        .drop-down-field {
          width: 168px;
        }
      }
    }
  }
}
