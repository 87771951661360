@import 'theme.scss';

.footer {
  background: #f2f1f1;
  position: fixed;
  bottom: 0;
  width: -webkit-fill-available;
  font-size: 14px;
  z-index: 200;
  text-align: center;
  .footer-container {
    padding: 0.5rem;
  }
}
@media (max-width: $device-medium) {
  .footer {
    margin-left: -12px;
  }
}
