@import 'theme.scss';

.edit-menu {
  border-radius: $form-container-border-radius;
  padding: $form-container-padding;
  box-shadow: $form-container-box-shadow;
  .dropdown {
    display: flex;
    align-items: center;
  }
  .error-message {
    margin-left: 4px;
    color: $danger-red-color;
  }

  .p-float-label label {
    position: absolute;
    pointer-events: none;
    margin-top: $float-label-margin-top;
    transition-property: all;
    transition-timing-function: ease;
    margin-left: $float-label-margin-left;
    font-weight: $font-normal;
    color: $grey-font-color-on-hover;
  }

  label {
    left: $form-label;
  }

  .star-required {
    color: $danger-red-color;
    font-size: $x-large;
    font-weight: $font-medium;
  }

  .field {
    margin-bottom: $form-field-margin;
  }
  .p-inputtext {
    width: $field-width-60;
    &:hover {
      border-color: $primary-color;
    }

    &:focus {
      border-color: $primary-color;
      box-shadow: 0 0 0 0.2rem $box-shadow-primary-color;
    }
  }

  .p-inputtext.p-invalid:enabled:hover {
    border-color: $danger-red-color;
  }
  .p-inputtext.p-invalid:enabled:focus {
    border-color: $danger-red-color;
    box-shadow: none;
  }

  .file-upload {
    width: $field-width-60;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .p-dropdown {
    width: $field-width-60;

    &:hover {
      border-color: $primary-color;
    }

    &:focus {
      $focus-shadow-color: 0 0 0 0.2rem #50b3f640;
    }

    .p-dropdown-label.p-placeholder {
      opacity: initial;
    }
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    gap: $form-button-gap;
  }

  .error-label {
    color: $danger-red-color !important;
  }

  .menu-upload {
    margin-left: 10px;
    margin-bottom: -2px;
    font-size: $x-small;
    font-weight: $font-normal;
    color: $grey-font-color-on-hover;
  }

  .p-fileupload .p-fileupload-buttonbar {
    .p-button {
      background: $primary-color;
      border: none;
      padding: $button-label-padding;
      margin-left: -10px;

      &:hover {
        background: $on-primary-hover;
      }
    }
  }
  .error-message {
    color: $danger-red-color;
  }

  .p-error {
    color: $danger-red-color;
    border-color: $danger-red-color;
  }
  .p-float-label {
    width: $field-width-100;
  }
  .image-file {
    display: flex;
    justify-content: center;
    .edit-image-container {
      display: flex;
      justify-content: center;
      border-radius: 7px;
      width: 150px;
      height: 150px;
      padding: 5px;
      .cancel-action {
        display: flex;
        justify-content: center;
        position: absolute;
        right: 18px;
      }
    }
  }
  .edit-file-upload {
    background: #ffffff;
    padding: 0.7rem;
    border: 1px solid #dee2e6;
    color: #495057;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    position: relative;
  }

  .p-dropdown:not(.p-disabled).p-focus {
    box-shadow: $focus-shadow-color;
    border-color: $primary-color;
  }
  .p-inputtext:enabled:hover,
  .p-dropdown:not(.p-disabled):hover {
    border-color: $primary-color;
  }
}

@media screen and (max-width: $device-small) {
  .edit-menu {
    .p-dropdown {
      width: $field-width-100;
    }
    .p-inputtext {
      width: $field-width-100;
    }
    .file-upload {
      width: $field-width-100;
    }
    .p-button.p-button-icon-only.p-button-rounded {
      margin-right: -12px;
      width: 30px;
      height: 30px;
    }
  }
}
