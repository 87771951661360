@import 'theme.scss';

.settings-container {
  .app-settings {
    border-radius: $form-container-border-radius;
    padding: 12px 0px 5px;
    .grid-input {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }

    .field {
      margin-bottom: $form-field-margin;
    }

    label {
      left: $form-label;
    }

    .p-float-label label {
      position: absolute;
      margin-top: $float-label-margin-top;
      margin-left: $float-label-margin-left;
      font-weight: $font-normal;
      color: $grey-font-color-on-hover;
    }
    .p-fluid {
      width: 100%;
    }

    .p-fluid .p-inputtext {
      width: 100%;

      &:focus {
        border-color: $primary-color;
        box-shadow: 0 0 0 0.2rem $box-shadow-primary-color;
      }
    }
    .star {
      color: $danger-red-color;
      font-size: $x-large;
      font-weight: $font-medium;
    }
    .star-required {
      color: $danger-red-color;
      visibility: hidden;
    }

    .p-inputtextarea-resizable {
      width: 100%;
    }

    .error-message {
      margin-left: $form-error-message-margin;
      color: $danger-red-color;
      font-size: $small;
    }
    .error-label {
      color: $danger-red-color !important;
    }
  }
  .app-settings-button-container {
    display: flex;
    justify-content: flex-end;
    .app-settings-buttons {
      display: flex;
      justify-content: flex-end;
      gap: 20px;
      margin-top: -12px;
      padding: 0px 0px 12px 0px;
    }
  }
  .edit-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .p-button:focus {
      box-shadow: $focus-shadow-color;
    }
  }
  .p-inputtext:enabled:hover {
    border-color: $primary-color;
  }

  .p-inputtext.p-invalid:enabled:hover {
    border-color: $danger-red-color;
  }
}

@media (max-width: 767px) {
  .settings-container {
    margin-top: 0px;
    .app-settings {
      padding: 10px;
      .grid-input {
        grid-template-columns: 1fr;
        gap: 0;
      }
      .p-fluid {
        width: $field-width-100;
      }

      .star-required {
        color: $danger-red-color;
        visibility: hidden;
      }
      .star {
        color: $danger-red-color;
        font-size: $x-large;
        font-weight: $font-medium;
      }

      .app-settings-buttons {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        margin-top: -12px;
        padding: 0px 0px 12px 0px;
      }
    }

    .edit-container {
      margin-right: 12px;
      margin-top: 10px;
    }
  }
}
