@import 'theme.scss';

body {
  font-family: sans-serif;
}

element.style {
  position: relative;
  top: 0px;
}

.App {
  text-align: center;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
div {
  font-family: 'Roboto', 'Arial', sans-serif;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: $light-grey-border;
  border-style: solid;
  border-width: 0;
}

.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
  vertical-align: middle;
}

.table thead th {
  vertical-align: middle;
  border-bottom: 2px solid $light-grey-border;
  background-color: $primary-color;
  color: $white;
}

.navbar-brand {
  margin-top: 9px;
}

// This Code is on hold currently
.primary-buttom-style {
  background-color: $primary-color;
  border: 1px solid $primary-color;
  transition: all 0.1s;
  padding: 0.5rem 0.75rem !important;
  overflow: visible !important;
  &:focus {
    box-shadow:
      0 0 0 2px $white,
      0 0 0 4px #50b3f640,
      0 1px 2px 0 rgb(0, 0, 0);
  }
  &:hover {
    background-color: $on-primary-hover !important;
    border-color: $on-primary-hover !important;
  }
  &:active {
    transform: scale(0.95);
  }
}
.secondary-buttom-style {
  background-color: $white;
  border: 1px solid $primary-color;
  transition: all 0.1s;
  padding: 0.5rem 0.8rem;
  color: $primary-color;
  &:focus {
    box-shadow:
      0 0 0 2px $white,
      0 0 0 4px $focus-shadow-color,
      0 1px 2px 0 rgb(0, 0, 0);
  }
  &:hover {
    background-color: $on-primary-hover;
    border-color: $on-primary-hover;
  }
  &:active {
    transform: scale(0.95);
  }
}

.bold_and_green {
  color: $success-background;
  font-weight: $font-semibold;
}

.bold_and_red {
  color: $danger-red-color;
  font-weight: $font-semibold;
}

.bold_and_black {
  color: black;
  font-weight: $font-semibold;
}

.bold_and_dark_gray {
  color: gray;
  font-weight: $font-semibold;
}

.expired {
  color: #c5c5c5;
}
