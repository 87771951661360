@import 'theme.scss';

.global-raw-coffee-price-data-preview-modal-popup {
  .p-dialog-header {
    padding: 0.65rem 1rem;
    border-bottom: 1px solid lightgrey;
  }
  .p-dialog-content {
    padding: 0.65rem;
    .add-button-wrapper {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;
    }
    .preview-table-wrapper {
      max-height: 60vh;
      .p-datatable-wrapper {
        border-bottom: 1px solid lightgray;
        .p-datatable-table {
          overflow: auto !important;
          margin-top: -1px;
          border: 1px solid lightgray;
          overflow: hidden;
          .p-datatable-thead {
            tr th {
              padding: 12px 7px;
              border-top: 1px solid lightgray;
              font-size: 13px;
            }
          }
          .p-datatable-tbody {
            tr {
              height: 45px !important;
              td {
                padding: 8px 7px;
                font-size: 13px;
              }
            }

            .rowReorder {
              width: 35px;
              padding-left: 10px;
            }
            .p-button {
              background-color: $box-shadow-primary-color;
              border: none;
              background-color: $white;
              color: $primary-color;
              padding: 0px;
              width: 25px;
              &:focus {
                box-shadow: none;
              }
            }
            .p-dropdown {
              width: 100px !important;
            }
            .p-dropdown-label {
              padding: 4px 8px;
              font-size: 13px;
            }
            .p-dropdown-trigger {
              width: 28px;
              .p-icon {
                height: 12px;
                width: 12px;
              }
            }
            input {
              padding: 4px;
              max-width: 80px;
              font-size: 13px;
            }
            .p-datatable-emptymessage {
              td {
                text-align: center;
                padding: 15px;
              }
            }
          }
        }
      }
    }
  }
  .p-dialog-footer {
    padding: 0.65rem;
    display: flex;
    justify-content: flex-end;
    button {
      margin: 0px;
    }
  }
}
