@import 'theme.scss';

.subscription-feature-container {
  box-shadow: $form-container-box-shadow;
  border-radius: $form-container-border-radius;
  padding: 18px;

  .subscription-feature-container-head {
    display: flex;
    justify-content: space-between;
    .feature-heading {
      font-size: $x-large;
      font-weight: $font-bold;
      margin-left: 12px;
    }
  }
  .subscription-feature-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    padding: 12px;
    .feature-content-data {
      display: flex;
      gap: 8px;
      margin-bottom: 10px;
      align-items: center;
      .check-box-container {
        .p-checkbox .p-checkbox-box.p-highlight {
          border-color: $primary-color;
          background: $primary-color;
        }

        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
          border-color: $primary-color;
        }

        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:focus {
          border-color: $primary-color;
        }

        .p-checkbox,
        .p-focus {
          outline: 0 none;
          outline-offset: 0;
        }

        .p-focus {
          border-color: $primary-color;
          box-shadow: $focus-shadow-color;
        }
      }
    }
  }
  .feature-button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

@media screen and (max-width: $device-small) {
  .subscription-feature-container {
    .subscription-feature-wrapper {
      display: block;
    }
  }
}
