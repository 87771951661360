@import 'theme.scss';

.delete-tab-container {
  display: flex;
  align-items: center;
  flex-direction: column;

  .card {
    display: block;
    width: 100%;
    margin-bottom: 24px;

    .p-card .p-card-body {
      padding: $form-container-padding;
      height: auto;
      max-height: 400px;

      .p-card-content {
        padding: 0 0;
      }
    }

    .card-title {
      font-size: $large;
      font-weight: $font-semibold;
      margin-bottom: 0;
    }

    .card-content {
      .delete-button-container {
        display: flex;
        justify-content: flex-end;
        margin-top: 16px;

        .delete-button {
          background-color: $danger-red-color;
          border-color: $danger-red-color;
          padding: $button-label-padding;
        }

        .delete-button:hover {
          background-color: $danger-red-on-hover;
          border-color: $danger-red-on-hover;
        }

        .delete-button:focus {
          box-shadow: $focus-shadow-danger;
        }
      }
    }
  }

  .card:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: $device-medium) {
  .delete-tab-container {
    margin: auto;
    padding: 24px;

    .card {
      width: 100%;
    }
  }
}
