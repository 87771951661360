@import 'theme.scss';
.dialog-image-component {
  max-width: 40vw;
  .p-dialog-header {
    border-bottom: 1px solid #dee2e6;
    padding: 8px 10px 8px 24px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

    .p-dialog-header-icon:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: $focus-shadow-color;
    }
  }
  .thumbnail-modal {
    img {
      width: 100%;
    }

    .default-img {
      padding: 0.5rem;
    }

    .grid {
      display: grid;
      margin-top: 25px;
      margin-left: -8px;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 10px;
    }

    .or-text {
      text-align: center;
      margin: 20px 0;
      font-size: 20px;
      font-weight: bold;
      color: #3498db;
      position: relative;
    }

    .or-text::before,
    .or-text::after {
      content: '';
      display: block;
      height: 2px;
      width: 45%;
      background-color: $primary-color;
      position: absolute;
      top: 50%;
    }

    .or-text::before {
      left: 0;
    }

    .or-text::after {
      right: 0;
    }

    .custom-upload-btn {
      display: none;
    }
    .default-image {
      border: 1px solid $light-grey-border;
    }

    .selected-image {
      border: 2px solid $primary-color;
    }

    .file-preview-conatiner {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .file-preview {
        display: flex;
        align-items: center;
        width: 30%;
      }
    }

    .header-modal-image {
      background-color: transparent;
      display: flex;
      align-items: center;
    }
    .empty-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      .empty-text {
        font-size: 1.2em;
        margin-top: 5px;
      }
    }

    .galleria-container {
      width: 80%;
      max-width: 850px;
    }

    .thumbnail-image {
      display: block;
      width: 50px;
      height: 50px;
    }

    .image-modal-preview {
      width: 40%;
    }

    .image-item-template {
      display: flex;
      justify-content: center;
      gap: 50%;
      padding: 1rem;
    }

    .image-preview-template {
      display: flex;
      justify-content: center;
      gap: 50%;
      padding: 1rem;
    }
  }
  .modal-main-header {
    text-align: left;
    margin-left: -10px;
  }
  .p-fileupload {
    .p-fileupload-content {
      padding: 0%;
    }
  }

  .dialog-component {
    max-width: 50vw;
  }

  .section-header {
    text-align: left;
    margin-top: 10px;
    margin-bottom: -15px;
    margin-left: -8px;
  }

  .section-header-text {
    color: #333;
  }
  .file-upload-header {
    text-align: left;
    margin-bottom: 10px;
    margin-left: 3px;
  }

  .file-upload-text {
    color: #333;
  }

  .remove-image {
    margin-left: 16rem;
    margin-top: -4rem;
  }
  video {
    width: 100%;
  }

  .choose-file-button {
    .p-button {
      background: $primary-color;
      border: 1px solid $primary-color;
      &:focus {
        box-shadow: none;
      }
      &:hover {
        background: $on-primary-hover;
        border: 1px solid $primary-color;
      }
      &:active {
        background: $primary-color;
        color: $white;
        border-color: $primary-color;
      }
    }
  }

  .drag-drop {
    display: flex;
    justify-content: center;
  }
  .default-button-container {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 10px;
  }

  .add-file-upload {
    background: $white;
    padding: 0.7rem;
    border: 1px solid #dee2e6;
    color: #495057;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    position: relative;
  }
  .image-file {
    display: flex;
    gap: 1rem;
  }

  .add-image-container {
    display: flex;
    justify-content: center;
    border-radius: 7px;
    width: 400px;
    height: 200px;
  }
}
.image-display {
  align-items: center;
  border: 1px solid #f8f9fa;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 10px;
  width: 100%;
}

@media (max-width: $device-small) {
  .dialog-image-component {
    max-width: 80vw;
    .add-image-container {
      display: flex;
      justify-content: center;
      border-radius: 7px;
      width: 110px;
      height: 110px;
    }
    .image-file {
      display: flex;
      gap: 6rem;
    }
  }
}
.selected-border {
  border: 2px solid blue;
}
