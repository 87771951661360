@import 'theme.scss';

.certified-stock-edit-modal {
  width: 50%;
  background-color: $white;
  .p-dialog-title {
    text-transform: uppercase;
  }
  .p-dialog-content {
    padding-top: 12px;
  }
  .p-link:focus {
    box-shadow: $focus-shadow-color;
  }

  .edit-container {
    .action-button {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 1rem;
      .p-button:focus {
        box-shadow: $focus-shadow-color;
      }
    }
    .edit-form-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      width: 100%;
      .input-form-section {
        margin-bottom: 1rem;

        .required-text-field {
          color: $danger-red-color;
          margin-left: 2px;
          font-size: $x-large;
        }

        label {
          margin-top: $float-label-margin-top;
          left: $form-label;
          margin-left: $float-label-margin-left;
        }

        .input-label {
          margin-top: $float-label-margin-top;
          left: $form-label;
          margin-left: $float-label-margin-left;
          &.error-fields {
            color: $danger-red-color;
          }
        }

        .input-text {
          width: $field-width-100;

          &:focus {
            box-shadow: $focus-shadow-color;
            border-color: $primary-color;
          }
          &:hover {
            border-color: $primary-color;
          }
          &.error-fields {
            border-color: $danger-red-color;
          }
        }

        .calendar-input {
          width: $field-width-100;
          .p-button.p-button-icon-only {
            width: 3rem;
            padding: 0.75rem 0;
          }

          .p-button-icon-only {
            justify-content: center;
          }
          .p-button {
            margin: 0;
            color: $white;
            background: $primary-color;
            border: 1px solid $primary-color;
            transition:
              background-color 0.2s,
              color 0.2s,
              border-color 0.2s,
              box-shadow 0.2s;
          }
          .p-button:focus {
            box-shadow: $focus-shadow-color;
          }
          .p-inputtext:enabled:hover {
            border-color: $primary-color;
          }
          .p-inputtext:enabled:focus {
            box-shadow: $focus-shadow-color;
            border-color: $primary-color;
          }
        }
        .error-message {
          color: $danger-red-color;
          margin-left: $form-error-message-margin;
        }
      }
    }
  }
  .footer-button {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }
}

@media (max-width: $device-small) {
  .certified-stock-edit-modal {
    width: 94%;
    .edit-container {
      .edit-form-wrapper {
        grid-template-columns: 1fr;
      }
    }
  }
}
