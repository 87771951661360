@import 'theme.scss';

.coffee-quotes-container {
  .tab-container {
    margin: 0px 5px;
    .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
      background: $white;
      border-color: $primary-color;
      color: $primary-color;
    }

    .p-tabview .p-tabview-nav .p-tabview-ink-bar {
      background: $primary-color;
    }
    .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none;
    }

    .p-tabview .p-tabview-panels {
      padding: 10px 0px;
    }

    .coffee_quotes_screen_body {
      position: relative;
      width: 100%;
      .robusta_and_arabica_table {
        table-layout: auto;
        width: 100%;
        border-collapse: collapse;
        border: 1px solid #dee2e6;
        margin-bottom: 0px;

        .table_head {
          background-color: $primary-color;
          color: $white;
          td {
            padding: 4px;
            text-align: center;
          }

          .contract_name {
            font-size: 16px;
            text-align: start !important;
            display: flex;

            .country-flag-icon {
              height: 17px;
              border: 1px solid rgba(0, 17, 51, 0.15);
              border-radius: 2px;
              margin: auto 0px;
              margin-right: 3px;
            }
          }
        }

        .negative_red {
          color: $danger-red-color;
          font-weight: $font-semibold;
        }
        .positive_green {
          color: $success-background;
          font-weight: $font-semibold;
        }

        .data-tr {
          cursor: pointer;
          border-bottom: 1px solid rgb(222, 226, 230);
          border-collapse: collapse;
          .contract-name {
            text-align: start;
            padding-left: 10px;
          }
          &:hover {
            background-color: rgb(224, 224, 224);
          }

          td {
            min-width: 50px;
            font-size: 15px;
            text-align: center;
            font-family: Arial, Helvetica, sans-serif;
            vertical-align: middle;
            text-align: center;
            padding: 0rem 0.1rem;

            .pi-pencil {
              color: $primary-color;
              padding: 6px;
            }
          }
        }
      }
    }
  }
}
